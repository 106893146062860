/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment';

/**
 * 
 * @export
 * @interface ExecRiskIRS990EZ
 */
export interface ExecRiskIRS990EZ {
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment>}
     * @memberof ExecRiskIRS990EZ
     */
    ProgramSrvcAccomplishmentGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishment>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl>}
     * @memberof ExecRiskIRS990EZ
     */
    OfficerDirectorTrusteeEmplGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmpl>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp>}
     * @memberof ExecRiskIRS990EZ
     */
    CompensationHighestPaidEmplGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrp>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PartVIOfCompOfHghstPdEmplTxt: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp>}
     * @memberof ExecRiskIRS990EZ
     */
    CompensationOfHghstPdCntrctGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PartVIHghstPdCntrctProfSrvcTxt: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    SpecialConditionDesc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    accountingPeriodChangeCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    accountingPeriodChangeApprvCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    AddressChangeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    NameChangeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InitialReturnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    FinalReturnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    AmendedReturnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ApplicationPendingInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    GroupExemptionNum: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MethodOfAccountingCashInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MethodOfAccountingAccrualInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MethodOfAccountingOtherDesc: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ScheduleBNotRequiredInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    WebsiteAddressTxt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Organization501c3Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    organization_501_c_ind?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    organization_501_c_type_text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Organization4947a1NotPFInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Organization527Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationCorpInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationTrustInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationAssocInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationOtherInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TypeOfOrganizationOtherDesc: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossReceiptsAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    ContributionsGiftsGrantsEtcAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    ProgramServiceRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    MembershipDuesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    InvestmentIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SaleOfAssetsGrossAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    CostOrOtherBasisExpenseSaleAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GainOrLossFromSaleOfAssetsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    FundraisingGrossIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SpecialEventsDirectExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SpecialEventsNetIncomeLossAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossSalesOfInventoryAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    CostOfGoodsSoldAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossProfitLossSlsOfInvntryAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OtherRevenueTotalAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TotalRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrantsAndSimilarAmountsPaidAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    BenefitsPaidToOrForMembersAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    SalariesOtherCompEmplBnftAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    FeesAndOtherPymtToIndCntrctAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OccupancyRentUtltsAndMaintAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    PrintingPublicationsPostageAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OtherExpensesTotalAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TotalExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    ExcessOrDeficitForYearAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    NetAssetsOrFundBalancesBOYAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    OtherChangesInNetAssetsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    NetAssetsOrFundBalancesEOYAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIIInd: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    CashSavingsAndInvestmentsGrp: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    LandAndBuildingsGrp: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    OtherAssetsTotalDetail: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    Form990TotalAssetsGrp: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    SumOfTotalLiabilitiesGrp: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt}
     * @memberof ExecRiskIRS990EZ
     */
    NetAssetsOrFundBalancesGrp: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIIIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PrimaryExemptPurposeTxt: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TotalProgramServiceExpensesAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartIVInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartVInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ActivitiesNotPreviouslyRptInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ChgMadeToOrgnzngDocNotRptInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OrganizationHadUBIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OrganizationFiled990TInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    SubjectToProxyTaxInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OrganizationDissolvedEtcInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    DirectIndirectPltclExpendAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Form1120PolFiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    MadeLoansToFromOfficersInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    LoansToFromOfficersAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    InitiationFeesAndCapContriAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrossReceiptsForPublicUseAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedUnderIRC4911Amt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedUnderIRC4912Amt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedUnderIRC4955Amt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    EngagedInExcessBenefitTransInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxImposedOnOrganizationMgrAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    TaxReimbursedByOrganizationAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ProhibitedTaxShelterTransInd: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    StatesWhereCopyOfReturnIsFldCd: Array<string>;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail}
     * @memberof ExecRiskIRS990EZ
     */
    BooksInCareOfDetail: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignFinancialAccountInd: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignFinancialAccountCntryCd: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignOfficeInd: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990EZ
     */
    ForeignOfficeCountryCd: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    NECTFilingForm990Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    DonorAdvisedFndsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OperateHospitalInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TanningServicesProvidedInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    Form720FiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    RelatedOrganizationCtrlEntInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TransactionWithControlEntInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    PoliticalCampaignActyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    InfoInScheduleOPartVIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    LobbyingActivitiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    SchoolOperatingInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    TrnsfrExmptNonChrtblRltdOrgInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    RelatedOrgSect527OrgInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    OtherEmployeePaidOver100kCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    CntrctRcvdGreaterThan100KCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990EZ
     */
    FiledScheduleAInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990EZ
     */
    GrantsAndAllocationsAmt: number;
}

/**
 * Check if a given object implements the ExecRiskIRS990EZ interface.
 */
export function instanceOfExecRiskIRS990EZ(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ProgramSrvcAccomplishmentGrp" in value;
    isInstance = isInstance && "OfficerDirectorTrusteeEmplGrp" in value;
    isInstance = isInstance && "CompensationHighestPaidEmplGrp" in value;
    isInstance = isInstance && "PartVIOfCompOfHghstPdEmplTxt" in value;
    isInstance = isInstance && "CompensationOfHghstPdCntrctGrp" in value;
    isInstance = isInstance && "PartVIHghstPdCntrctProfSrvcTxt" in value;
    isInstance = isInstance && "SpecialConditionDesc" in value;
    isInstance = isInstance && "accountingPeriodChangeCd" in value;
    isInstance = isInstance && "accountingPeriodChangeApprvCd" in value;
    isInstance = isInstance && "AddressChangeInd" in value;
    isInstance = isInstance && "NameChangeInd" in value;
    isInstance = isInstance && "InitialReturnInd" in value;
    isInstance = isInstance && "FinalReturnInd" in value;
    isInstance = isInstance && "AmendedReturnInd" in value;
    isInstance = isInstance && "ApplicationPendingInd" in value;
    isInstance = isInstance && "GroupExemptionNum" in value;
    isInstance = isInstance && "MethodOfAccountingCashInd" in value;
    isInstance = isInstance && "MethodOfAccountingAccrualInd" in value;
    isInstance = isInstance && "MethodOfAccountingOtherDesc" in value;
    isInstance = isInstance && "ScheduleBNotRequiredInd" in value;
    isInstance = isInstance && "WebsiteAddressTxt" in value;
    isInstance = isInstance && "Organization501c3Ind" in value;
    isInstance = isInstance && "Organization4947a1NotPFInd" in value;
    isInstance = isInstance && "Organization527Ind" in value;
    isInstance = isInstance && "TypeOfOrganizationCorpInd" in value;
    isInstance = isInstance && "TypeOfOrganizationTrustInd" in value;
    isInstance = isInstance && "TypeOfOrganizationAssocInd" in value;
    isInstance = isInstance && "TypeOfOrganizationOtherInd" in value;
    isInstance = isInstance && "TypeOfOrganizationOtherDesc" in value;
    isInstance = isInstance && "GrossReceiptsAmt" in value;
    isInstance = isInstance && "InfoInScheduleOPartIInd" in value;
    isInstance = isInstance && "ContributionsGiftsGrantsEtcAmt" in value;
    isInstance = isInstance && "ProgramServiceRevenueAmt" in value;
    isInstance = isInstance && "MembershipDuesAmt" in value;
    isInstance = isInstance && "InvestmentIncomeAmt" in value;
    isInstance = isInstance && "SaleOfAssetsGrossAmt" in value;
    isInstance = isInstance && "CostOrOtherBasisExpenseSaleAmt" in value;
    isInstance = isInstance && "GainOrLossFromSaleOfAssetsAmt" in value;
    isInstance = isInstance && "FundraisingGrossIncomeAmt" in value;
    isInstance = isInstance && "SpecialEventsDirectExpensesAmt" in value;
    isInstance = isInstance && "SpecialEventsNetIncomeLossAmt" in value;
    isInstance = isInstance && "GrossSalesOfInventoryAmt" in value;
    isInstance = isInstance && "CostOfGoodsSoldAmt" in value;
    isInstance = isInstance && "GrossProfitLossSlsOfInvntryAmt" in value;
    isInstance = isInstance && "OtherRevenueTotalAmt" in value;
    isInstance = isInstance && "TotalRevenueAmt" in value;
    isInstance = isInstance && "GrantsAndSimilarAmountsPaidAmt" in value;
    isInstance = isInstance && "BenefitsPaidToOrForMembersAmt" in value;
    isInstance = isInstance && "SalariesOtherCompEmplBnftAmt" in value;
    isInstance = isInstance && "FeesAndOtherPymtToIndCntrctAmt" in value;
    isInstance = isInstance && "OccupancyRentUtltsAndMaintAmt" in value;
    isInstance = isInstance && "PrintingPublicationsPostageAmt" in value;
    isInstance = isInstance && "OtherExpensesTotalAmt" in value;
    isInstance = isInstance && "TotalExpensesAmt" in value;
    isInstance = isInstance && "ExcessOrDeficitForYearAmt" in value;
    isInstance = isInstance && "NetAssetsOrFundBalancesBOYAmt" in value;
    isInstance = isInstance && "OtherChangesInNetAssetsAmt" in value;
    isInstance = isInstance && "NetAssetsOrFundBalancesEOYAmt" in value;
    isInstance = isInstance && "InfoInScheduleOPartIIInd" in value;
    isInstance = isInstance && "CashSavingsAndInvestmentsGrp" in value;
    isInstance = isInstance && "LandAndBuildingsGrp" in value;
    isInstance = isInstance && "OtherAssetsTotalDetail" in value;
    isInstance = isInstance && "Form990TotalAssetsGrp" in value;
    isInstance = isInstance && "SumOfTotalLiabilitiesGrp" in value;
    isInstance = isInstance && "NetAssetsOrFundBalancesGrp" in value;
    isInstance = isInstance && "InfoInScheduleOPartIIIInd" in value;
    isInstance = isInstance && "PrimaryExemptPurposeTxt" in value;
    isInstance = isInstance && "TotalProgramServiceExpensesAmt" in value;
    isInstance = isInstance && "InfoInScheduleOPartIVInd" in value;
    isInstance = isInstance && "InfoInScheduleOPartVInd" in value;
    isInstance = isInstance && "ActivitiesNotPreviouslyRptInd" in value;
    isInstance = isInstance && "ChgMadeToOrgnzngDocNotRptInd" in value;
    isInstance = isInstance && "OrganizationHadUBIInd" in value;
    isInstance = isInstance && "OrganizationFiled990TInd" in value;
    isInstance = isInstance && "SubjectToProxyTaxInd" in value;
    isInstance = isInstance && "OrganizationDissolvedEtcInd" in value;
    isInstance = isInstance && "DirectIndirectPltclExpendAmt" in value;
    isInstance = isInstance && "Form1120PolFiledInd" in value;
    isInstance = isInstance && "MadeLoansToFromOfficersInd" in value;
    isInstance = isInstance && "LoansToFromOfficersAmt" in value;
    isInstance = isInstance && "InitiationFeesAndCapContriAmt" in value;
    isInstance = isInstance && "GrossReceiptsForPublicUseAmt" in value;
    isInstance = isInstance && "TaxImposedUnderIRC4911Amt" in value;
    isInstance = isInstance && "TaxImposedUnderIRC4912Amt" in value;
    isInstance = isInstance && "TaxImposedUnderIRC4955Amt" in value;
    isInstance = isInstance && "EngagedInExcessBenefitTransInd" in value;
    isInstance = isInstance && "TaxImposedOnOrganizationMgrAmt" in value;
    isInstance = isInstance && "TaxReimbursedByOrganizationAmt" in value;
    isInstance = isInstance && "ProhibitedTaxShelterTransInd" in value;
    isInstance = isInstance && "StatesWhereCopyOfReturnIsFldCd" in value;
    isInstance = isInstance && "BooksInCareOfDetail" in value;
    isInstance = isInstance && "ForeignFinancialAccountInd" in value;
    isInstance = isInstance && "ForeignFinancialAccountCntryCd" in value;
    isInstance = isInstance && "ForeignOfficeInd" in value;
    isInstance = isInstance && "ForeignOfficeCountryCd" in value;
    isInstance = isInstance && "NECTFilingForm990Ind" in value;
    isInstance = isInstance && "DonorAdvisedFndsInd" in value;
    isInstance = isInstance && "OperateHospitalInd" in value;
    isInstance = isInstance && "TanningServicesProvidedInd" in value;
    isInstance = isInstance && "Form720FiledInd" in value;
    isInstance = isInstance && "RelatedOrganizationCtrlEntInd" in value;
    isInstance = isInstance && "TransactionWithControlEntInd" in value;
    isInstance = isInstance && "PoliticalCampaignActyInd" in value;
    isInstance = isInstance && "InfoInScheduleOPartVIInd" in value;
    isInstance = isInstance && "LobbyingActivitiesInd" in value;
    isInstance = isInstance && "SchoolOperatingInd" in value;
    isInstance = isInstance && "TrnsfrExmptNonChrtblRltdOrgInd" in value;
    isInstance = isInstance && "RelatedOrgSect527OrgInd" in value;
    isInstance = isInstance && "OtherEmployeePaidOver100kCnt" in value;
    isInstance = isInstance && "CntrctRcvdGreaterThan100KCnt" in value;
    isInstance = isInstance && "FiledScheduleAInd" in value;
    isInstance = isInstance && "GrantsAndAllocationsAmt" in value;

    return isInstance;
}

export function ExecRiskIRS990EZFromJSON(json: any): ExecRiskIRS990EZ {
    return ExecRiskIRS990EZFromJSONTyped(json, false);
}

export function ExecRiskIRS990EZFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskIRS990EZ {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ProgramSrvcAccomplishmentGrp': ((json['ProgramSrvcAccomplishmentGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentFromJSON)),
        'OfficerDirectorTrusteeEmplGrp': ((json['OfficerDirectorTrusteeEmplGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplFromJSON)),
        'CompensationHighestPaidEmplGrp': ((json['CompensationHighestPaidEmplGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpFromJSON)),
        'PartVIOfCompOfHghstPdEmplTxt': json['PartVIOfCompOfHghstPdEmplTxt'],
        'CompensationOfHghstPdCntrctGrp': ((json['CompensationOfHghstPdCntrctGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSON)),
        'PartVIHghstPdCntrctProfSrvcTxt': json['PartVIHghstPdCntrctProfSrvcTxt'],
        'SpecialConditionDesc': json['SpecialConditionDesc'],
        'accountingPeriodChangeCd': json['accountingPeriodChangeCd'],
        'accountingPeriodChangeApprvCd': json['accountingPeriodChangeApprvCd'],
        'AddressChangeInd': json['AddressChangeInd'],
        'NameChangeInd': json['NameChangeInd'],
        'InitialReturnInd': json['InitialReturnInd'],
        'FinalReturnInd': json['FinalReturnInd'],
        'AmendedReturnInd': json['AmendedReturnInd'],
        'ApplicationPendingInd': json['ApplicationPendingInd'],
        'GroupExemptionNum': json['GroupExemptionNum'],
        'MethodOfAccountingCashInd': json['MethodOfAccountingCashInd'],
        'MethodOfAccountingAccrualInd': json['MethodOfAccountingAccrualInd'],
        'MethodOfAccountingOtherDesc': json['MethodOfAccountingOtherDesc'],
        'ScheduleBNotRequiredInd': json['ScheduleBNotRequiredInd'],
        'WebsiteAddressTxt': json['WebsiteAddressTxt'],
        'Organization501c3Ind': json['Organization501c3Ind'],
        'organization_501_c_ind': !exists(json, 'organization_501_c_ind') ? undefined : json['organization_501_c_ind'],
        'organization_501_c_type_text': !exists(json, 'organization_501_c_type_text') ? undefined : json['organization_501_c_type_text'],
        'Organization4947a1NotPFInd': json['Organization4947a1NotPFInd'],
        'Organization527Ind': json['Organization527Ind'],
        'TypeOfOrganizationCorpInd': json['TypeOfOrganizationCorpInd'],
        'TypeOfOrganizationTrustInd': json['TypeOfOrganizationTrustInd'],
        'TypeOfOrganizationAssocInd': json['TypeOfOrganizationAssocInd'],
        'TypeOfOrganizationOtherInd': json['TypeOfOrganizationOtherInd'],
        'TypeOfOrganizationOtherDesc': json['TypeOfOrganizationOtherDesc'],
        'GrossReceiptsAmt': json['GrossReceiptsAmt'],
        'InfoInScheduleOPartIInd': json['InfoInScheduleOPartIInd'],
        'ContributionsGiftsGrantsEtcAmt': json['ContributionsGiftsGrantsEtcAmt'],
        'ProgramServiceRevenueAmt': json['ProgramServiceRevenueAmt'],
        'MembershipDuesAmt': json['MembershipDuesAmt'],
        'InvestmentIncomeAmt': json['InvestmentIncomeAmt'],
        'SaleOfAssetsGrossAmt': json['SaleOfAssetsGrossAmt'],
        'CostOrOtherBasisExpenseSaleAmt': json['CostOrOtherBasisExpenseSaleAmt'],
        'GainOrLossFromSaleOfAssetsAmt': json['GainOrLossFromSaleOfAssetsAmt'],
        'FundraisingGrossIncomeAmt': json['FundraisingGrossIncomeAmt'],
        'SpecialEventsDirectExpensesAmt': json['SpecialEventsDirectExpensesAmt'],
        'SpecialEventsNetIncomeLossAmt': json['SpecialEventsNetIncomeLossAmt'],
        'GrossSalesOfInventoryAmt': json['GrossSalesOfInventoryAmt'],
        'CostOfGoodsSoldAmt': json['CostOfGoodsSoldAmt'],
        'GrossProfitLossSlsOfInvntryAmt': json['GrossProfitLossSlsOfInvntryAmt'],
        'OtherRevenueTotalAmt': json['OtherRevenueTotalAmt'],
        'TotalRevenueAmt': json['TotalRevenueAmt'],
        'GrantsAndSimilarAmountsPaidAmt': json['GrantsAndSimilarAmountsPaidAmt'],
        'BenefitsPaidToOrForMembersAmt': json['BenefitsPaidToOrForMembersAmt'],
        'SalariesOtherCompEmplBnftAmt': json['SalariesOtherCompEmplBnftAmt'],
        'FeesAndOtherPymtToIndCntrctAmt': json['FeesAndOtherPymtToIndCntrctAmt'],
        'OccupancyRentUtltsAndMaintAmt': json['OccupancyRentUtltsAndMaintAmt'],
        'PrintingPublicationsPostageAmt': json['PrintingPublicationsPostageAmt'],
        'OtherExpensesTotalAmt': json['OtherExpensesTotalAmt'],
        'TotalExpensesAmt': json['TotalExpensesAmt'],
        'ExcessOrDeficitForYearAmt': json['ExcessOrDeficitForYearAmt'],
        'NetAssetsOrFundBalancesBOYAmt': json['NetAssetsOrFundBalancesBOYAmt'],
        'OtherChangesInNetAssetsAmt': json['OtherChangesInNetAssetsAmt'],
        'NetAssetsOrFundBalancesEOYAmt': json['NetAssetsOrFundBalancesEOYAmt'],
        'InfoInScheduleOPartIIInd': json['InfoInScheduleOPartIIInd'],
        'CashSavingsAndInvestmentsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['CashSavingsAndInvestmentsGrp']),
        'LandAndBuildingsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['LandAndBuildingsGrp']),
        'OtherAssetsTotalDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['OtherAssetsTotalDetail']),
        'Form990TotalAssetsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['Form990TotalAssetsGrp']),
        'SumOfTotalLiabilitiesGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['SumOfTotalLiabilitiesGrp']),
        'NetAssetsOrFundBalancesGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON(json['NetAssetsOrFundBalancesGrp']),
        'InfoInScheduleOPartIIIInd': json['InfoInScheduleOPartIIIInd'],
        'PrimaryExemptPurposeTxt': json['PrimaryExemptPurposeTxt'],
        'TotalProgramServiceExpensesAmt': json['TotalProgramServiceExpensesAmt'],
        'InfoInScheduleOPartIVInd': json['InfoInScheduleOPartIVInd'],
        'InfoInScheduleOPartVInd': json['InfoInScheduleOPartVInd'],
        'ActivitiesNotPreviouslyRptInd': json['ActivitiesNotPreviouslyRptInd'],
        'ChgMadeToOrgnzngDocNotRptInd': json['ChgMadeToOrgnzngDocNotRptInd'],
        'OrganizationHadUBIInd': json['OrganizationHadUBIInd'],
        'OrganizationFiled990TInd': json['OrganizationFiled990TInd'],
        'SubjectToProxyTaxInd': json['SubjectToProxyTaxInd'],
        'OrganizationDissolvedEtcInd': json['OrganizationDissolvedEtcInd'],
        'DirectIndirectPltclExpendAmt': json['DirectIndirectPltclExpendAmt'],
        'Form1120PolFiledInd': json['Form1120PolFiledInd'],
        'MadeLoansToFromOfficersInd': json['MadeLoansToFromOfficersInd'],
        'LoansToFromOfficersAmt': json['LoansToFromOfficersAmt'],
        'InitiationFeesAndCapContriAmt': json['InitiationFeesAndCapContriAmt'],
        'GrossReceiptsForPublicUseAmt': json['GrossReceiptsForPublicUseAmt'],
        'TaxImposedUnderIRC4911Amt': json['TaxImposedUnderIRC4911Amt'],
        'TaxImposedUnderIRC4912Amt': json['TaxImposedUnderIRC4912Amt'],
        'TaxImposedUnderIRC4955Amt': json['TaxImposedUnderIRC4955Amt'],
        'EngagedInExcessBenefitTransInd': json['EngagedInExcessBenefitTransInd'],
        'TaxImposedOnOrganizationMgrAmt': json['TaxImposedOnOrganizationMgrAmt'],
        'TaxReimbursedByOrganizationAmt': json['TaxReimbursedByOrganizationAmt'],
        'ProhibitedTaxShelterTransInd': json['ProhibitedTaxShelterTransInd'],
        'StatesWhereCopyOfReturnIsFldCd': json['StatesWhereCopyOfReturnIsFldCd'],
        'BooksInCareOfDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON(json['BooksInCareOfDetail']),
        'ForeignFinancialAccountInd': json['ForeignFinancialAccountInd'],
        'ForeignFinancialAccountCntryCd': json['ForeignFinancialAccountCntryCd'],
        'ForeignOfficeInd': json['ForeignOfficeInd'],
        'ForeignOfficeCountryCd': json['ForeignOfficeCountryCd'],
        'NECTFilingForm990Ind': json['NECTFilingForm990Ind'],
        'DonorAdvisedFndsInd': json['DonorAdvisedFndsInd'],
        'OperateHospitalInd': json['OperateHospitalInd'],
        'TanningServicesProvidedInd': json['TanningServicesProvidedInd'],
        'Form720FiledInd': json['Form720FiledInd'],
        'RelatedOrganizationCtrlEntInd': json['RelatedOrganizationCtrlEntInd'],
        'TransactionWithControlEntInd': json['TransactionWithControlEntInd'],
        'PoliticalCampaignActyInd': json['PoliticalCampaignActyInd'],
        'InfoInScheduleOPartVIInd': json['InfoInScheduleOPartVIInd'],
        'LobbyingActivitiesInd': json['LobbyingActivitiesInd'],
        'SchoolOperatingInd': json['SchoolOperatingInd'],
        'TrnsfrExmptNonChrtblRltdOrgInd': json['TrnsfrExmptNonChrtblRltdOrgInd'],
        'RelatedOrgSect527OrgInd': json['RelatedOrgSect527OrgInd'],
        'OtherEmployeePaidOver100kCnt': json['OtherEmployeePaidOver100kCnt'],
        'CntrctRcvdGreaterThan100KCnt': json['CntrctRcvdGreaterThan100KCnt'],
        'FiledScheduleAInd': json['FiledScheduleAInd'],
        'GrantsAndAllocationsAmt': json['GrantsAndAllocationsAmt'],
    };
}

export function ExecRiskIRS990EZToJSON(value?: ExecRiskIRS990EZ | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ProgramSrvcAccomplishmentGrp': ((value.ProgramSrvcAccomplishmentGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZProgramSrvcAccomplishmentToJSON)),
        'OfficerDirectorTrusteeEmplGrp': ((value.OfficerDirectorTrusteeEmplGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZOfficerDirectorTrusteeEmplToJSON)),
        'CompensationHighestPaidEmplGrp': ((value.CompensationHighestPaidEmplGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationHighestPaidEmplGrpToJSON)),
        'PartVIOfCompOfHghstPdEmplTxt': value.PartVIOfCompOfHghstPdEmplTxt,
        'CompensationOfHghstPdCntrctGrp': ((value.CompensationOfHghstPdCntrctGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpToJSON)),
        'PartVIHghstPdCntrctProfSrvcTxt': value.PartVIHghstPdCntrctProfSrvcTxt,
        'SpecialConditionDesc': value.SpecialConditionDesc,
        'accountingPeriodChangeCd': value.accountingPeriodChangeCd,
        'accountingPeriodChangeApprvCd': value.accountingPeriodChangeApprvCd,
        'AddressChangeInd': value.AddressChangeInd,
        'NameChangeInd': value.NameChangeInd,
        'InitialReturnInd': value.InitialReturnInd,
        'FinalReturnInd': value.FinalReturnInd,
        'AmendedReturnInd': value.AmendedReturnInd,
        'ApplicationPendingInd': value.ApplicationPendingInd,
        'GroupExemptionNum': value.GroupExemptionNum,
        'MethodOfAccountingCashInd': value.MethodOfAccountingCashInd,
        'MethodOfAccountingAccrualInd': value.MethodOfAccountingAccrualInd,
        'MethodOfAccountingOtherDesc': value.MethodOfAccountingOtherDesc,
        'ScheduleBNotRequiredInd': value.ScheduleBNotRequiredInd,
        'WebsiteAddressTxt': value.WebsiteAddressTxt,
        'Organization501c3Ind': value.Organization501c3Ind,
        'organization_501_c_ind': value.organization_501_c_ind,
        'organization_501_c_type_text': value.organization_501_c_type_text,
        'Organization4947a1NotPFInd': value.Organization4947a1NotPFInd,
        'Organization527Ind': value.Organization527Ind,
        'TypeOfOrganizationCorpInd': value.TypeOfOrganizationCorpInd,
        'TypeOfOrganizationTrustInd': value.TypeOfOrganizationTrustInd,
        'TypeOfOrganizationAssocInd': value.TypeOfOrganizationAssocInd,
        'TypeOfOrganizationOtherInd': value.TypeOfOrganizationOtherInd,
        'TypeOfOrganizationOtherDesc': value.TypeOfOrganizationOtherDesc,
        'GrossReceiptsAmt': value.GrossReceiptsAmt,
        'InfoInScheduleOPartIInd': value.InfoInScheduleOPartIInd,
        'ContributionsGiftsGrantsEtcAmt': value.ContributionsGiftsGrantsEtcAmt,
        'ProgramServiceRevenueAmt': value.ProgramServiceRevenueAmt,
        'MembershipDuesAmt': value.MembershipDuesAmt,
        'InvestmentIncomeAmt': value.InvestmentIncomeAmt,
        'SaleOfAssetsGrossAmt': value.SaleOfAssetsGrossAmt,
        'CostOrOtherBasisExpenseSaleAmt': value.CostOrOtherBasisExpenseSaleAmt,
        'GainOrLossFromSaleOfAssetsAmt': value.GainOrLossFromSaleOfAssetsAmt,
        'FundraisingGrossIncomeAmt': value.FundraisingGrossIncomeAmt,
        'SpecialEventsDirectExpensesAmt': value.SpecialEventsDirectExpensesAmt,
        'SpecialEventsNetIncomeLossAmt': value.SpecialEventsNetIncomeLossAmt,
        'GrossSalesOfInventoryAmt': value.GrossSalesOfInventoryAmt,
        'CostOfGoodsSoldAmt': value.CostOfGoodsSoldAmt,
        'GrossProfitLossSlsOfInvntryAmt': value.GrossProfitLossSlsOfInvntryAmt,
        'OtherRevenueTotalAmt': value.OtherRevenueTotalAmt,
        'TotalRevenueAmt': value.TotalRevenueAmt,
        'GrantsAndSimilarAmountsPaidAmt': value.GrantsAndSimilarAmountsPaidAmt,
        'BenefitsPaidToOrForMembersAmt': value.BenefitsPaidToOrForMembersAmt,
        'SalariesOtherCompEmplBnftAmt': value.SalariesOtherCompEmplBnftAmt,
        'FeesAndOtherPymtToIndCntrctAmt': value.FeesAndOtherPymtToIndCntrctAmt,
        'OccupancyRentUtltsAndMaintAmt': value.OccupancyRentUtltsAndMaintAmt,
        'PrintingPublicationsPostageAmt': value.PrintingPublicationsPostageAmt,
        'OtherExpensesTotalAmt': value.OtherExpensesTotalAmt,
        'TotalExpensesAmt': value.TotalExpensesAmt,
        'ExcessOrDeficitForYearAmt': value.ExcessOrDeficitForYearAmt,
        'NetAssetsOrFundBalancesBOYAmt': value.NetAssetsOrFundBalancesBOYAmt,
        'OtherChangesInNetAssetsAmt': value.OtherChangesInNetAssetsAmt,
        'NetAssetsOrFundBalancesEOYAmt': value.NetAssetsOrFundBalancesEOYAmt,
        'InfoInScheduleOPartIIInd': value.InfoInScheduleOPartIIInd,
        'CashSavingsAndInvestmentsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.CashSavingsAndInvestmentsGrp),
        'LandAndBuildingsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.LandAndBuildingsGrp),
        'OtherAssetsTotalDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.OtherAssetsTotalDetail),
        'Form990TotalAssetsGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.Form990TotalAssetsGrp),
        'SumOfTotalLiabilitiesGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.SumOfTotalLiabilitiesGrp),
        'NetAssetsOrFundBalancesGrp': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON(value.NetAssetsOrFundBalancesGrp),
        'InfoInScheduleOPartIIIInd': value.InfoInScheduleOPartIIIInd,
        'PrimaryExemptPurposeTxt': value.PrimaryExemptPurposeTxt,
        'TotalProgramServiceExpensesAmt': value.TotalProgramServiceExpensesAmt,
        'InfoInScheduleOPartIVInd': value.InfoInScheduleOPartIVInd,
        'InfoInScheduleOPartVInd': value.InfoInScheduleOPartVInd,
        'ActivitiesNotPreviouslyRptInd': value.ActivitiesNotPreviouslyRptInd,
        'ChgMadeToOrgnzngDocNotRptInd': value.ChgMadeToOrgnzngDocNotRptInd,
        'OrganizationHadUBIInd': value.OrganizationHadUBIInd,
        'OrganizationFiled990TInd': value.OrganizationFiled990TInd,
        'SubjectToProxyTaxInd': value.SubjectToProxyTaxInd,
        'OrganizationDissolvedEtcInd': value.OrganizationDissolvedEtcInd,
        'DirectIndirectPltclExpendAmt': value.DirectIndirectPltclExpendAmt,
        'Form1120PolFiledInd': value.Form1120PolFiledInd,
        'MadeLoansToFromOfficersInd': value.MadeLoansToFromOfficersInd,
        'LoansToFromOfficersAmt': value.LoansToFromOfficersAmt,
        'InitiationFeesAndCapContriAmt': value.InitiationFeesAndCapContriAmt,
        'GrossReceiptsForPublicUseAmt': value.GrossReceiptsForPublicUseAmt,
        'TaxImposedUnderIRC4911Amt': value.TaxImposedUnderIRC4911Amt,
        'TaxImposedUnderIRC4912Amt': value.TaxImposedUnderIRC4912Amt,
        'TaxImposedUnderIRC4955Amt': value.TaxImposedUnderIRC4955Amt,
        'EngagedInExcessBenefitTransInd': value.EngagedInExcessBenefitTransInd,
        'TaxImposedOnOrganizationMgrAmt': value.TaxImposedOnOrganizationMgrAmt,
        'TaxReimbursedByOrganizationAmt': value.TaxReimbursedByOrganizationAmt,
        'ProhibitedTaxShelterTransInd': value.ProhibitedTaxShelterTransInd,
        'StatesWhereCopyOfReturnIsFldCd': value.StatesWhereCopyOfReturnIsFldCd,
        'BooksInCareOfDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON(value.BooksInCareOfDetail),
        'ForeignFinancialAccountInd': value.ForeignFinancialAccountInd,
        'ForeignFinancialAccountCntryCd': value.ForeignFinancialAccountCntryCd,
        'ForeignOfficeInd': value.ForeignOfficeInd,
        'ForeignOfficeCountryCd': value.ForeignOfficeCountryCd,
        'NECTFilingForm990Ind': value.NECTFilingForm990Ind,
        'DonorAdvisedFndsInd': value.DonorAdvisedFndsInd,
        'OperateHospitalInd': value.OperateHospitalInd,
        'TanningServicesProvidedInd': value.TanningServicesProvidedInd,
        'Form720FiledInd': value.Form720FiledInd,
        'RelatedOrganizationCtrlEntInd': value.RelatedOrganizationCtrlEntInd,
        'TransactionWithControlEntInd': value.TransactionWithControlEntInd,
        'PoliticalCampaignActyInd': value.PoliticalCampaignActyInd,
        'InfoInScheduleOPartVIInd': value.InfoInScheduleOPartVIInd,
        'LobbyingActivitiesInd': value.LobbyingActivitiesInd,
        'SchoolOperatingInd': value.SchoolOperatingInd,
        'TrnsfrExmptNonChrtblRltdOrgInd': value.TrnsfrExmptNonChrtblRltdOrgInd,
        'RelatedOrgSect527OrgInd': value.RelatedOrgSect527OrgInd,
        'OtherEmployeePaidOver100kCnt': value.OtherEmployeePaidOver100kCnt,
        'CntrctRcvdGreaterThan100KCnt': value.CntrctRcvdGreaterThan100KCnt,
        'FiledScheduleAInd': value.FiledScheduleAInd,
        'GrantsAndAllocationsAmt': value.GrantsAndAllocationsAmt,
    };
}

