/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitEntityExtraGeo
 */
export interface MonitEntityExtraGeo {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    stateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtraGeo
     */
    subPremise?: string;
}

/**
 * Check if a given object implements the MonitEntityExtraGeo interface.
 */
export function instanceOfMonitEntityExtraGeo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityExtraGeoFromJSON(json: any): MonitEntityExtraGeo {
    return MonitEntityExtraGeoFromJSONTyped(json, false);
}

export function MonitEntityExtraGeoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityExtraGeo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'stateCode': !exists(json, 'stateCode') ? undefined : json['stateCode'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'subPremise': !exists(json, 'subPremise') ? undefined : json['subPremise'],
    };
}

export function MonitEntityExtraGeoToJSON(value?: MonitEntityExtraGeo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'country': value.country,
        'countryCode': value.countryCode,
        'postalCode': value.postalCode,
        'state': value.state,
        'stateCode': value.stateCode,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'subPremise': value.subPremise,
    };
}

