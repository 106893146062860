/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    Desc: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    BusinessCd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    TotalRevenueColumnAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    RelatedOrExemptFuncIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    UnrelatedBusinessRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    ExclusionAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    ActivityOrMissionDesc: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue
     */
    MissionDesc: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Desc" in value;
    isInstance = isInstance && "BusinessCd" in value;
    isInstance = isInstance && "TotalRevenueColumnAmt" in value;
    isInstance = isInstance && "RelatedOrExemptFuncIncomeAmt" in value;
    isInstance = isInstance && "UnrelatedBusinessRevenueAmt" in value;
    isInstance = isInstance && "ExclusionAmt" in value;
    isInstance = isInstance && "ActivityOrMissionDesc" in value;
    isInstance = isInstance && "MissionDesc" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Desc': json['Desc'],
        'BusinessCd': json['BusinessCd'],
        'TotalRevenueColumnAmt': json['TotalRevenueColumnAmt'],
        'RelatedOrExemptFuncIncomeAmt': json['RelatedOrExemptFuncIncomeAmt'],
        'UnrelatedBusinessRevenueAmt': json['UnrelatedBusinessRevenueAmt'],
        'ExclusionAmt': json['ExclusionAmt'],
        'ActivityOrMissionDesc': json['ActivityOrMissionDesc'],
        'MissionDesc': json['MissionDesc'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Desc': value.Desc,
        'BusinessCd': value.BusinessCd,
        'TotalRevenueColumnAmt': value.TotalRevenueColumnAmt,
        'RelatedOrExemptFuncIncomeAmt': value.RelatedOrExemptFuncIncomeAmt,
        'UnrelatedBusinessRevenueAmt': value.UnrelatedBusinessRevenueAmt,
        'ExclusionAmt': value.ExclusionAmt,
        'ActivityOrMissionDesc': value.ActivityOrMissionDesc,
        'MissionDesc': value.MissionDesc,
    };
}

