/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    Desc: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    TotalAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    ProgramServicesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    ManagementAndGeneralAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt
     */
    FundraisingAmt: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Desc" in value;
    isInstance = isInstance && "TotalAmt" in value;
    isInstance = isInstance && "ProgramServicesAmt" in value;
    isInstance = isInstance && "ManagementAndGeneralAmt" in value;
    isInstance = isInstance && "FundraisingAmt" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Desc': json['Desc'],
        'TotalAmt': json['TotalAmt'],
        'ProgramServicesAmt': json['ProgramServicesAmt'],
        'ManagementAndGeneralAmt': json['ManagementAndGeneralAmt'],
        'FundraisingAmt': json['FundraisingAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Desc': value.Desc,
        'TotalAmt': value.TotalAmt,
        'ProgramServicesAmt': value.ProgramServicesAmt,
        'ManagementAndGeneralAmt': value.ManagementAndGeneralAmt,
        'FundraisingAmt': value.FundraisingAmt,
    };
}

