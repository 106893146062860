/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Builtwith } from './Builtwith';
import {
    BuiltwithFromJSON,
    BuiltwithFromJSONTyped,
    BuiltwithToJSON,
} from './Builtwith';
import type { CRSFEmailLeaksAggregatedResponse } from './CRSFEmailLeaksAggregatedResponse';
import {
    CRSFEmailLeaksAggregatedResponseFromJSON,
    CRSFEmailLeaksAggregatedResponseFromJSONTyped,
    CRSFEmailLeaksAggregatedResponseToJSON,
} from './CRSFEmailLeaksAggregatedResponse';
import type { CRSFSourcelistsData } from './CRSFSourcelistsData';
import {
    CRSFSourcelistsDataFromJSON,
    CRSFSourcelistsDataFromJSONTyped,
    CRSFSourcelistsDataToJSON,
} from './CRSFSourcelistsData';
import type { RiskSummary } from './RiskSummary';
import {
    RiskSummaryFromJSON,
    RiskSummaryFromJSONTyped,
    RiskSummaryToJSON,
} from './RiskSummary';
import type { Virustotal } from './Virustotal';
import {
    VirustotalFromJSON,
    VirustotalFromJSONTyped,
    VirustotalToJSON,
} from './Virustotal';

/**
 * 
 * @export
 * @interface CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse
 */
export interface CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse {
    /**
     * 
     * @type {Builtwith}
     * @memberof CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse
     */
    builtwith: Builtwith;
    /**
     * 
     * @type {CRSFEmailLeaksAggregatedResponse}
     * @memberof CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse
     */
    dataleaks: CRSFEmailLeaksAggregatedResponse;
    /**
     * 
     * @type {RiskSummary}
     * @memberof CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse
     */
    risk_summary: RiskSummary;
    /**
     * 
     * @type {CRSFSourcelistsData}
     * @memberof CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse
     */
    sourcelists: CRSFSourcelistsData;
    /**
     * 
     * @type {Virustotal}
     * @memberof CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse
     */
    virustotal: Virustotal;
}

/**
 * Check if a given object implements the CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse interface.
 */
export function instanceOfCRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "builtwith" in value;
    isInstance = isInstance && "dataleaks" in value;
    isInstance = isInstance && "risk_summary" in value;
    isInstance = isInstance && "sourcelists" in value;
    isInstance = isInstance && "virustotal" in value;

    return isInstance;
}

export function CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponseFromJSON(json: any): CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse {
    return CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponseFromJSONTyped(json, false);
}

export function CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'builtwith': BuiltwithFromJSON(json['builtwith']),
        'dataleaks': CRSFEmailLeaksAggregatedResponseFromJSON(json['dataleaks']),
        'risk_summary': RiskSummaryFromJSON(json['risk_summary']),
        'sourcelists': CRSFSourcelistsDataFromJSON(json['sourcelists']),
        'virustotal': VirustotalFromJSON(json['virustotal']),
    };
}

export function CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponseToJSON(value?: CRSFSrcHttpdlibApiSecreviewAggregatedAggregatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'builtwith': BuiltwithToJSON(value.builtwith),
        'dataleaks': CRSFEmailLeaksAggregatedResponseToJSON(value.dataleaks),
        'risk_summary': RiskSummaryToJSON(value.risk_summary),
        'sourcelists': CRSFSourcelistsDataToJSON(value.sourcelists),
        'virustotal': VirustotalToJSON(value.virustotal),
    };
}

