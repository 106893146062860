/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MonitEntityProcessOrderBy = {
    Created: 'created',
    Updated: 'updated',
    StatusEnum: 'status_enum',
    StatusEnumDns: 'status_enum_dns',
    StatusScan: 'status_scan',
    StatusSnapscan: 'status_snapscan',
    StatusSnap: 'status_snap',
    StatusDataleaks: 'status_dataleaks',
    StatusTorrents: 'status_torrents',
    StatusSinkhole: 'status_sinkhole',
    StatusKeywords: 'status_keywords',
    StatusPastebin: 'status_pastebin',
    StatusSourcelists: 'status_sourcelists',
    StatusHomoglyphs: 'status_homoglyphs',
    StatusHomoglyphsEnrich: 'status_homoglyphs_enrich',
    StatusHomoglyphsCompare: 'status_homoglyphs_compare',
    StatusSecchecksScan: 'status_secchecks_scan',
    StatusTechstackScan: 'status_techstack_scan',
    StatusSummaryScan: 'status_summary_scan',
    StatusSecchecksSnap: 'status_secchecks_snap',
    StatusTechstackSnap: 'status_techstack_snap',
    StatusSummarySnap: 'status_summary_snap'
} as const;
export type MonitEntityProcessOrderBy = typeof MonitEntityProcessOrderBy[keyof typeof MonitEntityProcessOrderBy];


export function MonitEntityProcessOrderByFromJSON(json: any): MonitEntityProcessOrderBy {
    return MonitEntityProcessOrderByFromJSONTyped(json, false);
}

export function MonitEntityProcessOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityProcessOrderBy {
    return json as MonitEntityProcessOrderBy;
}

export function MonitEntityProcessOrderByToJSON(value?: MonitEntityProcessOrderBy | null): any {
    return value as any;
}

