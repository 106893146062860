/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    ActivityCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    ExpenseAmt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    GrantAmt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    RevenueAmt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy
     */
    Desc: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ActivityCd" in value;
    isInstance = isInstance && "ExpenseAmt" in value;
    isInstance = isInstance && "GrantAmt" in value;
    isInstance = isInstance && "RevenueAmt" in value;
    isInstance = isInstance && "Desc" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ActivityCd': json['ActivityCd'],
        'ExpenseAmt': json['ExpenseAmt'],
        'GrantAmt': json['GrantAmt'],
        'RevenueAmt': json['RevenueAmt'],
        'Desc': json['Desc'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ActivityCd': value.ActivityCd,
        'ExpenseAmt': value.ExpenseAmt,
        'GrantAmt': value.GrantAmt,
        'RevenueAmt': value.RevenueAmt,
        'Desc': value.Desc,
    };
}

