/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CRSFDataleaksRiskSummaryObject } from './CRSFDataleaksRiskSummaryObject';
import {
    CRSFDataleaksRiskSummaryObjectFromJSON,
    CRSFDataleaksRiskSummaryObjectFromJSONTyped,
    CRSFDataleaksRiskSummaryObjectToJSON,
} from './CRSFDataleaksRiskSummaryObject';

/**
 * Represents a DataleakRiskSummary entity: for a given domain, it has the representation of the risk summary for
 * three different time ranges.
 * @export
 * @interface CRSFDataleakRiskSummary
 */
export interface CRSFDataleakRiskSummary {
    /**
     * 
     * @type {CRSFDataleaksRiskSummaryObject}
     * @memberof CRSFDataleakRiskSummary
     */
    all_time: CRSFDataleaksRiskSummaryObject;
    /**
     * 
     * @type {string}
     * @memberof CRSFDataleakRiskSummary
     */
    domain: string;
    /**
     * 
     * @type {CRSFDataleaksRiskSummaryObject}
     * @memberof CRSFDataleakRiskSummary
     */
    previous_12_months: CRSFDataleaksRiskSummaryObject;
    /**
     * 
     * @type {CRSFDataleaksRiskSummaryObject}
     * @memberof CRSFDataleakRiskSummary
     */
    previous_2_weeks?: CRSFDataleaksRiskSummaryObject;
    /**
     * 
     * @type {CRSFDataleaksRiskSummaryObject}
     * @memberof CRSFDataleakRiskSummary
     */
    previous_6_months: CRSFDataleaksRiskSummaryObject;
}

/**
 * Check if a given object implements the CRSFDataleakRiskSummary interface.
 */
export function instanceOfCRSFDataleakRiskSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "all_time" in value;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "previous_12_months" in value;
    isInstance = isInstance && "previous_6_months" in value;

    return isInstance;
}

export function CRSFDataleakRiskSummaryFromJSON(json: any): CRSFDataleakRiskSummary {
    return CRSFDataleakRiskSummaryFromJSONTyped(json, false);
}

export function CRSFDataleakRiskSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CRSFDataleakRiskSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'all_time': CRSFDataleaksRiskSummaryObjectFromJSON(json['all_time']),
        'domain': json['domain'],
        'previous_12_months': CRSFDataleaksRiskSummaryObjectFromJSON(json['previous_12_months']),
        'previous_2_weeks': !exists(json, 'previous_2_weeks') ? undefined : CRSFDataleaksRiskSummaryObjectFromJSON(json['previous_2_weeks']),
        'previous_6_months': CRSFDataleaksRiskSummaryObjectFromJSON(json['previous_6_months']),
    };
}

export function CRSFDataleakRiskSummaryToJSON(value?: CRSFDataleakRiskSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all_time': CRSFDataleaksRiskSummaryObjectToJSON(value.all_time),
        'domain': value.domain,
        'previous_12_months': CRSFDataleaksRiskSummaryObjectToJSON(value.previous_12_months),
        'previous_2_weeks': CRSFDataleaksRiskSummaryObjectToJSON(value.previous_2_weeks),
        'previous_6_months': CRSFDataleaksRiskSummaryObjectToJSON(value.previous_6_months),
    };
}

