/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
     */
    AddressLine1Txt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
     */
    AddressLine2Txt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
     */
    CityNm: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
     */
    ProvinceOrStateNm: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
     */
    CountryCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress
     */
    ForeignPostalCd: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AddressLine1Txt" in value;
    isInstance = isInstance && "AddressLine2Txt" in value;
    isInstance = isInstance && "CityNm" in value;
    isInstance = isInstance && "ProvinceOrStateNm" in value;
    isInstance = isInstance && "CountryCd" in value;
    isInstance = isInstance && "ForeignPostalCd" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress {
    return ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AddressLine1Txt': json['AddressLine1Txt'],
        'AddressLine2Txt': json['AddressLine2Txt'],
        'CityNm': json['CityNm'],
        'ProvinceOrStateNm': json['ProvinceOrStateNm'],
        'CountryCd': json['CountryCd'],
        'ForeignPostalCd': json['ForeignPostalCd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AddressLine1Txt': value.AddressLine1Txt,
        'AddressLine2Txt': value.AddressLine2Txt,
        'CityNm': value.CityNm,
        'ProvinceOrStateNm': value.ProvinceOrStateNm,
        'CountryCd': value.CountryCd,
        'ForeignPostalCd': value.ForeignPostalCd,
    };
}

