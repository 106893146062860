/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MonitLocale = {
    EnUs: 'en-US',
    EnCa: 'en-CA',
    EnGb: 'en-GB',
    EnAu: 'en-AU',
    FrCa: 'fr-CA',
    DeDe: 'de-DE'
} as const;
export type MonitLocale = typeof MonitLocale[keyof typeof MonitLocale];


export function MonitLocaleFromJSON(json: any): MonitLocale {
    return MonitLocaleFromJSONTyped(json, false);
}

export function MonitLocaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitLocale {
    return json as MonitLocale;
}

export function MonitLocaleToJSON(value?: MonitLocale | null): any {
    return value as any;
}

