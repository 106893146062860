/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue } from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue';

/**
 * 
 * @export
 * @interface ExecRiskIRS990
 */
export interface ExecRiskIRS990 {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990
     */
    SpecialConditionDesc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    accountingPeriodChangeCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    accountingPeriodChangeApprvCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AddressChangeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NameChangeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InitialReturnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FinalReturnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AmendedReturnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ApplicationPendingInd: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskIRS990
     */
    DoingBusinessAsName: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrossReceiptsAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PrincipalOfficerNm: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskIRS990
     */
    PrincipalOfcrBusinessName: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskIRS990
     */
    USAddress: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskIRS990
     */
    ForeignAddress: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Organization501c3Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    organization_501_c_ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    organization_501_c_type_text: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Organization4947a1NotPFInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Organization527Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    WebsiteAddressTxt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GroupReturnForAffiliatesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AllAffiliatesIncludedInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GroupExemptionNum: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationCorpInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationTrustInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationAssocInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TypeOfOrganizationOtherInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OtherOrganizationDsc: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FormationYr: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LegalDomicileStateCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LegalDomicileCountryCd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ActivityOrMissionDesc: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ContractTerminationInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    VotingMembersGoverningBodyCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    VotingMembersIndependentCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TotalEmployeeCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TotalVolunteersCnt: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalGrossUBIAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetUnrelatedBusTxblIncmAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYContributionsGrantsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYContributionsGrantsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYProgramServiceRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYProgramServiceRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYInvestmentIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYInvestmentIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYOtherRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYTotalRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYGrantsAndSimilarPaidAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYGrantsAndSimilarPaidAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYBenefitsPaidToMembersAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYBenefitsPaidToMembersAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYSalariesCompEmpBnftPaidAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYSalariesCompEmpBnftPaidAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYTotalProfFndrsngExpnsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalProfFndrsngExpnsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalFundraisingExpenseAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYOtherExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYOtherExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYTotalExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYTotalExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PYRevenuesLessExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CYRevenuesLessExpensesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalAssetsBOYAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalAssetsEOYAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetAssetsOrFundBalancesBOYAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetAssetsOrFundBalancesEOYAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartIIIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MissionDesc: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SignificantNewProgramSrvcInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SignificantChangeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ActivityCd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ExpenseAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrantAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    RevenueAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Desc: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>}
     * @memberof ExecRiskIRS990
     */
    ProgSrvcAccomActy2Grp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>}
     * @memberof ExecRiskIRS990
     */
    ProgSrvcAccomActy3Grp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>}
     * @memberof ExecRiskIRS990
     */
    ProgSrvcAccomActyOtherGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActy>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherProgSrvcExpenseAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherProgSrvcGrantAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherProgSrvcRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalProgramServiceExpensesAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DescribedInSection501c3Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ScheduleBRequiredInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PoliticalCampaignActyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LobbyingActivitiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SubjectToProxyTaxInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DonorAdvisedFundInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ConservationEasementsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CollectionsOfArtInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CreditCounselingInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DonorRstrOrQuasiEndowmentsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportLandBuildingEquipmentInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportInvestmentsOtherSecInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportProgramRelatedInvstInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportOtherAssetsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ReportOtherLiabilitiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IncludeFIN48FootnoteInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndependentAuditFinclStmtInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ConsolidatedAuditFinclStmtInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SchoolOperatingInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ForeignOfficeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ForeignActivitiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MoreThan5000KToOrgInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MoreThan5000KToIndividualsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ProfessionalFundraisingInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FundraisingActivitiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GamingActivitiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OperateHospitalInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AuditedFinancialStmtAttInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GrantsToOrganizationsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GrantsToIndividualsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ScheduleJRequiredInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TaxExemptBondsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InvestTaxExemptBondsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EscrowAccountInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OnBehalfOfIssuerInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EngagedInExcessBenefitTransInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PYExcessBenefitTransInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LoanOutstandingInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GrantToRelatedPersonInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BusinessRlnWithOrgMemInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BusinessRlnWithFamMemInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BusinessRlnWith35CtrlEntInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DeductibleNonCashContriInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DeductibleArtContributionInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TerminateOperationsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PartialLiquidationInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DisregardedEntityInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RelatedEntityInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RelatedOrganizationCtrlEntInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TransactionWithControlEntInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TrnsfrExmptNonChrtblRltdOrgInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ActivitiesConductedPrtshpInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IRPDocumentCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IRPDocumentW2GCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    BackupWthldComplianceInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EmployeeCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    EmploymentTaxReturnsFiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    UnrelatedBusIncmOverLimitInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form990TFiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ForeignFinancialAccountInd: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990
     */
    ForeignCountryCd: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ProhibitedTaxShelterTransInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TaxablePartyNotificationInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8886TFiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NondeductibleContributionsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NondeductibleContriDisclInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    QuidProQuoContributionsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    QuidProQuoContriDisclInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8282PropertyDisposedOfInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8282FiledCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RcvFndsToPayPrsnlBnftCntrctInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PayPremiumsPrsnlBnftCntrctInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form8899Filedind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form1098CFiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DAFExcessBusinessHoldingsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    TaxableDistributionsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DistributionToDonorInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    InitiationFeesAndCapContriAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrossReceiptsForPublicUseAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    MembersAndShrGrossIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    OtherSourcesGrossIncomeAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OrgFiledInLieuOfForm1041Ind: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TaxExemptInterestAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LicensedMoreThanOneStateInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    StateRequiredReservesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ReservesMaintainedAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndoorTanningServicesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form720FiledInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SubjToTaxRmnrtnExPrchtPymtInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    SubjectToExcsTaxNetInvstIncInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ExcsTaxSect4951Or4952Or4953Ind: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    GoverningBodyVotingMembersCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndependentVotingMemberCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FamilyOrBusinessRlnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DelegationOfMgmtDutiesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ChangeToOrgDocumentsInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MaterialDiversionOrMisuseInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MembersOrStockholdersInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ElectionOfBoardMembersInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DecisionsSubjectToApprovaInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MinutesOfGoverningBodyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MinutesOfCommitteesInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OfficerMailingAddressInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    LocalChaptersInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    PoliciesReferenceChaptersInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    Form990ProvidedToGvrnBodyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    ConflictOfInterestPolicyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AnnualDisclosureCoveredPrsnInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    RegularMonitoringEnfrcInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    WhistleblowerPolicyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    DocumentRetentionPolicyInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CompensationProcessCEOInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CompensationProcessOtherInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InvestmentInJointVentureInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    WrittenPolicyOrProcedureInd: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecRiskIRS990
     */
    StatesWhereCopyOfReturnIsFldCd: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OwnWebsiteInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OtherWebsiteInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    UponRequestInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OtherInd: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail}
     * @memberof ExecRiskIRS990
     */
    BooksInCareOfDetail: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetail;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVIIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    NoListedPersonsCompensatedInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA>}
     * @memberof ExecRiskIRS990
     */
    Form990PartVIISectionAGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalReportableCompFromOrgAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotReportableCompRltdOrgAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalOtherCompensationAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    IndivRcvdGreaterThan100KCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FormerOfcrEmployeesListedInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CompensationFromOtherSrcsInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation>}
     * @memberof ExecRiskIRS990
     */
    ContractorCompensationGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensation>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    CntrctRcvdGreaterThan100KCnt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartVIIIInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FederatedCampaignsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    MembershipDuesAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FundraisingAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    RelatedOrganizationsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GovernmentGrantsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    AllOtherContributionsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NoncashContributionsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalContributionsAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue>}
     * @memberof ExecRiskIRS990
     */
    ProgramServiceRevenueGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenue>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    TotalOthProgramServiceRevGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    TotalProgramServiceRevenueAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    InvestmentIncomeGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    IncmFromInvestBondProceedsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    RoyaltiesRevenueGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>}
     * @memberof ExecRiskIRS990
     */
    GrossRentsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>}
     * @memberof ExecRiskIRS990
     */
    LessRentalExpensesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>}
     * @memberof ExecRiskIRS990
     */
    RentalIncomeOrLossGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetRentalIncomeOrLossGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>}
     * @memberof ExecRiskIRS990
     */
    GrossAmountSalesAssetsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>}
     * @memberof ExecRiskIRS990
     */
    LessCostOthBasisSalesExpnssGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>}
     * @memberof ExecRiskIRS990
     */
    GainOrLossGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetGainOrLossInvestmentsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ContriRptFundraisingEventAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FundraisingGrossIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    FundraisingDirectExpensesAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetIncmFromFundraisingEvtGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GamingGrossIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GamingDirectExpensesAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetIncomeFromGamingGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    GrossSalesOfInventoryAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    CostOfGoodsSoldAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    NetIncomeOrLossGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc>}
     * @memberof ExecRiskIRS990
     */
    OtherRevenueMiscGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMisc>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    MiscellaneousRevenueGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    OtherRevenueTotalAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>}
     * @memberof ExecRiskIRS990
     */
    TotalRevenueGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartIXInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    GrantsToDomesticOrgsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    GrantsToDomesticIndividualsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    ForeignGrantsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    BenefitsToMembersGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    CompCurrentOfcrDirectorsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    CompDisqualPersonsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherSalariesAndWagesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PensionPlanContributionsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherEmployeeBenefitsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PayrollTaxesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesManagementGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesLegalGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesAccountingGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesLobbyingGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesProfFundraising: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForSrvcInvstMgmntFeesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    FeesForServicesOtherGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    AdvertisingGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OfficeExpensesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    InformationTechnologyGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    RoyaltiesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OccupancyGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    TravelGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PymtTravelEntrtnmntPubOfclGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    ConferencesMeetingsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    InterestGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    PaymentsToAffiliatesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    DepreciationDepletionGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    InsuranceGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherExpensesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    AllOtherExpensesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalFunctionalExpensesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    JointCostsInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalJointCostsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartXInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    CashNonInterestBearingGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    SavingsAndTempCashInvstGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    PledgesAndGrantsReceivableGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    AccountsReceivableGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    ReceivablesFromOfficersEtcGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    RcvblFromDisqualifiedPrsnGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    OthNotesLoansReceivableNetGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InventoriesForSaleOrUseGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    PrepaidExpensesDefrdChargesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    LandBldgEquipCostOrOtherBssAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    LandBldgEquipAccumDeprecAmt: number;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    LandBldgEquipBasisNetGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InvestmentsPubTradedSecGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InvestmentsOtherSecuritiesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    InvestmentsProgramRelatedGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    IntangibleAssetsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherAssetsTotalGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalAssetsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    AccountsPayableAccrExpnssGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    GrantsPayableGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    DeferredRevenueGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TaxExemptBondLiabilitiesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    EscrowAccountLiabilityGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    LoansFromOfficersDirectorsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    MortgNotesPyblScrdInvstPropGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    UnsecuredNotesLoansPayableGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    OtherLiabilitiesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalLiabilitiesGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OrganizationFollowsFASB117Ind: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    NoDonorRestrictionNetAssetsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    DonorRestrictionNetAssetsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    OrgDoesNotFollowFASB117Ind: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    CapStkTrPrinCurrentFundsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    PdInCapSrplsLandBldgEqpFundGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    RtnEarnEndowmentIncmOthFndsGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotalNetAssetsFundBalanceGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>}
     * @memberof ExecRiskIRS990
     */
    TotLiabNetAssetsFundBalanceGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmt>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartXIInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    ReconcilationRevenueExpnssAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    NetUnrlzdGainsLossesInvstAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    DonatedServicesAndUseFcltsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    InvestmentExpenseAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    PriorPeriodAdjustmentsAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskIRS990
     */
    OtherChangesInNetAssetsAmt: number;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    InfoInScheduleOPartXIIInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MethodOfAccountingCashInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MethodOfAccountingAccrualInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    MethodOfAccountingOtherInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AccountantCompileOrReviewInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>}
     * @memberof ExecRiskIRS990
     */
    AcctCompileOrReviewBasisGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FSAuditedInd: string;
    /**
     * 
     * @type {Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>}
     * @memberof ExecRiskIRS990
     */
    FSAuditedBasisGrp: Array<ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis>;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    AuditCommitteeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FederalGrantAuditRequiredInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskIRS990
     */
    FederalGrantAuditPerformedInd: string;
}

/**
 * Check if a given object implements the ExecRiskIRS990 interface.
 */
export function instanceOfExecRiskIRS990(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "SpecialConditionDesc" in value;
    isInstance = isInstance && "accountingPeriodChangeCd" in value;
    isInstance = isInstance && "accountingPeriodChangeApprvCd" in value;
    isInstance = isInstance && "AddressChangeInd" in value;
    isInstance = isInstance && "NameChangeInd" in value;
    isInstance = isInstance && "InitialReturnInd" in value;
    isInstance = isInstance && "FinalReturnInd" in value;
    isInstance = isInstance && "AmendedReturnInd" in value;
    isInstance = isInstance && "ApplicationPendingInd" in value;
    isInstance = isInstance && "DoingBusinessAsName" in value;
    isInstance = isInstance && "GrossReceiptsAmt" in value;
    isInstance = isInstance && "PrincipalOfficerNm" in value;
    isInstance = isInstance && "PrincipalOfcrBusinessName" in value;
    isInstance = isInstance && "USAddress" in value;
    isInstance = isInstance && "ForeignAddress" in value;
    isInstance = isInstance && "Organization501c3Ind" in value;
    isInstance = isInstance && "organization_501_c_ind" in value;
    isInstance = isInstance && "organization_501_c_type_text" in value;
    isInstance = isInstance && "Organization4947a1NotPFInd" in value;
    isInstance = isInstance && "Organization527Ind" in value;
    isInstance = isInstance && "WebsiteAddressTxt" in value;
    isInstance = isInstance && "GroupReturnForAffiliatesInd" in value;
    isInstance = isInstance && "AllAffiliatesIncludedInd" in value;
    isInstance = isInstance && "GroupExemptionNum" in value;
    isInstance = isInstance && "TypeOfOrganizationCorpInd" in value;
    isInstance = isInstance && "TypeOfOrganizationTrustInd" in value;
    isInstance = isInstance && "TypeOfOrganizationAssocInd" in value;
    isInstance = isInstance && "TypeOfOrganizationOtherInd" in value;
    isInstance = isInstance && "OtherOrganizationDsc" in value;
    isInstance = isInstance && "FormationYr" in value;
    isInstance = isInstance && "LegalDomicileStateCd" in value;
    isInstance = isInstance && "LegalDomicileCountryCd" in value;
    isInstance = isInstance && "ActivityOrMissionDesc" in value;
    isInstance = isInstance && "ContractTerminationInd" in value;
    isInstance = isInstance && "VotingMembersGoverningBodyCnt" in value;
    isInstance = isInstance && "VotingMembersIndependentCnt" in value;
    isInstance = isInstance && "TotalEmployeeCnt" in value;
    isInstance = isInstance && "TotalVolunteersCnt" in value;
    isInstance = isInstance && "TotalGrossUBIAmt" in value;
    isInstance = isInstance && "NetUnrelatedBusTxblIncmAmt" in value;
    isInstance = isInstance && "PYContributionsGrantsAmt" in value;
    isInstance = isInstance && "CYContributionsGrantsAmt" in value;
    isInstance = isInstance && "PYProgramServiceRevenueAmt" in value;
    isInstance = isInstance && "CYProgramServiceRevenueAmt" in value;
    isInstance = isInstance && "PYInvestmentIncomeAmt" in value;
    isInstance = isInstance && "CYInvestmentIncomeAmt" in value;
    isInstance = isInstance && "PYOtherRevenueAmt" in value;
    isInstance = isInstance && "PYTotalRevenueAmt" in value;
    isInstance = isInstance && "CYTotalRevenueAmt" in value;
    isInstance = isInstance && "PYGrantsAndSimilarPaidAmt" in value;
    isInstance = isInstance && "CYGrantsAndSimilarPaidAmt" in value;
    isInstance = isInstance && "PYBenefitsPaidToMembersAmt" in value;
    isInstance = isInstance && "CYBenefitsPaidToMembersAmt" in value;
    isInstance = isInstance && "PYSalariesCompEmpBnftPaidAmt" in value;
    isInstance = isInstance && "CYSalariesCompEmpBnftPaidAmt" in value;
    isInstance = isInstance && "PYTotalProfFndrsngExpnsAmt" in value;
    isInstance = isInstance && "CYTotalProfFndrsngExpnsAmt" in value;
    isInstance = isInstance && "CYTotalFundraisingExpenseAmt" in value;
    isInstance = isInstance && "PYOtherExpensesAmt" in value;
    isInstance = isInstance && "CYOtherExpensesAmt" in value;
    isInstance = isInstance && "PYTotalExpensesAmt" in value;
    isInstance = isInstance && "CYTotalExpensesAmt" in value;
    isInstance = isInstance && "PYRevenuesLessExpensesAmt" in value;
    isInstance = isInstance && "CYRevenuesLessExpensesAmt" in value;
    isInstance = isInstance && "TotalAssetsBOYAmt" in value;
    isInstance = isInstance && "TotalAssetsEOYAmt" in value;
    isInstance = isInstance && "NetAssetsOrFundBalancesBOYAmt" in value;
    isInstance = isInstance && "NetAssetsOrFundBalancesEOYAmt" in value;
    isInstance = isInstance && "InfoInScheduleOPartIIIInd" in value;
    isInstance = isInstance && "MissionDesc" in value;
    isInstance = isInstance && "SignificantNewProgramSrvcInd" in value;
    isInstance = isInstance && "SignificantChangeInd" in value;
    isInstance = isInstance && "ActivityCd" in value;
    isInstance = isInstance && "ExpenseAmt" in value;
    isInstance = isInstance && "GrantAmt" in value;
    isInstance = isInstance && "RevenueAmt" in value;
    isInstance = isInstance && "Desc" in value;
    isInstance = isInstance && "ProgSrvcAccomActy2Grp" in value;
    isInstance = isInstance && "ProgSrvcAccomActy3Grp" in value;
    isInstance = isInstance && "ProgSrvcAccomActyOtherGrp" in value;
    isInstance = isInstance && "TotalOtherProgSrvcExpenseAmt" in value;
    isInstance = isInstance && "TotalOtherProgSrvcGrantAmt" in value;
    isInstance = isInstance && "TotalOtherProgSrvcRevenueAmt" in value;
    isInstance = isInstance && "TotalProgramServiceExpensesAmt" in value;
    isInstance = isInstance && "DescribedInSection501c3Ind" in value;
    isInstance = isInstance && "ScheduleBRequiredInd" in value;
    isInstance = isInstance && "PoliticalCampaignActyInd" in value;
    isInstance = isInstance && "LobbyingActivitiesInd" in value;
    isInstance = isInstance && "SubjectToProxyTaxInd" in value;
    isInstance = isInstance && "DonorAdvisedFundInd" in value;
    isInstance = isInstance && "ConservationEasementsInd" in value;
    isInstance = isInstance && "CollectionsOfArtInd" in value;
    isInstance = isInstance && "CreditCounselingInd" in value;
    isInstance = isInstance && "DonorRstrOrQuasiEndowmentsInd" in value;
    isInstance = isInstance && "ReportLandBuildingEquipmentInd" in value;
    isInstance = isInstance && "ReportInvestmentsOtherSecInd" in value;
    isInstance = isInstance && "ReportProgramRelatedInvstInd" in value;
    isInstance = isInstance && "ReportOtherAssetsInd" in value;
    isInstance = isInstance && "ReportOtherLiabilitiesInd" in value;
    isInstance = isInstance && "IncludeFIN48FootnoteInd" in value;
    isInstance = isInstance && "IndependentAuditFinclStmtInd" in value;
    isInstance = isInstance && "ConsolidatedAuditFinclStmtInd" in value;
    isInstance = isInstance && "SchoolOperatingInd" in value;
    isInstance = isInstance && "ForeignOfficeInd" in value;
    isInstance = isInstance && "ForeignActivitiesInd" in value;
    isInstance = isInstance && "MoreThan5000KToOrgInd" in value;
    isInstance = isInstance && "MoreThan5000KToIndividualsInd" in value;
    isInstance = isInstance && "ProfessionalFundraisingInd" in value;
    isInstance = isInstance && "FundraisingActivitiesInd" in value;
    isInstance = isInstance && "GamingActivitiesInd" in value;
    isInstance = isInstance && "OperateHospitalInd" in value;
    isInstance = isInstance && "AuditedFinancialStmtAttInd" in value;
    isInstance = isInstance && "GrantsToOrganizationsInd" in value;
    isInstance = isInstance && "GrantsToIndividualsInd" in value;
    isInstance = isInstance && "ScheduleJRequiredInd" in value;
    isInstance = isInstance && "TaxExemptBondsInd" in value;
    isInstance = isInstance && "InvestTaxExemptBondsInd" in value;
    isInstance = isInstance && "EscrowAccountInd" in value;
    isInstance = isInstance && "OnBehalfOfIssuerInd" in value;
    isInstance = isInstance && "EngagedInExcessBenefitTransInd" in value;
    isInstance = isInstance && "PYExcessBenefitTransInd" in value;
    isInstance = isInstance && "LoanOutstandingInd" in value;
    isInstance = isInstance && "GrantToRelatedPersonInd" in value;
    isInstance = isInstance && "BusinessRlnWithOrgMemInd" in value;
    isInstance = isInstance && "BusinessRlnWithFamMemInd" in value;
    isInstance = isInstance && "BusinessRlnWith35CtrlEntInd" in value;
    isInstance = isInstance && "DeductibleNonCashContriInd" in value;
    isInstance = isInstance && "DeductibleArtContributionInd" in value;
    isInstance = isInstance && "TerminateOperationsInd" in value;
    isInstance = isInstance && "PartialLiquidationInd" in value;
    isInstance = isInstance && "DisregardedEntityInd" in value;
    isInstance = isInstance && "RelatedEntityInd" in value;
    isInstance = isInstance && "RelatedOrganizationCtrlEntInd" in value;
    isInstance = isInstance && "TransactionWithControlEntInd" in value;
    isInstance = isInstance && "TrnsfrExmptNonChrtblRltdOrgInd" in value;
    isInstance = isInstance && "ActivitiesConductedPrtshpInd" in value;
    isInstance = isInstance && "InfoInScheduleOPartVInd" in value;
    isInstance = isInstance && "IRPDocumentCnt" in value;
    isInstance = isInstance && "IRPDocumentW2GCnt" in value;
    isInstance = isInstance && "BackupWthldComplianceInd" in value;
    isInstance = isInstance && "EmployeeCnt" in value;
    isInstance = isInstance && "EmploymentTaxReturnsFiledInd" in value;
    isInstance = isInstance && "UnrelatedBusIncmOverLimitInd" in value;
    isInstance = isInstance && "Form990TFiledInd" in value;
    isInstance = isInstance && "ForeignFinancialAccountInd" in value;
    isInstance = isInstance && "ForeignCountryCd" in value;
    isInstance = isInstance && "ProhibitedTaxShelterTransInd" in value;
    isInstance = isInstance && "TaxablePartyNotificationInd" in value;
    isInstance = isInstance && "Form8886TFiledInd" in value;
    isInstance = isInstance && "NondeductibleContributionsInd" in value;
    isInstance = isInstance && "NondeductibleContriDisclInd" in value;
    isInstance = isInstance && "QuidProQuoContributionsInd" in value;
    isInstance = isInstance && "QuidProQuoContriDisclInd" in value;
    isInstance = isInstance && "Form8282PropertyDisposedOfInd" in value;
    isInstance = isInstance && "Form8282FiledCnt" in value;
    isInstance = isInstance && "RcvFndsToPayPrsnlBnftCntrctInd" in value;
    isInstance = isInstance && "PayPremiumsPrsnlBnftCntrctInd" in value;
    isInstance = isInstance && "Form8899Filedind" in value;
    isInstance = isInstance && "Form1098CFiledInd" in value;
    isInstance = isInstance && "DAFExcessBusinessHoldingsInd" in value;
    isInstance = isInstance && "TaxableDistributionsInd" in value;
    isInstance = isInstance && "DistributionToDonorInd" in value;
    isInstance = isInstance && "InitiationFeesAndCapContriAmt" in value;
    isInstance = isInstance && "GrossReceiptsForPublicUseAmt" in value;
    isInstance = isInstance && "MembersAndShrGrossIncomeAmt" in value;
    isInstance = isInstance && "OtherSourcesGrossIncomeAmt" in value;
    isInstance = isInstance && "OrgFiledInLieuOfForm1041Ind" in value;
    isInstance = isInstance && "TaxExemptInterestAmt" in value;
    isInstance = isInstance && "LicensedMoreThanOneStateInd" in value;
    isInstance = isInstance && "StateRequiredReservesAmt" in value;
    isInstance = isInstance && "ReservesMaintainedAmt" in value;
    isInstance = isInstance && "IndoorTanningServicesInd" in value;
    isInstance = isInstance && "Form720FiledInd" in value;
    isInstance = isInstance && "SubjToTaxRmnrtnExPrchtPymtInd" in value;
    isInstance = isInstance && "SubjectToExcsTaxNetInvstIncInd" in value;
    isInstance = isInstance && "ExcsTaxSect4951Or4952Or4953Ind" in value;
    isInstance = isInstance && "InfoInScheduleOPartVIInd" in value;
    isInstance = isInstance && "GoverningBodyVotingMembersCnt" in value;
    isInstance = isInstance && "IndependentVotingMemberCnt" in value;
    isInstance = isInstance && "FamilyOrBusinessRlnInd" in value;
    isInstance = isInstance && "DelegationOfMgmtDutiesInd" in value;
    isInstance = isInstance && "ChangeToOrgDocumentsInd" in value;
    isInstance = isInstance && "MaterialDiversionOrMisuseInd" in value;
    isInstance = isInstance && "MembersOrStockholdersInd" in value;
    isInstance = isInstance && "ElectionOfBoardMembersInd" in value;
    isInstance = isInstance && "DecisionsSubjectToApprovaInd" in value;
    isInstance = isInstance && "MinutesOfGoverningBodyInd" in value;
    isInstance = isInstance && "MinutesOfCommitteesInd" in value;
    isInstance = isInstance && "OfficerMailingAddressInd" in value;
    isInstance = isInstance && "LocalChaptersInd" in value;
    isInstance = isInstance && "PoliciesReferenceChaptersInd" in value;
    isInstance = isInstance && "Form990ProvidedToGvrnBodyInd" in value;
    isInstance = isInstance && "ConflictOfInterestPolicyInd" in value;
    isInstance = isInstance && "AnnualDisclosureCoveredPrsnInd" in value;
    isInstance = isInstance && "RegularMonitoringEnfrcInd" in value;
    isInstance = isInstance && "WhistleblowerPolicyInd" in value;
    isInstance = isInstance && "DocumentRetentionPolicyInd" in value;
    isInstance = isInstance && "CompensationProcessCEOInd" in value;
    isInstance = isInstance && "CompensationProcessOtherInd" in value;
    isInstance = isInstance && "InvestmentInJointVentureInd" in value;
    isInstance = isInstance && "WrittenPolicyOrProcedureInd" in value;
    isInstance = isInstance && "StatesWhereCopyOfReturnIsFldCd" in value;
    isInstance = isInstance && "OwnWebsiteInd" in value;
    isInstance = isInstance && "OtherWebsiteInd" in value;
    isInstance = isInstance && "UponRequestInd" in value;
    isInstance = isInstance && "OtherInd" in value;
    isInstance = isInstance && "BooksInCareOfDetail" in value;
    isInstance = isInstance && "InfoInScheduleOPartVIIInd" in value;
    isInstance = isInstance && "NoListedPersonsCompensatedInd" in value;
    isInstance = isInstance && "Form990PartVIISectionAGrp" in value;
    isInstance = isInstance && "TotalReportableCompFromOrgAmt" in value;
    isInstance = isInstance && "TotReportableCompRltdOrgAmt" in value;
    isInstance = isInstance && "TotalOtherCompensationAmt" in value;
    isInstance = isInstance && "IndivRcvdGreaterThan100KCnt" in value;
    isInstance = isInstance && "FormerOfcrEmployeesListedInd" in value;
    isInstance = isInstance && "CompensationFromOtherSrcsInd" in value;
    isInstance = isInstance && "ContractorCompensationGrp" in value;
    isInstance = isInstance && "CntrctRcvdGreaterThan100KCnt" in value;
    isInstance = isInstance && "InfoInScheduleOPartVIIIInd" in value;
    isInstance = isInstance && "FederatedCampaignsAmt" in value;
    isInstance = isInstance && "MembershipDuesAmt" in value;
    isInstance = isInstance && "FundraisingAmt" in value;
    isInstance = isInstance && "RelatedOrganizationsAmt" in value;
    isInstance = isInstance && "GovernmentGrantsAmt" in value;
    isInstance = isInstance && "AllOtherContributionsAmt" in value;
    isInstance = isInstance && "NoncashContributionsAmt" in value;
    isInstance = isInstance && "TotalContributionsAmt" in value;
    isInstance = isInstance && "ProgramServiceRevenueGrp" in value;
    isInstance = isInstance && "TotalOthProgramServiceRevGrp" in value;
    isInstance = isInstance && "TotalProgramServiceRevenueAmt" in value;
    isInstance = isInstance && "InvestmentIncomeGrp" in value;
    isInstance = isInstance && "IncmFromInvestBondProceedsGrp" in value;
    isInstance = isInstance && "RoyaltiesRevenueGrp" in value;
    isInstance = isInstance && "GrossRentsGrp" in value;
    isInstance = isInstance && "LessRentalExpensesGrp" in value;
    isInstance = isInstance && "RentalIncomeOrLossGrp" in value;
    isInstance = isInstance && "NetRentalIncomeOrLossGrp" in value;
    isInstance = isInstance && "GrossAmountSalesAssetsGrp" in value;
    isInstance = isInstance && "LessCostOthBasisSalesExpnssGrp" in value;
    isInstance = isInstance && "GainOrLossGrp" in value;
    isInstance = isInstance && "NetGainOrLossInvestmentsGrp" in value;
    isInstance = isInstance && "ContriRptFundraisingEventAmt" in value;
    isInstance = isInstance && "FundraisingGrossIncomeAmt" in value;
    isInstance = isInstance && "FundraisingDirectExpensesAmt" in value;
    isInstance = isInstance && "NetIncmFromFundraisingEvtGrp" in value;
    isInstance = isInstance && "GamingGrossIncomeAmt" in value;
    isInstance = isInstance && "GamingDirectExpensesAmt" in value;
    isInstance = isInstance && "NetIncomeFromGamingGrp" in value;
    isInstance = isInstance && "GrossSalesOfInventoryAmt" in value;
    isInstance = isInstance && "CostOfGoodsSoldAmt" in value;
    isInstance = isInstance && "NetIncomeOrLossGrp" in value;
    isInstance = isInstance && "OtherRevenueMiscGrp" in value;
    isInstance = isInstance && "MiscellaneousRevenueGrp" in value;
    isInstance = isInstance && "OtherRevenueTotalAmt" in value;
    isInstance = isInstance && "TotalRevenueGrp" in value;
    isInstance = isInstance && "InfoInScheduleOPartIXInd" in value;
    isInstance = isInstance && "GrantsToDomesticOrgsGrp" in value;
    isInstance = isInstance && "GrantsToDomesticIndividualsGrp" in value;
    isInstance = isInstance && "ForeignGrantsGrp" in value;
    isInstance = isInstance && "BenefitsToMembersGrp" in value;
    isInstance = isInstance && "CompCurrentOfcrDirectorsGrp" in value;
    isInstance = isInstance && "CompDisqualPersonsGrp" in value;
    isInstance = isInstance && "OtherSalariesAndWagesGrp" in value;
    isInstance = isInstance && "PensionPlanContributionsGrp" in value;
    isInstance = isInstance && "OtherEmployeeBenefitsGrp" in value;
    isInstance = isInstance && "PayrollTaxesGrp" in value;
    isInstance = isInstance && "FeesForServicesManagementGrp" in value;
    isInstance = isInstance && "FeesForServicesLegalGrp" in value;
    isInstance = isInstance && "FeesForServicesAccountingGrp" in value;
    isInstance = isInstance && "FeesForServicesLobbyingGrp" in value;
    isInstance = isInstance && "FeesForServicesProfFundraising" in value;
    isInstance = isInstance && "FeesForSrvcInvstMgmntFeesGrp" in value;
    isInstance = isInstance && "FeesForServicesOtherGrp" in value;
    isInstance = isInstance && "AdvertisingGrp" in value;
    isInstance = isInstance && "OfficeExpensesGrp" in value;
    isInstance = isInstance && "InformationTechnologyGrp" in value;
    isInstance = isInstance && "RoyaltiesGrp" in value;
    isInstance = isInstance && "OccupancyGrp" in value;
    isInstance = isInstance && "TravelGrp" in value;
    isInstance = isInstance && "PymtTravelEntrtnmntPubOfclGrp" in value;
    isInstance = isInstance && "ConferencesMeetingsGrp" in value;
    isInstance = isInstance && "InterestGrp" in value;
    isInstance = isInstance && "PaymentsToAffiliatesGrp" in value;
    isInstance = isInstance && "DepreciationDepletionGrp" in value;
    isInstance = isInstance && "InsuranceGrp" in value;
    isInstance = isInstance && "OtherExpensesGrp" in value;
    isInstance = isInstance && "AllOtherExpensesGrp" in value;
    isInstance = isInstance && "TotalFunctionalExpensesGrp" in value;
    isInstance = isInstance && "JointCostsInd" in value;
    isInstance = isInstance && "TotalJointCostsGrp" in value;
    isInstance = isInstance && "InfoInScheduleOPartXInd" in value;
    isInstance = isInstance && "CashNonInterestBearingGrp" in value;
    isInstance = isInstance && "SavingsAndTempCashInvstGrp" in value;
    isInstance = isInstance && "PledgesAndGrantsReceivableGrp" in value;
    isInstance = isInstance && "AccountsReceivableGrp" in value;
    isInstance = isInstance && "ReceivablesFromOfficersEtcGrp" in value;
    isInstance = isInstance && "RcvblFromDisqualifiedPrsnGrp" in value;
    isInstance = isInstance && "OthNotesLoansReceivableNetGrp" in value;
    isInstance = isInstance && "InventoriesForSaleOrUseGrp" in value;
    isInstance = isInstance && "PrepaidExpensesDefrdChargesGrp" in value;
    isInstance = isInstance && "LandBldgEquipCostOrOtherBssAmt" in value;
    isInstance = isInstance && "LandBldgEquipAccumDeprecAmt" in value;
    isInstance = isInstance && "LandBldgEquipBasisNetGrp" in value;
    isInstance = isInstance && "InvestmentsPubTradedSecGrp" in value;
    isInstance = isInstance && "InvestmentsOtherSecuritiesGrp" in value;
    isInstance = isInstance && "InvestmentsProgramRelatedGrp" in value;
    isInstance = isInstance && "IntangibleAssetsGrp" in value;
    isInstance = isInstance && "OtherAssetsTotalGrp" in value;
    isInstance = isInstance && "TotalAssetsGrp" in value;
    isInstance = isInstance && "AccountsPayableAccrExpnssGrp" in value;
    isInstance = isInstance && "GrantsPayableGrp" in value;
    isInstance = isInstance && "DeferredRevenueGrp" in value;
    isInstance = isInstance && "TaxExemptBondLiabilitiesGrp" in value;
    isInstance = isInstance && "EscrowAccountLiabilityGrp" in value;
    isInstance = isInstance && "LoansFromOfficersDirectorsGrp" in value;
    isInstance = isInstance && "MortgNotesPyblScrdInvstPropGrp" in value;
    isInstance = isInstance && "UnsecuredNotesLoansPayableGrp" in value;
    isInstance = isInstance && "OtherLiabilitiesGrp" in value;
    isInstance = isInstance && "TotalLiabilitiesGrp" in value;
    isInstance = isInstance && "OrganizationFollowsFASB117Ind" in value;
    isInstance = isInstance && "NoDonorRestrictionNetAssetsGrp" in value;
    isInstance = isInstance && "DonorRestrictionNetAssetsGrp" in value;
    isInstance = isInstance && "OrgDoesNotFollowFASB117Ind" in value;
    isInstance = isInstance && "CapStkTrPrinCurrentFundsGrp" in value;
    isInstance = isInstance && "PdInCapSrplsLandBldgEqpFundGrp" in value;
    isInstance = isInstance && "RtnEarnEndowmentIncmOthFndsGrp" in value;
    isInstance = isInstance && "TotalNetAssetsFundBalanceGrp" in value;
    isInstance = isInstance && "TotLiabNetAssetsFundBalanceGrp" in value;
    isInstance = isInstance && "InfoInScheduleOPartXIInd" in value;
    isInstance = isInstance && "ReconcilationRevenueExpnssAmt" in value;
    isInstance = isInstance && "NetUnrlzdGainsLossesInvstAmt" in value;
    isInstance = isInstance && "DonatedServicesAndUseFcltsAmt" in value;
    isInstance = isInstance && "InvestmentExpenseAmt" in value;
    isInstance = isInstance && "PriorPeriodAdjustmentsAmt" in value;
    isInstance = isInstance && "OtherChangesInNetAssetsAmt" in value;
    isInstance = isInstance && "InfoInScheduleOPartXIIInd" in value;
    isInstance = isInstance && "MethodOfAccountingCashInd" in value;
    isInstance = isInstance && "MethodOfAccountingAccrualInd" in value;
    isInstance = isInstance && "MethodOfAccountingOtherInd" in value;
    isInstance = isInstance && "AccountantCompileOrReviewInd" in value;
    isInstance = isInstance && "AcctCompileOrReviewBasisGrp" in value;
    isInstance = isInstance && "FSAuditedInd" in value;
    isInstance = isInstance && "FSAuditedBasisGrp" in value;
    isInstance = isInstance && "AuditCommitteeInd" in value;
    isInstance = isInstance && "FederalGrantAuditRequiredInd" in value;
    isInstance = isInstance && "FederalGrantAuditPerformedInd" in value;

    return isInstance;
}

export function ExecRiskIRS990FromJSON(json: any): ExecRiskIRS990 {
    return ExecRiskIRS990FromJSONTyped(json, false);
}

export function ExecRiskIRS990FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskIRS990 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SpecialConditionDesc': json['SpecialConditionDesc'],
        'accountingPeriodChangeCd': json['accountingPeriodChangeCd'],
        'accountingPeriodChangeApprvCd': json['accountingPeriodChangeApprvCd'],
        'AddressChangeInd': json['AddressChangeInd'],
        'NameChangeInd': json['NameChangeInd'],
        'InitialReturnInd': json['InitialReturnInd'],
        'FinalReturnInd': json['FinalReturnInd'],
        'AmendedReturnInd': json['AmendedReturnInd'],
        'ApplicationPendingInd': json['ApplicationPendingInd'],
        'DoingBusinessAsName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['DoingBusinessAsName']),
        'GrossReceiptsAmt': json['GrossReceiptsAmt'],
        'PrincipalOfficerNm': json['PrincipalOfficerNm'],
        'PrincipalOfcrBusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['PrincipalOfcrBusinessName']),
        'USAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'Organization501c3Ind': json['Organization501c3Ind'],
        'organization_501_c_ind': json['organization_501_c_ind'],
        'organization_501_c_type_text': json['organization_501_c_type_text'],
        'Organization4947a1NotPFInd': json['Organization4947a1NotPFInd'],
        'Organization527Ind': json['Organization527Ind'],
        'WebsiteAddressTxt': json['WebsiteAddressTxt'],
        'GroupReturnForAffiliatesInd': json['GroupReturnForAffiliatesInd'],
        'AllAffiliatesIncludedInd': json['AllAffiliatesIncludedInd'],
        'GroupExemptionNum': json['GroupExemptionNum'],
        'TypeOfOrganizationCorpInd': json['TypeOfOrganizationCorpInd'],
        'TypeOfOrganizationTrustInd': json['TypeOfOrganizationTrustInd'],
        'TypeOfOrganizationAssocInd': json['TypeOfOrganizationAssocInd'],
        'TypeOfOrganizationOtherInd': json['TypeOfOrganizationOtherInd'],
        'OtherOrganizationDsc': json['OtherOrganizationDsc'],
        'FormationYr': json['FormationYr'],
        'LegalDomicileStateCd': json['LegalDomicileStateCd'],
        'LegalDomicileCountryCd': json['LegalDomicileCountryCd'],
        'ActivityOrMissionDesc': json['ActivityOrMissionDesc'],
        'ContractTerminationInd': json['ContractTerminationInd'],
        'VotingMembersGoverningBodyCnt': json['VotingMembersGoverningBodyCnt'],
        'VotingMembersIndependentCnt': json['VotingMembersIndependentCnt'],
        'TotalEmployeeCnt': json['TotalEmployeeCnt'],
        'TotalVolunteersCnt': json['TotalVolunteersCnt'],
        'TotalGrossUBIAmt': json['TotalGrossUBIAmt'],
        'NetUnrelatedBusTxblIncmAmt': json['NetUnrelatedBusTxblIncmAmt'],
        'PYContributionsGrantsAmt': json['PYContributionsGrantsAmt'],
        'CYContributionsGrantsAmt': json['CYContributionsGrantsAmt'],
        'PYProgramServiceRevenueAmt': json['PYProgramServiceRevenueAmt'],
        'CYProgramServiceRevenueAmt': json['CYProgramServiceRevenueAmt'],
        'PYInvestmentIncomeAmt': json['PYInvestmentIncomeAmt'],
        'CYInvestmentIncomeAmt': json['CYInvestmentIncomeAmt'],
        'PYOtherRevenueAmt': json['PYOtherRevenueAmt'],
        'PYTotalRevenueAmt': json['PYTotalRevenueAmt'],
        'CYTotalRevenueAmt': json['CYTotalRevenueAmt'],
        'PYGrantsAndSimilarPaidAmt': json['PYGrantsAndSimilarPaidAmt'],
        'CYGrantsAndSimilarPaidAmt': json['CYGrantsAndSimilarPaidAmt'],
        'PYBenefitsPaidToMembersAmt': json['PYBenefitsPaidToMembersAmt'],
        'CYBenefitsPaidToMembersAmt': json['CYBenefitsPaidToMembersAmt'],
        'PYSalariesCompEmpBnftPaidAmt': json['PYSalariesCompEmpBnftPaidAmt'],
        'CYSalariesCompEmpBnftPaidAmt': json['CYSalariesCompEmpBnftPaidAmt'],
        'PYTotalProfFndrsngExpnsAmt': json['PYTotalProfFndrsngExpnsAmt'],
        'CYTotalProfFndrsngExpnsAmt': json['CYTotalProfFndrsngExpnsAmt'],
        'CYTotalFundraisingExpenseAmt': json['CYTotalFundraisingExpenseAmt'],
        'PYOtherExpensesAmt': json['PYOtherExpensesAmt'],
        'CYOtherExpensesAmt': json['CYOtherExpensesAmt'],
        'PYTotalExpensesAmt': json['PYTotalExpensesAmt'],
        'CYTotalExpensesAmt': json['CYTotalExpensesAmt'],
        'PYRevenuesLessExpensesAmt': json['PYRevenuesLessExpensesAmt'],
        'CYRevenuesLessExpensesAmt': json['CYRevenuesLessExpensesAmt'],
        'TotalAssetsBOYAmt': json['TotalAssetsBOYAmt'],
        'TotalAssetsEOYAmt': json['TotalAssetsEOYAmt'],
        'NetAssetsOrFundBalancesBOYAmt': json['NetAssetsOrFundBalancesBOYAmt'],
        'NetAssetsOrFundBalancesEOYAmt': json['NetAssetsOrFundBalancesEOYAmt'],
        'InfoInScheduleOPartIIIInd': json['InfoInScheduleOPartIIIInd'],
        'MissionDesc': json['MissionDesc'],
        'SignificantNewProgramSrvcInd': json['SignificantNewProgramSrvcInd'],
        'SignificantChangeInd': json['SignificantChangeInd'],
        'ActivityCd': json['ActivityCd'],
        'ExpenseAmt': json['ExpenseAmt'],
        'GrantAmt': json['GrantAmt'],
        'RevenueAmt': json['RevenueAmt'],
        'Desc': json['Desc'],
        'ProgSrvcAccomActy2Grp': ((json['ProgSrvcAccomActy2Grp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON)),
        'ProgSrvcAccomActy3Grp': ((json['ProgSrvcAccomActy3Grp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON)),
        'ProgSrvcAccomActyOtherGrp': ((json['ProgSrvcAccomActyOtherGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyFromJSON)),
        'TotalOtherProgSrvcExpenseAmt': json['TotalOtherProgSrvcExpenseAmt'],
        'TotalOtherProgSrvcGrantAmt': json['TotalOtherProgSrvcGrantAmt'],
        'TotalOtherProgSrvcRevenueAmt': json['TotalOtherProgSrvcRevenueAmt'],
        'TotalProgramServiceExpensesAmt': json['TotalProgramServiceExpensesAmt'],
        'DescribedInSection501c3Ind': json['DescribedInSection501c3Ind'],
        'ScheduleBRequiredInd': json['ScheduleBRequiredInd'],
        'PoliticalCampaignActyInd': json['PoliticalCampaignActyInd'],
        'LobbyingActivitiesInd': json['LobbyingActivitiesInd'],
        'SubjectToProxyTaxInd': json['SubjectToProxyTaxInd'],
        'DonorAdvisedFundInd': json['DonorAdvisedFundInd'],
        'ConservationEasementsInd': json['ConservationEasementsInd'],
        'CollectionsOfArtInd': json['CollectionsOfArtInd'],
        'CreditCounselingInd': json['CreditCounselingInd'],
        'DonorRstrOrQuasiEndowmentsInd': json['DonorRstrOrQuasiEndowmentsInd'],
        'ReportLandBuildingEquipmentInd': json['ReportLandBuildingEquipmentInd'],
        'ReportInvestmentsOtherSecInd': json['ReportInvestmentsOtherSecInd'],
        'ReportProgramRelatedInvstInd': json['ReportProgramRelatedInvstInd'],
        'ReportOtherAssetsInd': json['ReportOtherAssetsInd'],
        'ReportOtherLiabilitiesInd': json['ReportOtherLiabilitiesInd'],
        'IncludeFIN48FootnoteInd': json['IncludeFIN48FootnoteInd'],
        'IndependentAuditFinclStmtInd': json['IndependentAuditFinclStmtInd'],
        'ConsolidatedAuditFinclStmtInd': json['ConsolidatedAuditFinclStmtInd'],
        'SchoolOperatingInd': json['SchoolOperatingInd'],
        'ForeignOfficeInd': json['ForeignOfficeInd'],
        'ForeignActivitiesInd': json['ForeignActivitiesInd'],
        'MoreThan5000KToOrgInd': json['MoreThan5000KToOrgInd'],
        'MoreThan5000KToIndividualsInd': json['MoreThan5000KToIndividualsInd'],
        'ProfessionalFundraisingInd': json['ProfessionalFundraisingInd'],
        'FundraisingActivitiesInd': json['FundraisingActivitiesInd'],
        'GamingActivitiesInd': json['GamingActivitiesInd'],
        'OperateHospitalInd': json['OperateHospitalInd'],
        'AuditedFinancialStmtAttInd': json['AuditedFinancialStmtAttInd'],
        'GrantsToOrganizationsInd': json['GrantsToOrganizationsInd'],
        'GrantsToIndividualsInd': json['GrantsToIndividualsInd'],
        'ScheduleJRequiredInd': json['ScheduleJRequiredInd'],
        'TaxExemptBondsInd': json['TaxExemptBondsInd'],
        'InvestTaxExemptBondsInd': json['InvestTaxExemptBondsInd'],
        'EscrowAccountInd': json['EscrowAccountInd'],
        'OnBehalfOfIssuerInd': json['OnBehalfOfIssuerInd'],
        'EngagedInExcessBenefitTransInd': json['EngagedInExcessBenefitTransInd'],
        'PYExcessBenefitTransInd': json['PYExcessBenefitTransInd'],
        'LoanOutstandingInd': json['LoanOutstandingInd'],
        'GrantToRelatedPersonInd': json['GrantToRelatedPersonInd'],
        'BusinessRlnWithOrgMemInd': json['BusinessRlnWithOrgMemInd'],
        'BusinessRlnWithFamMemInd': json['BusinessRlnWithFamMemInd'],
        'BusinessRlnWith35CtrlEntInd': json['BusinessRlnWith35CtrlEntInd'],
        'DeductibleNonCashContriInd': json['DeductibleNonCashContriInd'],
        'DeductibleArtContributionInd': json['DeductibleArtContributionInd'],
        'TerminateOperationsInd': json['TerminateOperationsInd'],
        'PartialLiquidationInd': json['PartialLiquidationInd'],
        'DisregardedEntityInd': json['DisregardedEntityInd'],
        'RelatedEntityInd': json['RelatedEntityInd'],
        'RelatedOrganizationCtrlEntInd': json['RelatedOrganizationCtrlEntInd'],
        'TransactionWithControlEntInd': json['TransactionWithControlEntInd'],
        'TrnsfrExmptNonChrtblRltdOrgInd': json['TrnsfrExmptNonChrtblRltdOrgInd'],
        'ActivitiesConductedPrtshpInd': json['ActivitiesConductedPrtshpInd'],
        'InfoInScheduleOPartVInd': json['InfoInScheduleOPartVInd'],
        'IRPDocumentCnt': json['IRPDocumentCnt'],
        'IRPDocumentW2GCnt': json['IRPDocumentW2GCnt'],
        'BackupWthldComplianceInd': json['BackupWthldComplianceInd'],
        'EmployeeCnt': json['EmployeeCnt'],
        'EmploymentTaxReturnsFiledInd': json['EmploymentTaxReturnsFiledInd'],
        'UnrelatedBusIncmOverLimitInd': json['UnrelatedBusIncmOverLimitInd'],
        'Form990TFiledInd': json['Form990TFiledInd'],
        'ForeignFinancialAccountInd': json['ForeignFinancialAccountInd'],
        'ForeignCountryCd': json['ForeignCountryCd'],
        'ProhibitedTaxShelterTransInd': json['ProhibitedTaxShelterTransInd'],
        'TaxablePartyNotificationInd': json['TaxablePartyNotificationInd'],
        'Form8886TFiledInd': json['Form8886TFiledInd'],
        'NondeductibleContributionsInd': json['NondeductibleContributionsInd'],
        'NondeductibleContriDisclInd': json['NondeductibleContriDisclInd'],
        'QuidProQuoContributionsInd': json['QuidProQuoContributionsInd'],
        'QuidProQuoContriDisclInd': json['QuidProQuoContriDisclInd'],
        'Form8282PropertyDisposedOfInd': json['Form8282PropertyDisposedOfInd'],
        'Form8282FiledCnt': json['Form8282FiledCnt'],
        'RcvFndsToPayPrsnlBnftCntrctInd': json['RcvFndsToPayPrsnlBnftCntrctInd'],
        'PayPremiumsPrsnlBnftCntrctInd': json['PayPremiumsPrsnlBnftCntrctInd'],
        'Form8899Filedind': json['Form8899Filedind'],
        'Form1098CFiledInd': json['Form1098CFiledInd'],
        'DAFExcessBusinessHoldingsInd': json['DAFExcessBusinessHoldingsInd'],
        'TaxableDistributionsInd': json['TaxableDistributionsInd'],
        'DistributionToDonorInd': json['DistributionToDonorInd'],
        'InitiationFeesAndCapContriAmt': json['InitiationFeesAndCapContriAmt'],
        'GrossReceiptsForPublicUseAmt': json['GrossReceiptsForPublicUseAmt'],
        'MembersAndShrGrossIncomeAmt': json['MembersAndShrGrossIncomeAmt'],
        'OtherSourcesGrossIncomeAmt': json['OtherSourcesGrossIncomeAmt'],
        'OrgFiledInLieuOfForm1041Ind': json['OrgFiledInLieuOfForm1041Ind'],
        'TaxExemptInterestAmt': json['TaxExemptInterestAmt'],
        'LicensedMoreThanOneStateInd': json['LicensedMoreThanOneStateInd'],
        'StateRequiredReservesAmt': json['StateRequiredReservesAmt'],
        'ReservesMaintainedAmt': json['ReservesMaintainedAmt'],
        'IndoorTanningServicesInd': json['IndoorTanningServicesInd'],
        'Form720FiledInd': json['Form720FiledInd'],
        'SubjToTaxRmnrtnExPrchtPymtInd': json['SubjToTaxRmnrtnExPrchtPymtInd'],
        'SubjectToExcsTaxNetInvstIncInd': json['SubjectToExcsTaxNetInvstIncInd'],
        'ExcsTaxSect4951Or4952Or4953Ind': json['ExcsTaxSect4951Or4952Or4953Ind'],
        'InfoInScheduleOPartVIInd': json['InfoInScheduleOPartVIInd'],
        'GoverningBodyVotingMembersCnt': json['GoverningBodyVotingMembersCnt'],
        'IndependentVotingMemberCnt': json['IndependentVotingMemberCnt'],
        'FamilyOrBusinessRlnInd': json['FamilyOrBusinessRlnInd'],
        'DelegationOfMgmtDutiesInd': json['DelegationOfMgmtDutiesInd'],
        'ChangeToOrgDocumentsInd': json['ChangeToOrgDocumentsInd'],
        'MaterialDiversionOrMisuseInd': json['MaterialDiversionOrMisuseInd'],
        'MembersOrStockholdersInd': json['MembersOrStockholdersInd'],
        'ElectionOfBoardMembersInd': json['ElectionOfBoardMembersInd'],
        'DecisionsSubjectToApprovaInd': json['DecisionsSubjectToApprovaInd'],
        'MinutesOfGoverningBodyInd': json['MinutesOfGoverningBodyInd'],
        'MinutesOfCommitteesInd': json['MinutesOfCommitteesInd'],
        'OfficerMailingAddressInd': json['OfficerMailingAddressInd'],
        'LocalChaptersInd': json['LocalChaptersInd'],
        'PoliciesReferenceChaptersInd': json['PoliciesReferenceChaptersInd'],
        'Form990ProvidedToGvrnBodyInd': json['Form990ProvidedToGvrnBodyInd'],
        'ConflictOfInterestPolicyInd': json['ConflictOfInterestPolicyInd'],
        'AnnualDisclosureCoveredPrsnInd': json['AnnualDisclosureCoveredPrsnInd'],
        'RegularMonitoringEnfrcInd': json['RegularMonitoringEnfrcInd'],
        'WhistleblowerPolicyInd': json['WhistleblowerPolicyInd'],
        'DocumentRetentionPolicyInd': json['DocumentRetentionPolicyInd'],
        'CompensationProcessCEOInd': json['CompensationProcessCEOInd'],
        'CompensationProcessOtherInd': json['CompensationProcessOtherInd'],
        'InvestmentInJointVentureInd': json['InvestmentInJointVentureInd'],
        'WrittenPolicyOrProcedureInd': json['WrittenPolicyOrProcedureInd'],
        'StatesWhereCopyOfReturnIsFldCd': json['StatesWhereCopyOfReturnIsFldCd'],
        'OwnWebsiteInd': json['OwnWebsiteInd'],
        'OtherWebsiteInd': json['OtherWebsiteInd'],
        'UponRequestInd': json['UponRequestInd'],
        'OtherInd': json['OtherInd'],
        'BooksInCareOfDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailFromJSON(json['BooksInCareOfDetail']),
        'InfoInScheduleOPartVIIInd': json['InfoInScheduleOPartVIIInd'],
        'NoListedPersonsCompensatedInd': json['NoListedPersonsCompensatedInd'],
        'Form990PartVIISectionAGrp': ((json['Form990PartVIISectionAGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSON)),
        'TotalReportableCompFromOrgAmt': json['TotalReportableCompFromOrgAmt'],
        'TotReportableCompRltdOrgAmt': json['TotReportableCompRltdOrgAmt'],
        'TotalOtherCompensationAmt': json['TotalOtherCompensationAmt'],
        'IndivRcvdGreaterThan100KCnt': json['IndivRcvdGreaterThan100KCnt'],
        'FormerOfcrEmployeesListedInd': json['FormerOfcrEmployeesListedInd'],
        'CompensationFromOtherSrcsInd': json['CompensationFromOtherSrcsInd'],
        'ContractorCompensationGrp': ((json['ContractorCompensationGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationFromJSON)),
        'CntrctRcvdGreaterThan100KCnt': json['CntrctRcvdGreaterThan100KCnt'],
        'InfoInScheduleOPartVIIIInd': json['InfoInScheduleOPartVIIIInd'],
        'FederatedCampaignsAmt': json['FederatedCampaignsAmt'],
        'MembershipDuesAmt': json['MembershipDuesAmt'],
        'FundraisingAmt': json['FundraisingAmt'],
        'RelatedOrganizationsAmt': json['RelatedOrganizationsAmt'],
        'GovernmentGrantsAmt': json['GovernmentGrantsAmt'],
        'AllOtherContributionsAmt': json['AllOtherContributionsAmt'],
        'NoncashContributionsAmt': json['NoncashContributionsAmt'],
        'TotalContributionsAmt': json['TotalContributionsAmt'],
        'ProgramServiceRevenueGrp': ((json['ProgramServiceRevenueGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueFromJSON)),
        'TotalOthProgramServiceRevGrp': ((json['TotalOthProgramServiceRevGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'TotalProgramServiceRevenueAmt': json['TotalProgramServiceRevenueAmt'],
        'InvestmentIncomeGrp': ((json['InvestmentIncomeGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'IncmFromInvestBondProceedsGrp': ((json['IncmFromInvestBondProceedsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'RoyaltiesRevenueGrp': ((json['RoyaltiesRevenueGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GrossRentsGrp': ((json['GrossRentsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON)),
        'LessRentalExpensesGrp': ((json['LessRentalExpensesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON)),
        'RentalIncomeOrLossGrp': ((json['RentalIncomeOrLossGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtFromJSON)),
        'NetRentalIncomeOrLossGrp': ((json['NetRentalIncomeOrLossGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GrossAmountSalesAssetsGrp': ((json['GrossAmountSalesAssetsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON)),
        'LessCostOthBasisSalesExpnssGrp': ((json['LessCostOthBasisSalesExpnssGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON)),
        'GainOrLossGrp': ((json['GainOrLossGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtFromJSON)),
        'NetGainOrLossInvestmentsGrp': ((json['NetGainOrLossInvestmentsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'ContriRptFundraisingEventAmt': json['ContriRptFundraisingEventAmt'],
        'FundraisingGrossIncomeAmt': json['FundraisingGrossIncomeAmt'],
        'FundraisingDirectExpensesAmt': json['FundraisingDirectExpensesAmt'],
        'NetIncmFromFundraisingEvtGrp': ((json['NetIncmFromFundraisingEvtGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GamingGrossIncomeAmt': json['GamingGrossIncomeAmt'],
        'GamingDirectExpensesAmt': json['GamingDirectExpensesAmt'],
        'NetIncomeFromGamingGrp': ((json['NetIncomeFromGamingGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'GrossSalesOfInventoryAmt': json['GrossSalesOfInventoryAmt'],
        'CostOfGoodsSoldAmt': json['CostOfGoodsSoldAmt'],
        'NetIncomeOrLossGrp': ((json['NetIncomeOrLossGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'OtherRevenueMiscGrp': ((json['OtherRevenueMiscGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscFromJSON)),
        'MiscellaneousRevenueGrp': ((json['MiscellaneousRevenueGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'OtherRevenueTotalAmt': json['OtherRevenueTotalAmt'],
        'TotalRevenueGrp': ((json['TotalRevenueGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON)),
        'InfoInScheduleOPartIXInd': json['InfoInScheduleOPartIXInd'],
        'GrantsToDomesticOrgsGrp': ((json['GrantsToDomesticOrgsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'GrantsToDomesticIndividualsGrp': ((json['GrantsToDomesticIndividualsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'ForeignGrantsGrp': ((json['ForeignGrantsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'BenefitsToMembersGrp': ((json['BenefitsToMembersGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'CompCurrentOfcrDirectorsGrp': ((json['CompCurrentOfcrDirectorsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'CompDisqualPersonsGrp': ((json['CompDisqualPersonsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OtherSalariesAndWagesGrp': ((json['OtherSalariesAndWagesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PensionPlanContributionsGrp': ((json['PensionPlanContributionsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OtherEmployeeBenefitsGrp': ((json['OtherEmployeeBenefitsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PayrollTaxesGrp': ((json['PayrollTaxesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesManagementGrp': ((json['FeesForServicesManagementGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesLegalGrp': ((json['FeesForServicesLegalGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesAccountingGrp': ((json['FeesForServicesAccountingGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesLobbyingGrp': ((json['FeesForServicesLobbyingGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesProfFundraising': ((json['FeesForServicesProfFundraising'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForSrvcInvstMgmntFeesGrp': ((json['FeesForSrvcInvstMgmntFeesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'FeesForServicesOtherGrp': ((json['FeesForServicesOtherGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'AdvertisingGrp': ((json['AdvertisingGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OfficeExpensesGrp': ((json['OfficeExpensesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InformationTechnologyGrp': ((json['InformationTechnologyGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'RoyaltiesGrp': ((json['RoyaltiesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OccupancyGrp': ((json['OccupancyGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'TravelGrp': ((json['TravelGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PymtTravelEntrtnmntPubOfclGrp': ((json['PymtTravelEntrtnmntPubOfclGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'ConferencesMeetingsGrp': ((json['ConferencesMeetingsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InterestGrp': ((json['InterestGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'PaymentsToAffiliatesGrp': ((json['PaymentsToAffiliatesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'DepreciationDepletionGrp': ((json['DepreciationDepletionGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InsuranceGrp': ((json['InsuranceGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'OtherExpensesGrp': ((json['OtherExpensesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'AllOtherExpensesGrp': ((json['AllOtherExpensesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'TotalFunctionalExpensesGrp': ((json['TotalFunctionalExpensesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'JointCostsInd': json['JointCostsInd'],
        'TotalJointCostsGrp': ((json['TotalJointCostsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtFromJSON)),
        'InfoInScheduleOPartXInd': json['InfoInScheduleOPartXInd'],
        'CashNonInterestBearingGrp': ((json['CashNonInterestBearingGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'SavingsAndTempCashInvstGrp': ((json['SavingsAndTempCashInvstGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'PledgesAndGrantsReceivableGrp': ((json['PledgesAndGrantsReceivableGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'AccountsReceivableGrp': ((json['AccountsReceivableGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'ReceivablesFromOfficersEtcGrp': ((json['ReceivablesFromOfficersEtcGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'RcvblFromDisqualifiedPrsnGrp': ((json['RcvblFromDisqualifiedPrsnGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OthNotesLoansReceivableNetGrp': ((json['OthNotesLoansReceivableNetGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InventoriesForSaleOrUseGrp': ((json['InventoriesForSaleOrUseGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'PrepaidExpensesDefrdChargesGrp': ((json['PrepaidExpensesDefrdChargesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'LandBldgEquipCostOrOtherBssAmt': json['LandBldgEquipCostOrOtherBssAmt'],
        'LandBldgEquipAccumDeprecAmt': json['LandBldgEquipAccumDeprecAmt'],
        'LandBldgEquipBasisNetGrp': ((json['LandBldgEquipBasisNetGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InvestmentsPubTradedSecGrp': ((json['InvestmentsPubTradedSecGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InvestmentsOtherSecuritiesGrp': ((json['InvestmentsOtherSecuritiesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InvestmentsProgramRelatedGrp': ((json['InvestmentsProgramRelatedGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'IntangibleAssetsGrp': ((json['IntangibleAssetsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OtherAssetsTotalGrp': ((json['OtherAssetsTotalGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotalAssetsGrp': ((json['TotalAssetsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'AccountsPayableAccrExpnssGrp': ((json['AccountsPayableAccrExpnssGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'GrantsPayableGrp': ((json['GrantsPayableGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'DeferredRevenueGrp': ((json['DeferredRevenueGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TaxExemptBondLiabilitiesGrp': ((json['TaxExemptBondLiabilitiesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'EscrowAccountLiabilityGrp': ((json['EscrowAccountLiabilityGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'LoansFromOfficersDirectorsGrp': ((json['LoansFromOfficersDirectorsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'MortgNotesPyblScrdInvstPropGrp': ((json['MortgNotesPyblScrdInvstPropGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'UnsecuredNotesLoansPayableGrp': ((json['UnsecuredNotesLoansPayableGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OtherLiabilitiesGrp': ((json['OtherLiabilitiesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotalLiabilitiesGrp': ((json['TotalLiabilitiesGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OrganizationFollowsFASB117Ind': json['OrganizationFollowsFASB117Ind'],
        'NoDonorRestrictionNetAssetsGrp': ((json['NoDonorRestrictionNetAssetsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'DonorRestrictionNetAssetsGrp': ((json['DonorRestrictionNetAssetsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'OrgDoesNotFollowFASB117Ind': json['OrgDoesNotFollowFASB117Ind'],
        'CapStkTrPrinCurrentFundsGrp': ((json['CapStkTrPrinCurrentFundsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'PdInCapSrplsLandBldgEqpFundGrp': ((json['PdInCapSrplsLandBldgEqpFundGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'RtnEarnEndowmentIncmOthFndsGrp': ((json['RtnEarnEndowmentIncmOthFndsGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotalNetAssetsFundBalanceGrp': ((json['TotalNetAssetsFundBalanceGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'TotLiabNetAssetsFundBalanceGrp': ((json['TotLiabNetAssetsFundBalanceGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtFromJSON)),
        'InfoInScheduleOPartXIInd': json['InfoInScheduleOPartXIInd'],
        'ReconcilationRevenueExpnssAmt': json['ReconcilationRevenueExpnssAmt'],
        'NetUnrlzdGainsLossesInvstAmt': json['NetUnrlzdGainsLossesInvstAmt'],
        'DonatedServicesAndUseFcltsAmt': json['DonatedServicesAndUseFcltsAmt'],
        'InvestmentExpenseAmt': json['InvestmentExpenseAmt'],
        'PriorPeriodAdjustmentsAmt': json['PriorPeriodAdjustmentsAmt'],
        'OtherChangesInNetAssetsAmt': json['OtherChangesInNetAssetsAmt'],
        'InfoInScheduleOPartXIIInd': json['InfoInScheduleOPartXIIInd'],
        'MethodOfAccountingCashInd': json['MethodOfAccountingCashInd'],
        'MethodOfAccountingAccrualInd': json['MethodOfAccountingAccrualInd'],
        'MethodOfAccountingOtherInd': json['MethodOfAccountingOtherInd'],
        'AccountantCompileOrReviewInd': json['AccountantCompileOrReviewInd'],
        'AcctCompileOrReviewBasisGrp': ((json['AcctCompileOrReviewBasisGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON)),
        'FSAuditedInd': json['FSAuditedInd'],
        'FSAuditedBasisGrp': ((json['FSAuditedBasisGrp'] as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON)),
        'AuditCommitteeInd': json['AuditCommitteeInd'],
        'FederalGrantAuditRequiredInd': json['FederalGrantAuditRequiredInd'],
        'FederalGrantAuditPerformedInd': json['FederalGrantAuditPerformedInd'],
    };
}

export function ExecRiskIRS990ToJSON(value?: ExecRiskIRS990 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SpecialConditionDesc': value.SpecialConditionDesc,
        'accountingPeriodChangeCd': value.accountingPeriodChangeCd,
        'accountingPeriodChangeApprvCd': value.accountingPeriodChangeApprvCd,
        'AddressChangeInd': value.AddressChangeInd,
        'NameChangeInd': value.NameChangeInd,
        'InitialReturnInd': value.InitialReturnInd,
        'FinalReturnInd': value.FinalReturnInd,
        'AmendedReturnInd': value.AmendedReturnInd,
        'ApplicationPendingInd': value.ApplicationPendingInd,
        'DoingBusinessAsName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.DoingBusinessAsName),
        'GrossReceiptsAmt': value.GrossReceiptsAmt,
        'PrincipalOfficerNm': value.PrincipalOfficerNm,
        'PrincipalOfcrBusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.PrincipalOfcrBusinessName),
        'USAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'Organization501c3Ind': value.Organization501c3Ind,
        'organization_501_c_ind': value.organization_501_c_ind,
        'organization_501_c_type_text': value.organization_501_c_type_text,
        'Organization4947a1NotPFInd': value.Organization4947a1NotPFInd,
        'Organization527Ind': value.Organization527Ind,
        'WebsiteAddressTxt': value.WebsiteAddressTxt,
        'GroupReturnForAffiliatesInd': value.GroupReturnForAffiliatesInd,
        'AllAffiliatesIncludedInd': value.AllAffiliatesIncludedInd,
        'GroupExemptionNum': value.GroupExemptionNum,
        'TypeOfOrganizationCorpInd': value.TypeOfOrganizationCorpInd,
        'TypeOfOrganizationTrustInd': value.TypeOfOrganizationTrustInd,
        'TypeOfOrganizationAssocInd': value.TypeOfOrganizationAssocInd,
        'TypeOfOrganizationOtherInd': value.TypeOfOrganizationOtherInd,
        'OtherOrganizationDsc': value.OtherOrganizationDsc,
        'FormationYr': value.FormationYr,
        'LegalDomicileStateCd': value.LegalDomicileStateCd,
        'LegalDomicileCountryCd': value.LegalDomicileCountryCd,
        'ActivityOrMissionDesc': value.ActivityOrMissionDesc,
        'ContractTerminationInd': value.ContractTerminationInd,
        'VotingMembersGoverningBodyCnt': value.VotingMembersGoverningBodyCnt,
        'VotingMembersIndependentCnt': value.VotingMembersIndependentCnt,
        'TotalEmployeeCnt': value.TotalEmployeeCnt,
        'TotalVolunteersCnt': value.TotalVolunteersCnt,
        'TotalGrossUBIAmt': value.TotalGrossUBIAmt,
        'NetUnrelatedBusTxblIncmAmt': value.NetUnrelatedBusTxblIncmAmt,
        'PYContributionsGrantsAmt': value.PYContributionsGrantsAmt,
        'CYContributionsGrantsAmt': value.CYContributionsGrantsAmt,
        'PYProgramServiceRevenueAmt': value.PYProgramServiceRevenueAmt,
        'CYProgramServiceRevenueAmt': value.CYProgramServiceRevenueAmt,
        'PYInvestmentIncomeAmt': value.PYInvestmentIncomeAmt,
        'CYInvestmentIncomeAmt': value.CYInvestmentIncomeAmt,
        'PYOtherRevenueAmt': value.PYOtherRevenueAmt,
        'PYTotalRevenueAmt': value.PYTotalRevenueAmt,
        'CYTotalRevenueAmt': value.CYTotalRevenueAmt,
        'PYGrantsAndSimilarPaidAmt': value.PYGrantsAndSimilarPaidAmt,
        'CYGrantsAndSimilarPaidAmt': value.CYGrantsAndSimilarPaidAmt,
        'PYBenefitsPaidToMembersAmt': value.PYBenefitsPaidToMembersAmt,
        'CYBenefitsPaidToMembersAmt': value.CYBenefitsPaidToMembersAmt,
        'PYSalariesCompEmpBnftPaidAmt': value.PYSalariesCompEmpBnftPaidAmt,
        'CYSalariesCompEmpBnftPaidAmt': value.CYSalariesCompEmpBnftPaidAmt,
        'PYTotalProfFndrsngExpnsAmt': value.PYTotalProfFndrsngExpnsAmt,
        'CYTotalProfFndrsngExpnsAmt': value.CYTotalProfFndrsngExpnsAmt,
        'CYTotalFundraisingExpenseAmt': value.CYTotalFundraisingExpenseAmt,
        'PYOtherExpensesAmt': value.PYOtherExpensesAmt,
        'CYOtherExpensesAmt': value.CYOtherExpensesAmt,
        'PYTotalExpensesAmt': value.PYTotalExpensesAmt,
        'CYTotalExpensesAmt': value.CYTotalExpensesAmt,
        'PYRevenuesLessExpensesAmt': value.PYRevenuesLessExpensesAmt,
        'CYRevenuesLessExpensesAmt': value.CYRevenuesLessExpensesAmt,
        'TotalAssetsBOYAmt': value.TotalAssetsBOYAmt,
        'TotalAssetsEOYAmt': value.TotalAssetsEOYAmt,
        'NetAssetsOrFundBalancesBOYAmt': value.NetAssetsOrFundBalancesBOYAmt,
        'NetAssetsOrFundBalancesEOYAmt': value.NetAssetsOrFundBalancesEOYAmt,
        'InfoInScheduleOPartIIIInd': value.InfoInScheduleOPartIIIInd,
        'MissionDesc': value.MissionDesc,
        'SignificantNewProgramSrvcInd': value.SignificantNewProgramSrvcInd,
        'SignificantChangeInd': value.SignificantChangeInd,
        'ActivityCd': value.ActivityCd,
        'ExpenseAmt': value.ExpenseAmt,
        'GrantAmt': value.GrantAmt,
        'RevenueAmt': value.RevenueAmt,
        'Desc': value.Desc,
        'ProgSrvcAccomActy2Grp': ((value.ProgSrvcAccomActy2Grp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON)),
        'ProgSrvcAccomActy3Grp': ((value.ProgSrvcAccomActy3Grp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON)),
        'ProgSrvcAccomActyOtherGrp': ((value.ProgSrvcAccomActyOtherGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgSrvcAccomActyToJSON)),
        'TotalOtherProgSrvcExpenseAmt': value.TotalOtherProgSrvcExpenseAmt,
        'TotalOtherProgSrvcGrantAmt': value.TotalOtherProgSrvcGrantAmt,
        'TotalOtherProgSrvcRevenueAmt': value.TotalOtherProgSrvcRevenueAmt,
        'TotalProgramServiceExpensesAmt': value.TotalProgramServiceExpensesAmt,
        'DescribedInSection501c3Ind': value.DescribedInSection501c3Ind,
        'ScheduleBRequiredInd': value.ScheduleBRequiredInd,
        'PoliticalCampaignActyInd': value.PoliticalCampaignActyInd,
        'LobbyingActivitiesInd': value.LobbyingActivitiesInd,
        'SubjectToProxyTaxInd': value.SubjectToProxyTaxInd,
        'DonorAdvisedFundInd': value.DonorAdvisedFundInd,
        'ConservationEasementsInd': value.ConservationEasementsInd,
        'CollectionsOfArtInd': value.CollectionsOfArtInd,
        'CreditCounselingInd': value.CreditCounselingInd,
        'DonorRstrOrQuasiEndowmentsInd': value.DonorRstrOrQuasiEndowmentsInd,
        'ReportLandBuildingEquipmentInd': value.ReportLandBuildingEquipmentInd,
        'ReportInvestmentsOtherSecInd': value.ReportInvestmentsOtherSecInd,
        'ReportProgramRelatedInvstInd': value.ReportProgramRelatedInvstInd,
        'ReportOtherAssetsInd': value.ReportOtherAssetsInd,
        'ReportOtherLiabilitiesInd': value.ReportOtherLiabilitiesInd,
        'IncludeFIN48FootnoteInd': value.IncludeFIN48FootnoteInd,
        'IndependentAuditFinclStmtInd': value.IndependentAuditFinclStmtInd,
        'ConsolidatedAuditFinclStmtInd': value.ConsolidatedAuditFinclStmtInd,
        'SchoolOperatingInd': value.SchoolOperatingInd,
        'ForeignOfficeInd': value.ForeignOfficeInd,
        'ForeignActivitiesInd': value.ForeignActivitiesInd,
        'MoreThan5000KToOrgInd': value.MoreThan5000KToOrgInd,
        'MoreThan5000KToIndividualsInd': value.MoreThan5000KToIndividualsInd,
        'ProfessionalFundraisingInd': value.ProfessionalFundraisingInd,
        'FundraisingActivitiesInd': value.FundraisingActivitiesInd,
        'GamingActivitiesInd': value.GamingActivitiesInd,
        'OperateHospitalInd': value.OperateHospitalInd,
        'AuditedFinancialStmtAttInd': value.AuditedFinancialStmtAttInd,
        'GrantsToOrganizationsInd': value.GrantsToOrganizationsInd,
        'GrantsToIndividualsInd': value.GrantsToIndividualsInd,
        'ScheduleJRequiredInd': value.ScheduleJRequiredInd,
        'TaxExemptBondsInd': value.TaxExemptBondsInd,
        'InvestTaxExemptBondsInd': value.InvestTaxExemptBondsInd,
        'EscrowAccountInd': value.EscrowAccountInd,
        'OnBehalfOfIssuerInd': value.OnBehalfOfIssuerInd,
        'EngagedInExcessBenefitTransInd': value.EngagedInExcessBenefitTransInd,
        'PYExcessBenefitTransInd': value.PYExcessBenefitTransInd,
        'LoanOutstandingInd': value.LoanOutstandingInd,
        'GrantToRelatedPersonInd': value.GrantToRelatedPersonInd,
        'BusinessRlnWithOrgMemInd': value.BusinessRlnWithOrgMemInd,
        'BusinessRlnWithFamMemInd': value.BusinessRlnWithFamMemInd,
        'BusinessRlnWith35CtrlEntInd': value.BusinessRlnWith35CtrlEntInd,
        'DeductibleNonCashContriInd': value.DeductibleNonCashContriInd,
        'DeductibleArtContributionInd': value.DeductibleArtContributionInd,
        'TerminateOperationsInd': value.TerminateOperationsInd,
        'PartialLiquidationInd': value.PartialLiquidationInd,
        'DisregardedEntityInd': value.DisregardedEntityInd,
        'RelatedEntityInd': value.RelatedEntityInd,
        'RelatedOrganizationCtrlEntInd': value.RelatedOrganizationCtrlEntInd,
        'TransactionWithControlEntInd': value.TransactionWithControlEntInd,
        'TrnsfrExmptNonChrtblRltdOrgInd': value.TrnsfrExmptNonChrtblRltdOrgInd,
        'ActivitiesConductedPrtshpInd': value.ActivitiesConductedPrtshpInd,
        'InfoInScheduleOPartVInd': value.InfoInScheduleOPartVInd,
        'IRPDocumentCnt': value.IRPDocumentCnt,
        'IRPDocumentW2GCnt': value.IRPDocumentW2GCnt,
        'BackupWthldComplianceInd': value.BackupWthldComplianceInd,
        'EmployeeCnt': value.EmployeeCnt,
        'EmploymentTaxReturnsFiledInd': value.EmploymentTaxReturnsFiledInd,
        'UnrelatedBusIncmOverLimitInd': value.UnrelatedBusIncmOverLimitInd,
        'Form990TFiledInd': value.Form990TFiledInd,
        'ForeignFinancialAccountInd': value.ForeignFinancialAccountInd,
        'ForeignCountryCd': value.ForeignCountryCd,
        'ProhibitedTaxShelterTransInd': value.ProhibitedTaxShelterTransInd,
        'TaxablePartyNotificationInd': value.TaxablePartyNotificationInd,
        'Form8886TFiledInd': value.Form8886TFiledInd,
        'NondeductibleContributionsInd': value.NondeductibleContributionsInd,
        'NondeductibleContriDisclInd': value.NondeductibleContriDisclInd,
        'QuidProQuoContributionsInd': value.QuidProQuoContributionsInd,
        'QuidProQuoContriDisclInd': value.QuidProQuoContriDisclInd,
        'Form8282PropertyDisposedOfInd': value.Form8282PropertyDisposedOfInd,
        'Form8282FiledCnt': value.Form8282FiledCnt,
        'RcvFndsToPayPrsnlBnftCntrctInd': value.RcvFndsToPayPrsnlBnftCntrctInd,
        'PayPremiumsPrsnlBnftCntrctInd': value.PayPremiumsPrsnlBnftCntrctInd,
        'Form8899Filedind': value.Form8899Filedind,
        'Form1098CFiledInd': value.Form1098CFiledInd,
        'DAFExcessBusinessHoldingsInd': value.DAFExcessBusinessHoldingsInd,
        'TaxableDistributionsInd': value.TaxableDistributionsInd,
        'DistributionToDonorInd': value.DistributionToDonorInd,
        'InitiationFeesAndCapContriAmt': value.InitiationFeesAndCapContriAmt,
        'GrossReceiptsForPublicUseAmt': value.GrossReceiptsForPublicUseAmt,
        'MembersAndShrGrossIncomeAmt': value.MembersAndShrGrossIncomeAmt,
        'OtherSourcesGrossIncomeAmt': value.OtherSourcesGrossIncomeAmt,
        'OrgFiledInLieuOfForm1041Ind': value.OrgFiledInLieuOfForm1041Ind,
        'TaxExemptInterestAmt': value.TaxExemptInterestAmt,
        'LicensedMoreThanOneStateInd': value.LicensedMoreThanOneStateInd,
        'StateRequiredReservesAmt': value.StateRequiredReservesAmt,
        'ReservesMaintainedAmt': value.ReservesMaintainedAmt,
        'IndoorTanningServicesInd': value.IndoorTanningServicesInd,
        'Form720FiledInd': value.Form720FiledInd,
        'SubjToTaxRmnrtnExPrchtPymtInd': value.SubjToTaxRmnrtnExPrchtPymtInd,
        'SubjectToExcsTaxNetInvstIncInd': value.SubjectToExcsTaxNetInvstIncInd,
        'ExcsTaxSect4951Or4952Or4953Ind': value.ExcsTaxSect4951Or4952Or4953Ind,
        'InfoInScheduleOPartVIInd': value.InfoInScheduleOPartVIInd,
        'GoverningBodyVotingMembersCnt': value.GoverningBodyVotingMembersCnt,
        'IndependentVotingMemberCnt': value.IndependentVotingMemberCnt,
        'FamilyOrBusinessRlnInd': value.FamilyOrBusinessRlnInd,
        'DelegationOfMgmtDutiesInd': value.DelegationOfMgmtDutiesInd,
        'ChangeToOrgDocumentsInd': value.ChangeToOrgDocumentsInd,
        'MaterialDiversionOrMisuseInd': value.MaterialDiversionOrMisuseInd,
        'MembersOrStockholdersInd': value.MembersOrStockholdersInd,
        'ElectionOfBoardMembersInd': value.ElectionOfBoardMembersInd,
        'DecisionsSubjectToApprovaInd': value.DecisionsSubjectToApprovaInd,
        'MinutesOfGoverningBodyInd': value.MinutesOfGoverningBodyInd,
        'MinutesOfCommitteesInd': value.MinutesOfCommitteesInd,
        'OfficerMailingAddressInd': value.OfficerMailingAddressInd,
        'LocalChaptersInd': value.LocalChaptersInd,
        'PoliciesReferenceChaptersInd': value.PoliciesReferenceChaptersInd,
        'Form990ProvidedToGvrnBodyInd': value.Form990ProvidedToGvrnBodyInd,
        'ConflictOfInterestPolicyInd': value.ConflictOfInterestPolicyInd,
        'AnnualDisclosureCoveredPrsnInd': value.AnnualDisclosureCoveredPrsnInd,
        'RegularMonitoringEnfrcInd': value.RegularMonitoringEnfrcInd,
        'WhistleblowerPolicyInd': value.WhistleblowerPolicyInd,
        'DocumentRetentionPolicyInd': value.DocumentRetentionPolicyInd,
        'CompensationProcessCEOInd': value.CompensationProcessCEOInd,
        'CompensationProcessOtherInd': value.CompensationProcessOtherInd,
        'InvestmentInJointVentureInd': value.InvestmentInJointVentureInd,
        'WrittenPolicyOrProcedureInd': value.WrittenPolicyOrProcedureInd,
        'StatesWhereCopyOfReturnIsFldCd': value.StatesWhereCopyOfReturnIsFldCd,
        'OwnWebsiteInd': value.OwnWebsiteInd,
        'OtherWebsiteInd': value.OtherWebsiteInd,
        'UponRequestInd': value.UponRequestInd,
        'OtherInd': value.OtherInd,
        'BooksInCareOfDetail': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBooksInCareOfDetailToJSON(value.BooksInCareOfDetail),
        'InfoInScheduleOPartVIIInd': value.InfoInScheduleOPartVIIInd,
        'NoListedPersonsCompensatedInd': value.NoListedPersonsCompensatedInd,
        'Form990PartVIISectionAGrp': ((value.Form990PartVIISectionAGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAToJSON)),
        'TotalReportableCompFromOrgAmt': value.TotalReportableCompFromOrgAmt,
        'TotReportableCompRltdOrgAmt': value.TotReportableCompRltdOrgAmt,
        'TotalOtherCompensationAmt': value.TotalOtherCompensationAmt,
        'IndivRcvdGreaterThan100KCnt': value.IndivRcvdGreaterThan100KCnt,
        'FormerOfcrEmployeesListedInd': value.FormerOfcrEmployeesListedInd,
        'CompensationFromOtherSrcsInd': value.CompensationFromOtherSrcsInd,
        'ContractorCompensationGrp': ((value.ContractorCompensationGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ContractorCompensationToJSON)),
        'CntrctRcvdGreaterThan100KCnt': value.CntrctRcvdGreaterThan100KCnt,
        'InfoInScheduleOPartVIIIInd': value.InfoInScheduleOPartVIIIInd,
        'FederatedCampaignsAmt': value.FederatedCampaignsAmt,
        'MembershipDuesAmt': value.MembershipDuesAmt,
        'FundraisingAmt': value.FundraisingAmt,
        'RelatedOrganizationsAmt': value.RelatedOrganizationsAmt,
        'GovernmentGrantsAmt': value.GovernmentGrantsAmt,
        'AllOtherContributionsAmt': value.AllOtherContributionsAmt,
        'NoncashContributionsAmt': value.NoncashContributionsAmt,
        'TotalContributionsAmt': value.TotalContributionsAmt,
        'ProgramServiceRevenueGrp': ((value.ProgramServiceRevenueGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990ProgramServiceRevenueToJSON)),
        'TotalOthProgramServiceRevGrp': ((value.TotalOthProgramServiceRevGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'TotalProgramServiceRevenueAmt': value.TotalProgramServiceRevenueAmt,
        'InvestmentIncomeGrp': ((value.InvestmentIncomeGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'IncmFromInvestBondProceedsGrp': ((value.IncmFromInvestBondProceedsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'RoyaltiesRevenueGrp': ((value.RoyaltiesRevenueGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GrossRentsGrp': ((value.GrossRentsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON)),
        'LessRentalExpensesGrp': ((value.LessRentalExpensesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON)),
        'RentalIncomeOrLossGrp': ((value.RentalIncomeOrLossGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990PersonalRealAmtToJSON)),
        'NetRentalIncomeOrLossGrp': ((value.NetRentalIncomeOrLossGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GrossAmountSalesAssetsGrp': ((value.GrossAmountSalesAssetsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON)),
        'LessCostOthBasisSalesExpnssGrp': ((value.LessCostOthBasisSalesExpnssGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON)),
        'GainOrLossGrp': ((value.GainOrLossGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherSecuritiesAmtToJSON)),
        'NetGainOrLossInvestmentsGrp': ((value.NetGainOrLossInvestmentsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'ContriRptFundraisingEventAmt': value.ContriRptFundraisingEventAmt,
        'FundraisingGrossIncomeAmt': value.FundraisingGrossIncomeAmt,
        'FundraisingDirectExpensesAmt': value.FundraisingDirectExpensesAmt,
        'NetIncmFromFundraisingEvtGrp': ((value.NetIncmFromFundraisingEvtGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GamingGrossIncomeAmt': value.GamingGrossIncomeAmt,
        'GamingDirectExpensesAmt': value.GamingDirectExpensesAmt,
        'NetIncomeFromGamingGrp': ((value.NetIncomeFromGamingGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'GrossSalesOfInventoryAmt': value.GrossSalesOfInventoryAmt,
        'CostOfGoodsSoldAmt': value.CostOfGoodsSoldAmt,
        'NetIncomeOrLossGrp': ((value.NetIncomeOrLossGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'OtherRevenueMiscGrp': ((value.OtherRevenueMiscGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990OtherRevenueMiscToJSON)),
        'MiscellaneousRevenueGrp': ((value.MiscellaneousRevenueGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'OtherRevenueTotalAmt': value.OtherRevenueTotalAmt,
        'TotalRevenueGrp': ((value.TotalRevenueGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON)),
        'InfoInScheduleOPartIXInd': value.InfoInScheduleOPartIXInd,
        'GrantsToDomesticOrgsGrp': ((value.GrantsToDomesticOrgsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'GrantsToDomesticIndividualsGrp': ((value.GrantsToDomesticIndividualsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'ForeignGrantsGrp': ((value.ForeignGrantsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'BenefitsToMembersGrp': ((value.BenefitsToMembersGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'CompCurrentOfcrDirectorsGrp': ((value.CompCurrentOfcrDirectorsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'CompDisqualPersonsGrp': ((value.CompDisqualPersonsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OtherSalariesAndWagesGrp': ((value.OtherSalariesAndWagesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PensionPlanContributionsGrp': ((value.PensionPlanContributionsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OtherEmployeeBenefitsGrp': ((value.OtherEmployeeBenefitsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PayrollTaxesGrp': ((value.PayrollTaxesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesManagementGrp': ((value.FeesForServicesManagementGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesLegalGrp': ((value.FeesForServicesLegalGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesAccountingGrp': ((value.FeesForServicesAccountingGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesLobbyingGrp': ((value.FeesForServicesLobbyingGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesProfFundraising': ((value.FeesForServicesProfFundraising as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForSrvcInvstMgmntFeesGrp': ((value.FeesForSrvcInvstMgmntFeesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'FeesForServicesOtherGrp': ((value.FeesForServicesOtherGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'AdvertisingGrp': ((value.AdvertisingGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OfficeExpensesGrp': ((value.OfficeExpensesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InformationTechnologyGrp': ((value.InformationTechnologyGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'RoyaltiesGrp': ((value.RoyaltiesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OccupancyGrp': ((value.OccupancyGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'TravelGrp': ((value.TravelGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PymtTravelEntrtnmntPubOfclGrp': ((value.PymtTravelEntrtnmntPubOfclGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'ConferencesMeetingsGrp': ((value.ConferencesMeetingsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InterestGrp': ((value.InterestGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'PaymentsToAffiliatesGrp': ((value.PaymentsToAffiliatesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'DepreciationDepletionGrp': ((value.DepreciationDepletionGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InsuranceGrp': ((value.InsuranceGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'OtherExpensesGrp': ((value.OtherExpensesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'AllOtherExpensesGrp': ((value.AllOtherExpensesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'TotalFunctionalExpensesGrp': ((value.TotalFunctionalExpensesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'JointCostsInd': value.JointCostsInd,
        'TotalJointCostsGrp': ((value.TotalJointCostsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalProgramServicesAmtToJSON)),
        'InfoInScheduleOPartXInd': value.InfoInScheduleOPartXInd,
        'CashNonInterestBearingGrp': ((value.CashNonInterestBearingGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'SavingsAndTempCashInvstGrp': ((value.SavingsAndTempCashInvstGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'PledgesAndGrantsReceivableGrp': ((value.PledgesAndGrantsReceivableGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'AccountsReceivableGrp': ((value.AccountsReceivableGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'ReceivablesFromOfficersEtcGrp': ((value.ReceivablesFromOfficersEtcGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'RcvblFromDisqualifiedPrsnGrp': ((value.RcvblFromDisqualifiedPrsnGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OthNotesLoansReceivableNetGrp': ((value.OthNotesLoansReceivableNetGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InventoriesForSaleOrUseGrp': ((value.InventoriesForSaleOrUseGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'PrepaidExpensesDefrdChargesGrp': ((value.PrepaidExpensesDefrdChargesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'LandBldgEquipCostOrOtherBssAmt': value.LandBldgEquipCostOrOtherBssAmt,
        'LandBldgEquipAccumDeprecAmt': value.LandBldgEquipAccumDeprecAmt,
        'LandBldgEquipBasisNetGrp': ((value.LandBldgEquipBasisNetGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InvestmentsPubTradedSecGrp': ((value.InvestmentsPubTradedSecGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InvestmentsOtherSecuritiesGrp': ((value.InvestmentsOtherSecuritiesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InvestmentsProgramRelatedGrp': ((value.InvestmentsProgramRelatedGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'IntangibleAssetsGrp': ((value.IntangibleAssetsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OtherAssetsTotalGrp': ((value.OtherAssetsTotalGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotalAssetsGrp': ((value.TotalAssetsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'AccountsPayableAccrExpnssGrp': ((value.AccountsPayableAccrExpnssGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'GrantsPayableGrp': ((value.GrantsPayableGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'DeferredRevenueGrp': ((value.DeferredRevenueGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TaxExemptBondLiabilitiesGrp': ((value.TaxExemptBondLiabilitiesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'EscrowAccountLiabilityGrp': ((value.EscrowAccountLiabilityGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'LoansFromOfficersDirectorsGrp': ((value.LoansFromOfficersDirectorsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'MortgNotesPyblScrdInvstPropGrp': ((value.MortgNotesPyblScrdInvstPropGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'UnsecuredNotesLoansPayableGrp': ((value.UnsecuredNotesLoansPayableGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OtherLiabilitiesGrp': ((value.OtherLiabilitiesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotalLiabilitiesGrp': ((value.TotalLiabilitiesGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OrganizationFollowsFASB117Ind': value.OrganizationFollowsFASB117Ind,
        'NoDonorRestrictionNetAssetsGrp': ((value.NoDonorRestrictionNetAssetsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'DonorRestrictionNetAssetsGrp': ((value.DonorRestrictionNetAssetsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'OrgDoesNotFollowFASB117Ind': value.OrgDoesNotFollowFASB117Ind,
        'CapStkTrPrinCurrentFundsGrp': ((value.CapStkTrPrinCurrentFundsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'PdInCapSrplsLandBldgEqpFundGrp': ((value.PdInCapSrplsLandBldgEqpFundGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'RtnEarnEndowmentIncmOthFndsGrp': ((value.RtnEarnEndowmentIncmOthFndsGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotalNetAssetsFundBalanceGrp': ((value.TotalNetAssetsFundBalanceGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'TotLiabNetAssetsFundBalanceGrp': ((value.TotLiabNetAssetsFundBalanceGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBOYEOYAmtToJSON)),
        'InfoInScheduleOPartXIInd': value.InfoInScheduleOPartXIInd,
        'ReconcilationRevenueExpnssAmt': value.ReconcilationRevenueExpnssAmt,
        'NetUnrlzdGainsLossesInvstAmt': value.NetUnrlzdGainsLossesInvstAmt,
        'DonatedServicesAndUseFcltsAmt': value.DonatedServicesAndUseFcltsAmt,
        'InvestmentExpenseAmt': value.InvestmentExpenseAmt,
        'PriorPeriodAdjustmentsAmt': value.PriorPeriodAdjustmentsAmt,
        'OtherChangesInNetAssetsAmt': value.OtherChangesInNetAssetsAmt,
        'InfoInScheduleOPartXIIInd': value.InfoInScheduleOPartXIIInd,
        'MethodOfAccountingCashInd': value.MethodOfAccountingCashInd,
        'MethodOfAccountingAccrualInd': value.MethodOfAccountingAccrualInd,
        'MethodOfAccountingOtherInd': value.MethodOfAccountingOtherInd,
        'AccountantCompileOrReviewInd': value.AccountantCompileOrReviewInd,
        'AcctCompileOrReviewBasisGrp': ((value.AcctCompileOrReviewBasisGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON)),
        'FSAuditedInd': value.FSAuditedInd,
        'FSAuditedBasisGrp': ((value.FSAuditedBasisGrp as Array<any>).map(ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON)),
        'AuditCommitteeInd': value.AuditCommitteeInd,
        'FederalGrantAuditRequiredInd': value.FederalGrantAuditRequiredInd,
        'FederalGrantAuditPerformedInd': value.FederalGrantAuditPerformedInd,
    };
}

