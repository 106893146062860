/**
 * Message Syntax: https://formatjs.io/docs/core-concepts/icu-syntax
 */
// prettier-ignore
export const en: IntlMessages = {
  /**
   * Utility
   */
  'tables-presets.goto-asset': 'Go to the {asset} details page',
  'tables-presets.goto-company':  'Go to the {company} details page',
  'tables-presets.search-company-by-clientid':  'Search company by client id: {client_id}',
  'tables-presets.search-by-clientid':  'Search company by client id',
  'tables-presets.search-by-clientid-description': 'Enter one or more client IDs, separated by commas, to search for a company',
  'tables-presets.search-by-clientid-invalid-list': 'Client IDs must be a comma-separated list',
  'tables-presets.goto-user': 'Go to {email}\'s profile page',
  'tables-presets.goto-claim':  'Go to the {claim} details page',
  'tables-presets.goto-process-id':  'See more details about this scan',
  'tables-presets.open-link': 'Open {link} in another tab',
  'tables-presets.open-jira': 'Open "{ticket}" in Jira',
  /**
   * Global
   */
  'tables-presets.account-policy': 'Account / Policy',
  'tables-presets.action': 'Action',
  'tables-presets.action-icon-delete-user': 'Delete User',
  'tables-presets.action-icon-impersonate-user': 'Impersonate User',
  'tables-presets.action-icon-invite-user': 'Invite User',
  'tables-presets.action-icon-recover-user': 'Recover User',
  'tables-presets.action-icon-upgrade-account': 'Upgrade Account',
  'tables-presets.actions': 'Actions',
  'tables-presets.added': 'Added',
  'tables-presets.added-by': 'Added By',
  'tables-presets.address': 'Address',
  'tables-presets.affected-asset': 'Affected Asset',
  'tables-presets.after-scan': 'After Scan',
  'tables-presets.agency': 'Agency',
  'tables-presets.all': 'All',
  'tables-presets.amount': 'Amount',
  'tables-presets.amount-adjusted': 'Amount (Adjusted)',
  'tables-presets.answers': 'Answers',
  'tables-presets.application': 'Application',
  'tables-presets.applications': 'Applications',
  'tables-presets.approved': 'Approved',
  'tables-presets.article': 'Article',
  'tables-presets.as': 'AS',
  'tables-presets.as-class': 'AS Class',
  'tables-presets.as-name': 'AS Name',
  'tables-presets.asn': 'ASN',
  'tables-presets.asset': 'Asset',
  'tables-presets.asset-type': 'Asset Type',
  'tables-presets.assets': 'Assets',
  'tables-presets.before-scan': 'Before Scan',
  'tables-presets.blocklists': 'Blocklists',
  'tables-presets.both': 'Both',
  'tables-presets.broker': 'Broker',
  'tables-presets.cancelled': 'Cancelled',
  'tables-presets.carrier': 'Carrier',
  'tables-presets.case': 'Case',
  'tables-presets.case-category': 'Case Category',
  'tables-presets.case-cause': 'Case / Cause',
  'tables-presets.case-details': 'Case Details',
  'tables-presets.case-id': 'Case ID',
  'tables-presets.categories': 'Categories',
  'tables-presets.category': 'Category',
  'tables-presets.category-expansion': 'Category Expansion',
  'tables-presets.cause': 'Cause',
  'tables-presets.city': 'City',
  'tables-presets.claim': 'Claim',
  'tables-presets.claim-id': 'Claim ID',
  'tables-presets.claim-type': 'Claim Type',
  'tables-presets.client-id': 'Client ID',
  'tables-presets.closed': 'Closed',
  'tables-presets.company': 'Company',
  'tables-presets.company-name': 'Company Name',
  'tables-presets.company-revenue': 'Company Revenue',
  'tables-presets.company-stats': 'Company Stats',
  'tables-presets.contact-type': 'Contact Type',
  'tables-presets.country': 'Country',
  'tables-presets.court': 'Court',
  'tables-presets.created': 'First Found',
  'tables-presets.created-at': 'Created At',
  'tables-presets.created-on': 'Created On',
  'tables-presets.critical': 'Critical',
  'tables-presets.criticality': 'Criticality',
  'tables-presets.cve': 'CVE',
  'tables-presets.cve-data': 'CVE Data',
  'tables-presets.cves': 'CVEs',
  'tables-presets.data': 'Data',
  'tables-presets.date': 'Date',
  'tables-presets.date-added': 'Date Added',
  'tables-presets.date-approved': 'Approved Date',
  'tables-presets.date-cached': 'Date Cached',
  'tables-presets.date-created-from': 'Created From',
  'tables-presets.date-created-to': 'Created To',
  'tables-presets.date-event': 'Event Date',
  'tables-presets.date-event-tooltip': 'The date logged after gathering results at the end of a scan event.',
  'tables-presets.date-exposure': 'Year of Exposure',
  'tables-presets.date-filed': 'Date Filed',
  'tables-presets.date-reported': 'Date Reported',
  'tables-presets.date-reported-from': 'Reported From',
  'tables-presets.date-reported-to': 'Reported To',
  'tables-presets.decline': 'Decline',
  'tables-presets.department': 'Department',
  'tables-presets.description': 'Description',
  'tables-presets.details': 'Details',
  'tables-presets.dns-records': 'DNS Records',
  'tables-presets.docket': 'Docket',
  'tables-presets.document-number': 'Document Number',
  'tables-presets.domain': 'Domain',
  'tables-presets.domains': 'Domains',
  'tables-presets.email': 'Email',
  'tables-presets.email-template': 'Email Template',
  'tables-presets.endorsement': 'Endorsement',
  'tables-presets.evidences': 'Evidence',
  'tables-presets.expired-at': 'Expired At',
  'tables-presets.expires-at': 'Expires At',
  'tables-presets.exposed-data': 'Exposed Data',
  'tables-presets.false_positive_removed': 'False Positive Removed',
  'tables-presets.file': 'File',
  'tables-presets.filing-location': 'Filing Location',
  'tables-presets.filing-location-owner': 'Filing Location / Owner',
  'tables-presets.filter': 'Filter',
  'tables-presets.form-status': 'Form Status',
  'tables-presets.form-type': 'Form Type',
  'tables-presets.forms': 'Forms',
  'tables-presets.forms-#': '# Forms',
  'tables-presets.found': 'Last Detected',
  'tables-presets.free': 'Free',
  'tables-presets.from': 'From',
  'tables-presets.full-documentation': 'Read the full documentation',
  'tables-presets.fullscan': 'Full Scan',
  'tables-presets.funding-amount': 'Funding Amount',
  'tables-presets.funding-date': 'Funding Date',
  'tables-presets.general': 'General',
  'tables-presets.generated-at': 'Generated At',
  'tables-presets.geo': 'Geo',
  'tables-presets.high': 'High',
  'tables-presets.host': 'Host',
  'tables-presets.hosting': 'Hosting',
  'tables-presets.http-screenshot': 'HTTP Screenshot',
  'tables-presets.industry': 'Industry',
  'tables-presets.info-hash': 'Info Hash',
  'tables-presets.insurance-line': 'Insurance Line',
  'tables-presets.internal-email': 'Internal Email',
  'tables-presets.investor': 'Investor',
  'tables-presets.invitation-date': 'Invitation Date',
  'tables-presets.invited-by': 'Invited By',
  'tables-presets.ip-address': 'IP Address',
  'tables-presets.ip-addresses': 'IP Addresses',
  'tables-presets.isp': 'ISP',
  'tables-presets.issuer-country': 'Issuer Country',
  'tables-presets.issuer-name': 'Issuer Name',
  'tables-presets.issuer-organization': 'Issuer Organization',
  'tables-presets.jira': 'Jira Issue',
  'tables-presets.job-title': 'Job Title',
  'tables-presets.jobs': 'Jobs',
  'tables-presets.keyword-matches': 'Keyword Matches',
  'tables-presets.keywords': 'Keywords',
  'tables-presets.kind': 'Kind',
  'tables-presets.last-activity': 'Last Activity',
  'tables-presets.latest-form-status': 'Latest Form Status',
  'tables-presets.latitude': 'Latitude',
  'tables-presets.lawsuite-resolution': 'Lawsuite Resolution',
  'tables-presets.leaked-pii': 'Leaked PII',
  'tables-presets.legal-type': 'Legal Type',
  'tables-presets.lender': 'Lender',
  'tables-presets.lien-amount': 'Lien Amount',
  'tables-presets.litigation-case-title': 'Litigation Case Title',
  'tables-presets.loan': 'Loan',
  'tables-presets.log-details': 'Log Details',
  'tables-presets.logs': 'Logs',
  'tables-presets.logs-#': '# Logs',
  'tables-presets.longitude': 'Longitude',
  'tables-presets.lookalike': 'Lookalike',
  'tables-presets.lookalikes': 'Lookalikes',
  'tables-presets.low': 'Low',
  'tables-presets.manage-requests': 'Manage Requests',
  'tables-presets.match-num': 'Match {num}',
  'tables-presets.matches': 'Matches',
  'tables-presets.medium': 'Medium',
  'tables-presets.module': 'Module',
  'tables-presets.module-payload': 'Module Payload',
  'tables-presets.monit-type': 'Monitoring',
  'tables-presets.monit-type-active': 'Extended',
  'tables-presets.monit-type-passive': 'Lite',
  'tables-presets.mute': 'Mute',
  'tables-presets.naics': 'NAICS',
  'tables-presets.naics-tr': 'NAICS TR',
  'tables-presets.name': 'Name',
  'tables-presets.nature-suit': 'Nature of Suit',
  'tables-presets.negative': 'Negative',
  'tables-presets.neutral': 'Neutral',
  'tables-presets.no-blocklists': 'No blocklists were found.',
  'tables-presets.no-cves': 'No CVEs listed',
  'tables-presets.no-dns-records': 'No DNS records were found.',
  'tables-presets.no-policies': 'No policies were found.',
  'tables-presets.no-response': 'No response',
  'tables-presets.not-after': 'Not After',
  'tables-presets.not-available': 'Not Available',
  'tables-presets.not-before': 'Not Before',
  'tables-presets.npa-dpa': 'NPA DPA',
  'tables-presets.number-employees': 'Number of Employees',
  'tables-presets.offense': 'Offense',
  'tables-presets.offense-group': 'Offense Group',
  'tables-presets.open': 'Open',
  'tables-presets.open-document': 'Open the document',
  'tables-presets.open-file': 'Open the report',
  'tables-presets.open-file-error': 'No report available',
  'tables-presets.orig-id': 'ORIG ID',
  'tables-presets.origin': 'Origin',
  'tables-presets.overall-sentiment': 'Overall Sentiment',
  'tables-presets.owner': 'Owner',
  'tables-presets.pacer-link': 'Pacer Link',
  'tables-presets.paid': 'Paid',
  'tables-presets.passwords': 'Passwords',
  'tables-presets.path': 'Path',
  'tables-presets.pdf-file': 'PDF File',
  'tables-presets.penalty': 'Penalty',
  'tables-presets.pending': 'Pending',
  'tables-presets.pii': 'PII',
  'tables-presets.pii-records': 'PII,PHI,or PCI Records',
  'tables-presets.plan': 'Plan',
  'tables-presets.policies': 'Policies',
  'tables-presets.policy-metrics': 'Policy Metrics',
  'tables-presets.policyholder': 'Policyholder',
  'tables-presets.policyholder-email': 'Policyholder Email',
  'tables-presets.port': 'Port',
  'tables-presets.positive': 'Positive',
  'tables-presets.pre-claim': 'Pre-Claim',
  'tables-presets.primary-offense': 'Primary Offense',
  'tables-presets.process-id': 'PID',
  'tables-presets.quote': 'Quote',
  'tables-presets.rdns': 'RDNS',
  'tables-presets.rdns-parent': 'RDNS Parent',
  'tables-presets.recipient': 'Recipient',
  'tables-presets.recommendations': 'Recommendations',
  'tables-presets.records': 'Records',
  'tables-presets.references': 'References',
  'tables-presets.rejected': 'Rejected',
  'tables-presets.remove': 'Remove',
  'tables-presets.report': 'Report',
  'tables-presets.reported': 'Reported',
  'tables-presets.reserved': 'Reserved',
  'tables-presets.reserved-paid': 'Reserved / Paid',
  'tables-presets.resolve': 'Resolve',
  'tables-presets.retrigger-verification': 'Re-trigger Verification',
  'tables-presets.review': 'Review',
  'tables-presets.reviewed-by': 'Reviewed by',
  'tables-presets.revoke': 'Revoke',
  'tables-presets.risk': 'Risk',
  'tables-presets.risk-after': 'Risk After',
  'tables-presets.risk-before': 'Risk Before',
  'tables-presets.risk-score': 'Risk Score',
  'tables-presets.role': 'Role',
  'tables-presets.role-tier': 'Tier / Role',
  'tables-presets.round': 'Round',
  'tables-presets.scanned': 'Scanned On',
  'tables-presets.search': 'Search',
  'tables-presets.search-docs': 'Search for documents',
  'tables-presets.search-vendors': 'Search for vendors',
  'tables-presets.secondary-offense': 'Secondary Offense',
  'tables-presets.secondary-review': 'Secondary Review',
  'tables-presets.self-resolved': 'Self Resolved',
  'tables-presets.sent-at': 'Sent At',
  'tables-presets.sentiment': 'Sentiment',
  'tables-presets.services': 'Services',
  'tables-presets.shared-asset': 'Shared',
  'tables-presets.size': 'Size',
  'tables-presets.snippet': 'Snippet',
  'tables-presets.source': 'Source',
  'tables-presets.ssl-certificate': 'SSL Certificate',
  'tables-presets.state': 'State',
  'tables-presets.status': 'Status',
  'tables-presets.sub-penalty': 'Sub Penalty',
  'tables-presets.subject-country': 'Subject Country',
  'tables-presets.subject-email': 'Subject Email',
  'tables-presets.subject-name': 'Subject Name',
  'tables-presets.subject-organization': 'Subject Organization',
  'tables-presets.summary': 'Summary',
  'tables-presets.tags': 'Tags',
  'tables-presets.third-party': 'Third Party',
  'tables-presets.third-party-databreaches': 'Third Party Data Breaches',
  'tables-presets.tier': 'Tier',
  'tables-presets.timezone': 'Timezone',
  'tables-presets.to': 'To',
  'tables-presets.total-leaks': 'Total Leaks',
  'tables-presets.trial': 'Trial',
  'tables-presets.trial-only': 'Trials Only',
  'tables-presets.triggers': 'Triggers',
  'tables-presets.type': 'Type',
  'tables-presets.type-status': 'Type / Status',
  'tables-presets.updated': 'Last Updated',
  'tables-presets.user': 'User',
  'tables-presets.user-account': 'User Account',
  'tables-presets.user-deleted': 'Deleted',
  'tables-presets.user-deleted-status': 'Deleted On',
  'tables-presets.user-id': 'User ID',
  'tables-presets.user-type': 'User Type',
  'tables-presets.verification-code': 'Verification Code',
  'tables-presets.verification-method': 'Verification Method',
  'tables-presets.verified': 'Verified',
  'tables-presets.verify': 'Verify',
  'tables-presets.version': 'Version',
  'tables-presets.vulnerabilities': 'Vulnerabilities',
  'tables-presets.vulnerability': 'Vulnerability',
  'tables-presets.vulnerability-scan': 'Vulnerability Scan',
  'tables-presets.vulnerability_resolved': 'Vulnerability Resolved',
  'tables-presets.vulnerable-assets': 'Vulnerable Assets',
  'tables-presets.vulnerable-assets-count': 'Vulnerable Assets: {assets}',
  'tables-presets.zip-code': 'Zip Code',
  /**
   * Table Header Tooltips
   */
  'tables-presets.mute-tooltip': 'Muting is only allowed for Critical vulnerabilities.',
  'tables-presets.resolve-tooltip': 'Resolve allows you to submit a request for our team to review the vulnerability for removal. If your request is approved, the vulnerability will be removed from your list of Vulnerabilities and your organization\'s Risk Score will be updated automatically.',
  /**
   * Cells
   */
  'tables-presets.cell-pending-review': 'pending review',
  'tables-presets.cell-remove-assets-pending': 'pending removal',
  'tables-presets.cell-remove-assets': 'remove',
  'tables-presets.cell-remove-assets-tooltip': 'Report a false positive asset.',
  'tables-presets.cell-mute-notifications': 'Mute',
  'tables-presets.cell-muted-notifications': 'Muted',
  'tables-presets.cell-muted-notifications-status-muted': 'This vulnerability has been muted. No additional security alert emails will be sent to members of your organization for this vulnerability.',
  'tables-presets.cell-muted-notifications-status-pending': 'The mute request for this vulnerability is pending approval.',
  'tables-presets.cell-mute-notifications-tooltip': 'Request a mute of notifications',
  'tables-presets.cell-mute-notifications-pending': 'pending mute',
  /**
   * Assets Domains
   */
  'tables-presets.assetsdomains-advsearch-title': 'Search for domains',
  'tables-presets.assetsdomains-summary-title': 'Domain Summary',
  'tables-presets.assetsdomains-no-ip-addresses': 'No IP addresses were found.',
  /**
   * Assets IP Addresses
   */
  'tables-presets.assetsipaddresses-advsearch-title': 'Search for IP Addresses',
  'tables-presets.assetsipaddresses-no-domains': 'No domains were found.',
  'tables-presets.assetsipaddresses-summary-title': 'IP Addresses Summary',
  'tables-presets.assetsipaddresses-pending-scan': 'Number of IP addresses pending scan',
  'tables-presets.assetsipaddresses-pending-scan-info': 'IP addresses, which were added manually, are only displayed after they have been scanned at least once.',
  /**
   * Asset Map
   */
  'tables-presets.asset-map-search-placeholder': 'Search for location',
  'tables-presets.asset-map-location-label': 'Location',
  'tables-presets.asset-map-location-value-unknown': 'Unknown location',
  'tables-presets.asset-map-location-value-multiple': 'Multiple locations',
  'tables-presets.asset-map-domains-label': 'Domain(s)',
  'tables-presets.asset-map-url-asset-domains-value': '{remaining, plural, =0 {{domainToShow}} one {{domainToShow} & 1 other} other {{domainToShow} & # others}}',
  'tables-presets.asset-map-domains-value-none': 'No domains found',
  'tables-presets.asset-map-ip-addresses-value': '{remaining, plural, =0 {{firstIpAddress}} one {{firstIpAddress} & 1 other} other {{firstIpAddress} & # others}}',
  'tables-presets.asset-map-security-findings-label': 'Security Findings',
  'tables-presets.asset-map-security-findings-tooltip': 'At least 1 {securityLevel} finding',
  'tables-presets.asset-map-url-asset-details': 'View Details',
  'tables-presets.asset-map-description': 'This asset distribution map shows the locations of the assets by IP addresses. Severity level information will be omitted for organizations with 5000+ IP addresses.',
  'tables-presets.asset-map-toggle-button-map-title': 'Map',
  'tables-presets.asset-map-toggle-button-table-title': 'Table',

  /**
   * Assets Excluded
   */
  'tables-presets.excluded-reason': 'Reason for removal',
  'tables-presets.excluded-date-removed': 'Date removed',
  /**
   * Findings
   */
  'tables-presets.findings-advsearch-title': 'Search for vulnerabilities',
  'tables-presets.findings-summary-title': 'Finding Summary',
  /**
   * Findings Aggregate
   */
  'tables-presets.findings-aggregate-vulnerability': 'Vulnerability',
  'tables-presets.findings-aggregate-summary-evidences': 'To see the evidence for each vulnerable asset, navigate to the Assets tab and select an asset from the list.',
  'tables-presets.findings-aggregate-summary-title': 'Vulnerability Group',
  'tables-presets.findings-aggregate-rescan-modal-title': "Ready to Rescan?",
  'tables-presets.findings-aggregate-resolve-modal-title': "Request to resolve this vulnerability?",
  'tables-presets.findings-aggregate-rescan-modal-body': "Select or enter your reason below and click 'Rescan' to attempt to clear this vulnerability. We'll notify you when the scan is complete.",
  'tables-presets.findings-aggregate-rescan': 'Rescan',
  'tables-presets.findings-aggregate-no-status': 'no status',
  'tables-presets.findings-aggregate-scanning': 'Scanning...',
  'tables-presets.findings-aggregate-scanning-detail': 'Rescan in progress! \nMost of our rescans finish within 24 hours.',
  'tables-presets.findings-aggregate-rescan-reason': 'Reason',
  'tables-presets.findings-aggregate-resolution-detail': 'The request to resolve this vulnerability is pending approval.',
  'tables-presets.findings-aggregate-custom-reason': 'Additional Details',
  'tables-presets.findings-aggregate-rescan-reason-port_closed': 'Closed the port',
  'tables-presets.findings-aggregate-rescan-reason-removed': 'Removed the application / service',
  'tables-presets.findings-aggregate-rescan-reason-patched': 'Patched to latest version',
  'tables-presets.findings-aggregate-rescan-reason-added_firewall': 'Put asset behind firewall',
  'tables-presets.findings-aggregate-rescan-reason-limited_ip_access': 'Limited access to specific IPs',
  'tables-presets.findings-aggregate-rescan-reason-other': 'Other',
  'tables-presets.findings-aggregate-rescan-reason-error': 'A rescan reason is required',
  'tables-presets.findings-aggregate-resolve-reason-error': 'A reason is required',
  'tables-presets.findings-aggregate-resolve-disabled': '<b>Resolve</b> is only available for Critical and High vulnerabilities. Please use <b>Rescan</b> to clear out this vulnerability.',
  'tables-presets.findings-aggregate-custom-reason-error': 'Additional explanation is required',
  'tables-presets.findings-aggregate-actions-tooltip-explorer': 'Rescan allows you to scan against a specific vulnerability to clear it from your organization.\n\nMute allows you to stop further email notifications from being sent out to members of your organization for a specific vulnerability. This only applies to Critical vulnerabilities.\n\nResolve submits a request to Coalition to manually review a specific vulnerability to be removed from your organization.',
  'tables-presets.findings-aggregate-actions-tooltip-control': 'Rescan allows you to scan against a specific vulnerability to clear it from your organization.\n\nMute allows you to stop further email notifications from being sent out to members of your organization for a specific vulnerability. This only applies to Critical vulnerabilities.\n\nResolve submits a request to Coalition to manually review a specific vulnerability to be removed from your organization. This feature is only available for Critical and High vulnerabilities at this time.',

  /**
   * Data Breaches
   */
  'tables-presets.databreaches-advsearch-title': 'Search for a data breach',
  'tables-presets.databreaches-summary-title': 'Data Breach Summary',
  /**
   * Event Log
   */
  'tables-presets.eventlog-advsearch-title': 'Search for events',
  /**
   * Lookalikes
   */
  'tables-presets.lookalikes-advsearch-title': 'Search for domains',
  'tables-presets.lookalikes-summary-title': 'Lookalike Domain Summary',
  /**
   * Honeypots
   */
  'tables-presets.honeypots-advsearch-title': 'Search for honeypots',
  /**
   * Torrents
   */
  'tables-presets.torrents-advsearch-title': 'Search for torrents',
  /**
   * Keywords
   */
  'tables-presets.keywords-advsearch-title': 'Search for keyword matches',
  'tables-presets.keywords-summary-title': 'Keyword Match',
  /**
   * Pastebin
   */
  'tables-presets.pastebin-advsearch-title': 'Search for keyword matches',
  'tables-presets.pastebin-summary-title': 'Keyword Match',
  'tables-presets.pastebin-open-file': 'Open Pastebin file',
  /**
   * Scans
   */
  'tables-presets.scans-advsearch-title': 'Search for scans',
  'tables-presets.scans-summary-title': 'Scan Summary',
  /**
   * Technologies
   */
  'tables-presets.technologies-advsearch-title': 'Search for technologies',
  'tables-presets.technologies-summary-title': 'Technology Summary',
  /**
   * DNS
   */
  'tables-presets.dns-advsearch-title': 'Search for a record',
  'tables-presets.dns-summary-title': 'DNS Record',
  /**
   * Companies
   */
  'tables-presets.companies-advsearch-title': 'Search for a company',
  'tables-presets.companies-summary-title': 'Company Summary',
  'tables-presets.additional-domains': 'Additional Domains',
  /**
   * Notifications
   */
  'tables-presets.notifications-advsearch-title': 'Search for a notification',
  'tables-presets.notifications-summary-title': 'Notification Summary',
  'tables-presets.email-template-disclaimer': 'The following notification example is for demonstrative purposes only. The actual email content sent to the user may differ.',
  'tables-presets.search-by-email': 'Search by email',
  'tables-presets.search-by-company': 'Search by company name',
  /**
   * Claims
   */
  'tables-presets.claims-advsearch-title': 'Search for a claim',
  /**
   * Claims Accounts
   */
  'tables-presets.claimsaccounts-advsearch-title': 'Search for an account',
  /**
   * Security Review
   */
  'tables-presets.secreview-advsearch-title': 'Search for a scan',
  'tables-presets.secreview-summary-title': 'Scan Summary',
  'tables-presets.secreview-no-domains': 'No additional domains were defined.',
  'tables-presets.secreview-no-ip-addresses': 'No additional IP addresses were defined.',
  'tables-presets.secreview-request-advsearch-title': 'Search for a security review request',
  /**
   * Risk Assessment
   */
  'tables-presets.cra-advsearch-title': 'Search for a scan',
  'tables-presets.cra-summary-title': 'Scan Summary',
  'tables-presets.cra-no-domains': 'No additional domains were defined.',
  'tables-presets.cra-no-ip-addresses': 'No additional IP addresses were defined.',
  /**
   * Loans
   */
  'tables-presets.loans-summary-title': 'Loan Summary',
  /**
   * Liens
   */
  'tables-presets.liens-summary-title': 'Lien Summary',
  /**
   * Litigation
   */
  'tables-presets.litigation-summary-title': 'Litigation Summary',
  /**
   * News
   */
  'tables-presets.news-summary-title': 'Article Summary',
  /**
   * Glassdoor
   */
  'tables-presets.glassdoor-summary-title': 'Glassdoor Review',
  /**
   * RegulatoryIssues
   */
  'tables-presets.regulatoryissues-summary-title': 'Regulatory Issue',
  'tables-presets.regulatoryissues-advsearch-title': 'Search for a regulatory issue',
  /**
   * Requests
   */
  'tables-presets.secreview-requests-summary-title': 'Request Summary',
  'tables-presets.secreview-requests-reason': 'Reason for reporting',
  'tables-presets.secreview-attestations': 'Attestations',
  'tables-presets.secreview-requests-reason-internal': 'Resolution notes',
  'tables-presets.secreview-requests-to-block': 'Request to block',
  /**
   * Admin Users
   */
  'tables-presets.adminusers-summary-title': 'User Summary',
  'tables-presets.adminusers-advsearch-title': 'Search for a user',
  'tables-presets.adminusers-delete-user-title': 'Delete User',
  'tables-presets.adminusers-update-user-role-title': 'Update Role',
  'tables-presets.adminusers-update-user-role-description': 'Update the role of this user',
  'tables-presets.adminusers-explain-delete-user': 'Once deleted, the user with this email address will be unable to access Control. Note that deleted users can be restored if needed.',
  'tables-presets.adminusers-explain-reset-authenticators': "Once reset, the user will be able to login with just their email and password.",
  'tables-presets.adminusers-reset-authenticators-attestation' : "I attest that I have followed the verification process to confirm the identity of the requester",
  'tables-presets.adminusers-reset-authenticators-title': "Reset MFA",
  'tables-presets.authenticators': 'Authenticators',
  'tables-presets.authenticators-created-label': "Created",
  'tables-presets.authenticators-last-auth-label': "Last Authenticated",
  'tables-presets.no-authenticators': "No authenticators were found.",
  'tables-presets.user-info-watchlist-section-title': 'Watchlist Licenses',
  'tables-presets.user-info-extended-monitoring-label': 'Extended Monitoring',
  'tables-presets.user-info-lite-monitoring-label': 'Lite Monitoring',
  /**
   * Admin Companies
   */
  'tables-presets.admincompanies-summary-title': 'Company Summary',
  'tables-presets.admincompanies-advsearch-title': 'Search for a company',
  /**
   * Control Users
   */
  'tables-presets.controlusers-advsearch-title': 'Search for a user',
  'tables-presets.controlusers-removeuser-title': 'Remove this user?',
  /**
   * Control Invites
   */
  'tables-presets.controlinvites-advsearch-title': 'Search for an invite',
  'tables-presets.controlinvites-revokeinvite-title': 'Revoke this invitation?',
  /**
   * Control Manage Access Requests
   */
  'tables-presets.controlmanagerequests-grant-access-title': 'Grant Access',
  'tables-presets.controlmanagerequests-grant-access-description': `Please confirm that you’re approving this request to add
  <b>{requested_entity_name}</b> as a {requested_entity_type} for
  <b>{requestor_entity_name}</b>`,
  'tables-presets.controlmanagerequests-deny-access-title': 'Deny Access',
  'tables-presets.controlmanagerequests-deny-access-description': `Please confirm that you’re rejecting this request to add <b>{requested_entity_name}</b> as a {requested_entity_type} for <b>{requestor_entity_name}</b>`,
  'tables-presets.controlmanagerequests-requestor': 'Requester',
  'tables-presets.controlmanagerequests-requested': 'Access Requested For',
  'tables-presets.controlmanagerequests-requested-as': 'Add As',
  'tables-presets.controlmanagerequests-created-at': 'Requested On',
  'tables-presets.controlmanagerequests-actions': 'Actions',
  'tables-presets.controlmanagerequests-advSearch': 'Search for a request',
  'tables-presets.controlmanagerequests-reviewed-by': 'Reviewed by',
  'tables-presets.controlmanagerequests-status': 'Status',
  'tables-presets.controlmanagerequests-policyholder-value': 'Policyholder',
  'tables-presets.controlmanagerequests-customer-value': 'Customer',
  'tables-presets.controlmanagerequests-advsearch-title': 'Search via client ID', 

  /**
   * Executive Risks
   */
  'tables-presets.execrisks-advsearch-title': 'Search for a scan',
  /**
   * Supplemental Forms (RSA)
   */
  'tables-presets.rsa-title': 'RSA Supplemental Form',
  'tables-presets.rsa-section-A': 'A. Company Name',
  'tables-presets.rsa-section-B': 'B. Email Security',
  'tables-presets.rsa-section-C': 'C. Network Security',
  'tables-presets.rsa-section-D': 'D. Business Continuity',
  'tables-presets.rsa-section-E': 'E. Network Administration',
  'tables-presets.rsa-section-signature': 'Signature',
  'tables-presets.rsa-no-form-data': 'No form data available',
  'tables-presets.modal-action': `Would you like to auto-populate the form with the previous submission's answers?`,
  'tables-presets.button-manual': 'Manually fill',
  'tables-presets.button-reenable': 'Re-enable editing',
  'tables-presets.button-pdf-preview': 'Download Submitted PDF',
  'tables-presets.button-response-preview': 'View Responses',
  'tables-presets.button-pdf-preview-tooltip': 'Download copy of the responses as a PDF',
  'tables-presets.request-successful': 'Your form has been generated!\n\nPlease choose a method to access it:',
  'tables-presets.open-new-tab': 'Open Form in New Tab',
  'tables-presets.copy-link': 'Copy to Clipboard',
  /**
   * Supplemental Forms (RSA) - Section A
   */
  'tables-presets.rsa-company_name': 'Company Name',
  /**
   * Supplemental Forms (RSA) - Section B
   */
  'tables-presets.rsa-filter_or_scan_incoming_emails': 'Do you filter or scan incoming emails for malicious attachments and malicious links?',
  'tables-presets.rsa-filter_or_scan_incoming_emails_tools': 'If so, what tools or services do you use for this?',
  'tables-presets.rsa-require_2fa_for_email': 'Do you enable and require multi factor authentication for access to email? (more information available here)',
  'tables-presets.rsa-use_microsoft_365': 'Do you use Microsoft Office 365?',
  'tables-presets.rsa-use_microsoft_sentinel': 'Microsoft Sentinel (free or paid tier)?',
  'tables-presets.rsa-use_microsoft_sentinel_atp': 'Advanced Threat Protection (ATP) add-on?',
  'tables-presets.rsa-use_other_email_products': 'Other email security products?',
  'tables-presets.rsa-use_other_email_products_description': 'If so, what product(s):',
  'tables-presets.rsa-use_self_hosted_microsoft_exchange_servers': 'Do you use self-hosted Microsoft Exchange servers?',
  'tables-presets.rsa-disabled_on_premise_exchange_web_services': 'If yes, have you disabled on premises Exchange Web Services?',
  'tables-presets.rsa-other_email_security_controls_description': 'What other email security controls do you have in place to mitigate risk (Anti-Malware, Anti-Phishing, other)? Provide details and context.',
  /**
   * Supplemental Forms (RSA) - Section C
   */
  'tables-presets.rsa-use_edr_solution': 'Do you use an Endpoint Detection and Response solution (e.g. Carbon Black Cloud, Cisco AMP, Crowdstrike Falcon, Cylance, Endgame Endpoint Protection, Symantec EDR, etc.)',
  'tables-presets.rsa-use_edr_solution_description': 'If so, which EDR tool(s) do you use?',
  'tables-presets.rsa-estimated_edr_coverage': 'What is the estimated percentage of endpoints covered with EDR?',
  'tables-presets.rsa-edr_coverage_all_domain_controllers': 'Does it include all domain controllers?',
  'tables-presets.rsa-mfa_enabled_and_required_for_all_remote_access': 'Is multi-factor access enabled and required for all remote access (VPN, etc)?',
  'tables-presets.rsa-secured_baseline_with_regular_professional_review': 'Do you have a secure/hardened baseline configuration which is regularly reviewed and updated by an information security professional?',
  'tables-presets.rsa-baseline_conf_materially_rolled_out': 'If “yes” to the above, is this baseline configuration materially rolled out across servers, laptops, desktops and managed mobile devices?',
  'tables-presets.rsa-endpoint_controls_security_patches_description': 'What processes or controls do you have in place to ensure that all endpoints in your network are updated with critical security patches?',
  'tables-presets.rsa-endpoint_controls_security_patches_software_description': 'What software is used to perform this function?',
  'tables-presets.rsa-inbound_and_outbound_firewall_configurations_with_log_retention': 'Do you have inbound and outbound firewall configurations with log retention?',
  'tables-presets.rsa-inbound_and_outbound_firewall_log_retention_time': 'If yes, for how long are these firewall logs retained?',
  'tables-presets.rsa-on_premises_servers_exposed_to_internet_description': 'Please describe any on premises servers that are exposed to the internet?',
  'tables-presets.rsa-list_ip_addresses_on_premises_servers_or_other_it_infrastructure_are_hosted': 'Please list the IP addresses on which any on-premises servers or other IT infrastructure are hosted:',
  'tables-presets.rsa-network_segmentation_geo_locations': 'Does your network have segmentation between. Geographic locations?',
  'tables-presets.rsa-network_segmentation_business_units': 'Does your network have segmentation between. Business units?',
  'tables-presets.rsa-network_segmentation_db_for_pii_phi_pci': 'Does your network have segmentation between. Databases for PII/PHI/PCI?',
  'tables-presets.rsa-network_segmentation_eol_software': 'Does your network have segmentation between. End of life/unsupported software and rest of network?',
  /**
   * Supplemental Forms (RSA) - Section D
   */
  'tables-presets.rsa-weekly_backups_of_sensitive_data_and_critical_business_systems': 'Do you maintain at least weekly backups of sensitive data and critical business systems?',
  'tables-presets.rsa-weekly_backups_disconnected_from_primary_network': 'If yes, are they disconnected and inaccessible from your primary network?',
  'tables-presets.rsa-test_successful_restoration_from_backups': 'Do you test the successful restoration and recovery of key server configurations and data from backups?',
  'tables-presets.rsa-test_successful_restoration_from_backups_description': 'If yes, how frequently do you perform such tests?',
  'tables-presets.rsa-business_continuity_disaster_recovery_plan': 'Do you have a business continuity/disaster recovery plan?',
  'tables-presets.rsa-business_continuity_disaster_recovery_plan_testing_frequency': 'How frequently is it tested?',
  'tables-presets.rsa-business_continuity_disaster_proven_recovery_time': 'Based on testing, what is your proven recovery time objective for critical systems to restore operations after a cyber attack or other unplanned outage?',
  'tables-presets.rsa-backups_only_accessed_via_authentication_mechanism_outside_of_ad': 'Can backups only be accessed via an authentication mechanism outside of Active Directory?',
  /**
   * Supplemental Forms (RSA) - Section E
   */
  'tables-presets.rsa-what_safeguards_are_in_place_around_it_network_administration': 'How do you control domain administrator access, what safeguards are in place around IT network administration?',
  'tables-presets.rsa-end_users_prevented_from_having_administrative': 'Are end users prevented from having administrative access on their endpoints?',
  'tables-presets.rsa-controls_are_in_place_to_prevent_privilege_escalation_description': 'What controls are in place to prevent privilege escalation?',
  'tables-presets.rsa-endpoint_management_software_exposed_to_the_internet': 'Do you have any endpoint management software exposed to the internet (Kaseya, etc)?',
  'tables-presets.rsa-endpoint_management_software_security_controls_description': 'If so, what security controls do you have around that?',
  'tables-presets.rsa-describe_your_it_support_organization': 'Briefly describe your IT support organization and identify any Managed Service Providers (MSP’s) or Managed Security Service Providers (MSSP’s) you use (If outsourced IT vendors are used, describe the vendor types, functions performed, and yearly cost approximations. If IT is staffed in-house describe the organization structure, functions performed, and FTE headcount.)',
  /**
   * Supplemental Forms (RSA) - Signature
   */
  'tables-presets.rsa-signed_by': 'Signed By (Full Name)',
  'tables-presets.rsa-signed_date': 'Signed Date',
  /**
   * Supplemental Forms (RSA2024) - Title
  */
  'tables-presets.supplemental-ransomware-title': 'Ransomware Supplemental Application',
  'tables-presets.supplemental-ransomware-description': 'This form assesses the user\'s preparedness for recovering from a ransomware attack.',
  'tables-presets.supplemental-no-response': '(No response)',
    /**
   * Vulnerability Resolutions
   */
  'resolution-request.details.summary-evidences': 'To see the evidence for each vulnerable asset, navigate to the <a>Assets tab</a> and select an asset from the list.',
      /**
   * Vulnerability Resolutions
   */
  'tables-presets.zero-day-alert.search-alert': 'Search by Alert name',
  'tables-presets.zero-day-alert.summary-title': 'View Alert Detail',
  'tables-presets.zero-day-alert.alert-name': 'Alert name',
  'tables-presets.zero-day-alert.total-exposure': 'Total exposure',
  'tables-presets.zero-day-alert.impacted-companies': 'Impacted companies',
  'tables-presets.zero-day-alert.created-date': 'Created Date',
  'tables-presets.zero-day-alert.companies': 'Companies',
  'tables-presets.zero-day-alert.status': 'Status',
  'tables-presets.zero-day-alert.policies-limit': 'Policies Limit',
  'tables-presets.zero-day-alert.emailed-on': 'Emailed on',
  'tables-presets.zero-day-alert.date-added': 'Date added',
  'tables-presets.zero-day-alert.sent-on': 'Date sent',
  'tables-presets.zero-day-alert.customers': 'Customers',
  'tables-presets.zero-day-alert.email-subject': 'Email object',
};
