/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
     */
    SeparateBasisFinclStmtInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
     */
    ConsolidatedBasisFinclStmtInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis
     */
    ConsolAndSepBasisFinclStmtInd: string;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "SeparateBasisFinclStmtInd" in value;
    isInstance = isInstance && "ConsolidatedBasisFinclStmtInd" in value;
    isInstance = isInstance && "ConsolAndSepBasisFinclStmtInd" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SeparateBasisFinclStmtInd': json['SeparateBasisFinclStmtInd'],
        'ConsolidatedBasisFinclStmtInd': json['ConsolidatedBasisFinclStmtInd'],
        'ConsolAndSepBasisFinclStmtInd': json['ConsolAndSepBasisFinclStmtInd'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasisToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990AcctCompileOrReviewBasis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SeparateBasisFinclStmtInd': value.SeparateBasisFinclStmtInd,
        'ConsolidatedBasisFinclStmtInd': value.ConsolidatedBasisFinclStmtInd,
        'ConsolAndSepBasisFinclStmtInd': value.ConsolAndSepBasisFinclStmtInd,
    };
}

