/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Assets } from './Assets';
import {
    AssetsFromJSON,
    AssetsFromJSONTyped,
    AssetsToJSON,
} from './Assets';
import type { ControlAbridgedCyberBenchmark } from './ControlAbridgedCyberBenchmark';
import {
    ControlAbridgedCyberBenchmarkFromJSON,
    ControlAbridgedCyberBenchmarkFromJSONTyped,
    ControlAbridgedCyberBenchmarkToJSON,
} from './ControlAbridgedCyberBenchmark';
import type { ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount } from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import {
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSONTyped,
    ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON,
} from './ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount';
import type { ControlEntityDataleaks } from './ControlEntityDataleaks';
import {
    ControlEntityDataleaksFromJSON,
    ControlEntityDataleaksFromJSONTyped,
    ControlEntityDataleaksToJSON,
} from './ControlEntityDataleaks';
import type { ControlEntityExtra } from './ControlEntityExtra';
import {
    ControlEntityExtraFromJSON,
    ControlEntityExtraFromJSONTyped,
    ControlEntityExtraToJSON,
} from './ControlEntityExtra';
import type { ControlEntityRiskScoreV2 } from './ControlEntityRiskScoreV2';
import {
    ControlEntityRiskScoreV2FromJSON,
    ControlEntityRiskScoreV2FromJSONTyped,
    ControlEntityRiskScoreV2ToJSON,
} from './ControlEntityRiskScoreV2';
import type { ControlPolicy } from './ControlPolicy';
import {
    ControlPolicyFromJSON,
    ControlPolicyFromJSONTyped,
    ControlPolicyToJSON,
} from './ControlPolicy';
import type { ControlTrial } from './ControlTrial';
import {
    ControlTrialFromJSON,
    ControlTrialFromJSONTyped,
    ControlTrialToJSON,
} from './ControlTrial';

/**
 * 
 * @export
 * @interface ControlEntitySearchResults
 */
export interface ControlEntitySearchResults {
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    entity_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    entity_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    entity_domain?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    entity_logo_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntitySearchResults
     */
    domains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntitySearchResults
     */
    ip_addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    monit_type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntitySearchResults
     */
    data_sources?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ControlEntitySearchResults
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ControlEntitySearchResults
     */
    metrics?: object;
    /**
     * 
     * @type {ControlEntityExtra}
     * @memberof ControlEntitySearchResults
     */
    extra?: ControlEntityExtra;
    /**
     * 
     * @type {boolean}
     * @memberof ControlEntitySearchResults
     */
    use_mapping?: boolean;
    /**
     * 
     * @type {Assets}
     * @memberof ControlEntitySearchResults
     */
    assets?: Assets;
    /**
     * 
     * @type {ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount}
     * @memberof ControlEntitySearchResults
     */
    vulnerabilities?: ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCount;
    /**
     * 
     * @type {ControlEntityDataleaks}
     * @memberof ControlEntitySearchResults
     */
    dataleaks?: ControlEntityDataleaks;
    /**
     * 
     * @type {number}
     * @memberof ControlEntitySearchResults
     */
    lookalikes?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntitySearchResults
     */
    honeypots?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntitySearchResults
     */
    torrents?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlEntitySearchResults
     */
    risk_score?: number;
    /**
     * 
     * @type {ControlEntityRiskScoreV2}
     * @memberof ControlEntitySearchResults
     */
    risk_score_v2?: ControlEntityRiskScoreV2;
    /**
     * 
     * @type {ControlEntityRiskScoreV2}
     * @memberof ControlEntitySearchResults
     */
    previous_risk_score_v2?: ControlEntityRiskScoreV2;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    report_url?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ControlEntitySearchResults
     */
    localized_reports?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    scanned?: string;
    /**
     * 
     * @type {ControlAbridgedCyberBenchmark}
     * @memberof ControlEntitySearchResults
     */
    cyber_benchmark?: ControlAbridgedCyberBenchmark;
    /**
     * 
     * @type {ControlPolicy}
     * @memberof ControlEntitySearchResults
     */
    policy?: ControlPolicy;
    /**
     * 
     * @type {string}
     * @memberof ControlEntitySearchResults
     */
    external_id?: string;
    /**
     * 
     * @type {ControlTrial}
     * @memberof ControlEntitySearchResults
     */
    trial?: ControlTrial;
}

/**
 * Check if a given object implements the ControlEntitySearchResults interface.
 */
export function instanceOfControlEntitySearchResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlEntitySearchResultsFromJSON(json: any): ControlEntitySearchResults {
    return ControlEntitySearchResultsFromJSONTyped(json, false);
}

export function ControlEntitySearchResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlEntitySearchResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': !exists(json, 'entity_id') ? undefined : json['entity_id'],
        'entity_name': !exists(json, 'entity_name') ? undefined : json['entity_name'],
        'entity_domain': !exists(json, 'entity_domain') ? undefined : json['entity_domain'],
        'entity_logo_url': !exists(json, 'entity_logo_url') ? undefined : json['entity_logo_url'],
        'domains': !exists(json, 'domains') ? undefined : json['domains'],
        'ip_addresses': !exists(json, 'ip_addresses') ? undefined : json['ip_addresses'],
        'monit_type': !exists(json, 'monit_type') ? undefined : json['monit_type'],
        'data_sources': !exists(json, 'data_sources') ? undefined : json['data_sources'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'metrics': !exists(json, 'metrics') ? undefined : json['metrics'],
        'extra': !exists(json, 'extra') ? undefined : ControlEntityExtraFromJSON(json['extra']),
        'use_mapping': !exists(json, 'use_mapping') ? undefined : json['use_mapping'],
        'assets': !exists(json, 'assets') ? undefined : AssetsFromJSON(json['assets']),
        'vulnerabilities': !exists(json, 'vulnerabilities') ? undefined : ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountFromJSON(json['vulnerabilities']),
        'dataleaks': !exists(json, 'dataleaks') ? undefined : ControlEntityDataleaksFromJSON(json['dataleaks']),
        'lookalikes': !exists(json, 'lookalikes') ? undefined : json['lookalikes'],
        'honeypots': !exists(json, 'honeypots') ? undefined : json['honeypots'],
        'torrents': !exists(json, 'torrents') ? undefined : json['torrents'],
        'risk_score': !exists(json, 'risk_score') ? undefined : json['risk_score'],
        'risk_score_v2': !exists(json, 'risk_score_v2') ? undefined : ControlEntityRiskScoreV2FromJSON(json['risk_score_v2']),
        'previous_risk_score_v2': !exists(json, 'previous_risk_score_v2') ? undefined : ControlEntityRiskScoreV2FromJSON(json['previous_risk_score_v2']),
        'report_url': !exists(json, 'report_url') ? undefined : json['report_url'],
        'localized_reports': !exists(json, 'localized_reports') ? undefined : json['localized_reports'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'scanned': !exists(json, 'scanned') ? undefined : json['scanned'],
        'cyber_benchmark': !exists(json, 'cyber_benchmark') ? undefined : ControlAbridgedCyberBenchmarkFromJSON(json['cyber_benchmark']),
        'policy': !exists(json, 'policy') ? undefined : ControlPolicyFromJSON(json['policy']),
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'trial': !exists(json, 'trial') ? undefined : ControlTrialFromJSON(json['trial']),
    };
}

export function ControlEntitySearchResultsToJSON(value?: ControlEntitySearchResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_domain': value.entity_domain,
        'entity_logo_url': value.entity_logo_url,
        'domains': value.domains,
        'ip_addresses': value.ip_addresses,
        'monit_type': value.monit_type,
        'data_sources': value.data_sources,
        'keywords': value.keywords,
        'metrics': value.metrics,
        'extra': ControlEntityExtraToJSON(value.extra),
        'use_mapping': value.use_mapping,
        'assets': AssetsToJSON(value.assets),
        'vulnerabilities': ControlAppSchemasExternalAsmEntityResponsesEntityVulnerabilitiesCountToJSON(value.vulnerabilities),
        'dataleaks': ControlEntityDataleaksToJSON(value.dataleaks),
        'lookalikes': value.lookalikes,
        'honeypots': value.honeypots,
        'torrents': value.torrents,
        'risk_score': value.risk_score,
        'risk_score_v2': ControlEntityRiskScoreV2ToJSON(value.risk_score_v2),
        'previous_risk_score_v2': ControlEntityRiskScoreV2ToJSON(value.previous_risk_score_v2),
        'report_url': value.report_url,
        'localized_reports': value.localized_reports,
        'status': value.status,
        'created': value.created,
        'updated': value.updated,
        'scanned': value.scanned,
        'cyber_benchmark': ControlAbridgedCyberBenchmarkToJSON(value.cyber_benchmark),
        'policy': ControlPolicyToJSON(value.policy),
        'external_id': value.external_id,
        'trial': ControlTrialToJSON(value.trial),
    };
}

