import { useState, useMemo, useContext, createContext } from 'react';

interface URLParams {
  domain: string;
  processId: string;
}

export interface SecReviewContextType {
  hasEntity: boolean;
  entity: any;
  setEntity: (data: any) => void;
  urlParams: URLParams;
  setUrlParams: (params: URLParams) => void;
}

const initialState: SecReviewContextType = {
  hasEntity: false,
  entity: {},
  setEntity: () => true,
  urlParams: { domain: '', processId: '' },
  setUrlParams: () => true,
};

export const SecReviewContext =
  createContext<SecReviewContextType>(initialState);

export const useSecReviewProvider = (): SecReviewContextType => {
  const [entity, setEntity] = useState<any>(initialState.entity);
  const [urlParams, setUrlParams] = useState<URLParams>(initialState.urlParams);

  const provider = useMemo<SecReviewContextType>(
    () => ({
      hasEntity: !!entity,
      entity,
      setEntity,
      urlParams,
      setUrlParams,
    }),
    [entity, urlParams],
  );

  return provider;
};

export const useSecReviewContext = (): SecReviewContextType => {
  return useContext(SecReviewContext);
};
