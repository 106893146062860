import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { endpoints, useQuery } from '@explorer/api';
import { Button, Grid } from '@explorer/core';
import { CheckboxOptions, FormFieldControl } from '@explorer/forms';
import { FormDialog, FormDialogProps } from '@explorer/forms/base/FormDialog';
import { CommonFormProps } from '@explorer/forms/types';
import { useFeatureFlag, useQuerySnackbar } from '@explorer/hooks';
import {
  FormValues,
  initializeFormSchema,
  validationSchema,
} from './formSchema';
import { useIntl } from 'react-intl';
import { parseCommaSeparatedList } from '@explorer/helpers';
import { useV1APIQuery } from '@explorer/query';

const FORM_ID = 'TriggerCRAScan';

const DEFAULT_LOCALE = 'en-US';

const INITIAL_FORM_VALUES = {
  entity_name: '',
  entity_domain: '',
  domains: '',
  ip_addresses: '',
  industry: '',
  number_of_employees: 0,
  number_of_pii_records: 0,
  company_revenue: 0,
  locale: DEFAULT_LOCALE,
  cobranded: null,
  scan_opts: {
    force: false,
  },
};
const SCAN_OPTS: CheckboxOptions = [
  {
    label: {
      id: 'forms-presets.scan-opts-force',
    },
    name: 'force',
  },
];

const LOCALE_OPTS: Options = [
  {
    label: {
      id: 'forms-presets.locale-en-AU',
    },
    value: 'en-AU',
  },
  {
    label: {
      id: 'forms-presets.locale-en-CA',
    },
    value: 'en-CA',
  },
  {
    label: {
      id: 'forms-presets.locale-en-GB',
    },
    value: 'en-GB',
  },
  {
    label: {
      id: 'forms-presets.locale-en-US',
    },
    value: 'en-US',
  },
  {
    label: {
      id: 'forms-presets.locale-de-DE',
    },
    value: 'de-DE',
  },
  {
    label: {
      id: 'forms-presets.locale-fr-CA',
    },
    value: 'fr-CA',
  },
];

const COBRANDED_OPTS: Options = [
  {
    label: 'Acrisure',
    value: 'acrisure',
  },
];

/**
 * TriggerCRAScan
 */
export const TriggerCRAScan = ({
  handleClose,
  industries,
  maxWidth = 'md',
  open = false,
  querySearch = '',
}: TriggerCRAScanProps) => {
  const { snackSuccess, snackError } = useQuerySnackbar();
  const intl = useIntl();

  const {
    data: fetchedCRAScans,
    invalidateAllQueries,
    isLoading: prefillDataIsLoading,
  } = useV1APIQuery({
    queryKey: ['v1CraWebListGet'],
    queryFn(client, context) {
      return client.v1CraWebListGet(
        {
          query: querySearch || undefined,
        },
        {
          signal: context.signal,
        },
      );
    },
    explorerAPIOptions: {},
    additionalParams: [querySearch],
  });

  const queryTriggerScan = useQuery({
    url: process.env.API_PORTAL,
    endpoint: endpoints.v1cra['/trigger'](),
    method: 'POST',
    auth: 'bearer',
    headers: {
      'Coalition-App': 'coalition-explorer',
    },
  });

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: validationSchema(intl),
    validateOnChange: false,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      const domains = Array.from(parseCommaSeparatedList(values?.domains));
      const ip_addresses = Array.from(
        parseCommaSeparatedList(values?.ip_addresses),
      );
      const { force } = values?.scan_opts;

      queryTriggerScan
        .submit({
          payload: {
            entity_name: values.entity_name,
            entity_domain: domains[0],
            domains: domains.slice(1),
            locale: values?.locale ?? DEFAULT_LOCALE,
            cobranded: values?.cobranded ?? null,
            ip_addresses,
            force,
            metrics: {
              industry: values.industry,
              number_of_employees: values.number_of_employees,
              number_of_pii_records: values.number_of_pii_records,
              company_revenue: values.company_revenue,
            },
          },
        })
        .then(({ error }) => {
          if (error) {
            snackError('Trigger New CRA Error', error);
            setSubmitting(false);
          } else {
            snackSuccess('Trigger New CRA Success');
            handleClose();
            resetForm();

            //invalidate query
            invalidateAllQueries();
          }
        });
    },
  });

  const handleFetchData = useCallback(() => {
    if (fetchedCRAScans.results.length > 0) {
      const craData = fetchedCRAScans.results[0];
      let domains = [craData.entity_domain];
      domains = domains.concat(craData.domains);

      formik.setValues({
        entity_name: craData.entity_name,
        domains: domains.join(','),
        ip_addresses: craData.ip_addresses?.join(',') || '',
        industry: `${craData?.metrics?.industry}` || '',
        number_of_employees:
          (craData?.metrics?.number_of_employees as number) || 0,
        number_of_pii_records:
          (craData?.metrics?.number_of_pii_records as number) || 0,
        company_revenue: (craData?.metrics?.company_revenue as number) || 0,
        locale: (craData?.locale as string) ?? DEFAULT_LOCALE,
        cobranded: null,
        scan_opts: {
          force: false,
        },
      });

      snackSuccess('Fetch CRA Data Success');
    } else {
      snackError('Fetch CRA Data Error', 'No data found');
    }
  }, [fetchedCRAScans?.results, formik, snackError, snackSuccess]);

  const formSchema = useMemo(() => {
    return initializeFormSchema({
      formId: FORM_ID,
      formik,
      options: {
        industries,
        scan_opts: SCAN_OPTS,
        locale_opts: LOCALE_OPTS,
        cobranded_opts: COBRANDED_OPTS,
      },
    });
  }, [formik, industries]);

  return (
    <FormDialog
      title={{ id: 'forms-presets.trigger-cra-scan-title' }}
      formId={FORM_ID}
      formik={formik}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth}
      submitButton
      resetButton
      loading={formik.isSubmitting}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Button
            color="secondary"
            label={{ id: 'forms-presets.fill-last-entry' }}
            loading={prefillDataIsLoading}
            onClick={handleFetchData}
            mb={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.entity_name} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.industry} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.number_of_employees} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.number_of_pii_records} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormFieldControl {...formSchema.company_revenue} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.domains} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.ip_addresses} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.locale} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldControl {...formSchema.cobranded} />
        </Grid>
        <Grid item xs={12}>
          <FormFieldControl {...formSchema.scan_opts} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export interface TriggerCRAScanProps
  extends Pick<FormDialogProps, 'open' | 'handleClose' | 'maxWidth'>,
    Omit<CommonFormProps<FormValues>, 'endpoint'> {
  industries: Options;
  querySearch?: string;
}
