/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MonitEntityMuteNotificationsOrderBy = {
    ExternalId: 'external_id',
    EntityId: 'entity_id',
    Asset: 'asset',
    Datapoint: 'datapoint',
    Status: 'status',
    Reason: 'reason',
    Created: 'created',
    Updated: 'updated',
    ReasonInternal: 'reason_internal',
    ReviewedBy: 'reviewed_by'
} as const;
export type MonitEntityMuteNotificationsOrderBy = typeof MonitEntityMuteNotificationsOrderBy[keyof typeof MonitEntityMuteNotificationsOrderBy];


export function MonitEntityMuteNotificationsOrderByFromJSON(json: any): MonitEntityMuteNotificationsOrderBy {
    return MonitEntityMuteNotificationsOrderByFromJSONTyped(json, false);
}

export function MonitEntityMuteNotificationsOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityMuteNotificationsOrderBy {
    return json as MonitEntityMuteNotificationsOrderBy;
}

export function MonitEntityMuteNotificationsOrderByToJSON(value?: MonitEntityMuteNotificationsOrderBy | null): any {
    return value as any;
}

