/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleA } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleA';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleA';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR } from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR';
import type { ExecRiskIRS990 } from './ExecRiskIRS990';
import {
    ExecRiskIRS990FromJSON,
    ExecRiskIRS990FromJSONTyped,
    ExecRiskIRS990ToJSON,
} from './ExecRiskIRS990';
import type { ExecRiskIRS990EZ } from './ExecRiskIRS990EZ';
import {
    ExecRiskIRS990EZFromJSON,
    ExecRiskIRS990EZFromJSONTyped,
    ExecRiskIRS990EZToJSON,
} from './ExecRiskIRS990EZ';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData {
    /**
     * 
     * @type {ExecRiskIRS990}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990: ExecRiskIRS990;
    /**
     * 
     * @type {ExecRiskIRS990EZ}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990EZ: ExecRiskIRS990EZ;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleA}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990ScheduleA: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleA;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData
     */
    IRS990ScheduleR: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleR;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "IRS990" in value;
    isInstance = isInstance && "IRS990EZ" in value;
    isInstance = isInstance && "IRS990ScheduleA" in value;
    isInstance = isInstance && "IRS990ScheduleR" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData {
    return ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IRS990': ExecRiskIRS990FromJSON(json['IRS990']),
        'IRS990EZ': ExecRiskIRS990EZFromJSON(json['IRS990EZ']),
        'IRS990ScheduleA': ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAFromJSON(json['IRS990ScheduleA']),
        'IRS990ScheduleR': ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRFromJSON(json['IRS990ScheduleR']),
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnDataToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsForm990ReturnData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IRS990': ExecRiskIRS990ToJSON(value.IRS990),
        'IRS990EZ': ExecRiskIRS990EZToJSON(value.IRS990EZ),
        'IRS990ScheduleA': ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleAToJSON(value.IRS990ScheduleA),
        'IRS990ScheduleR': ExecRiskAppApiV1EagerTasksForm990ModelsForm990ScheduleRToJSON(value.IRS990ScheduleR),
    };
}

