/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitEntityExtraGeo } from './MonitEntityExtraGeo';
import {
    MonitEntityExtraGeoFromJSON,
    MonitEntityExtraGeoFromJSONTyped,
    MonitEntityExtraGeoToJSON,
} from './MonitEntityExtraGeo';

/**
 * 
 * @export
 * @interface MonitEntityExtra
 */
export interface MonitEntityExtra {
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtra
     */
    sector?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtra
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtra
     */
    subindustry?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitEntityExtra
     */
    industrygroup?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitEntityExtra
     */
    tags?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof MonitEntityExtra
     */
    metrics?: object;
    /**
     * 
     * @type {MonitEntityExtraGeo}
     * @memberof MonitEntityExtra
     */
    geo?: MonitEntityExtraGeo;
}

/**
 * Check if a given object implements the MonitEntityExtra interface.
 */
export function instanceOfMonitEntityExtra(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitEntityExtraFromJSON(json: any): MonitEntityExtra {
    return MonitEntityExtraFromJSONTyped(json, false);
}

export function MonitEntityExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityExtra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'subindustry': !exists(json, 'subindustry') ? undefined : json['subindustry'],
        'industrygroup': !exists(json, 'industrygroup') ? undefined : json['industrygroup'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'metrics': !exists(json, 'metrics') ? undefined : json['metrics'],
        'geo': !exists(json, 'geo') ? undefined : MonitEntityExtraGeoFromJSON(json['geo']),
    };
}

export function MonitEntityExtraToJSON(value?: MonitEntityExtra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sector': value.sector,
        'industry': value.industry,
        'subindustry': value.subindustry,
        'industrygroup': value.industrygroup,
        'tags': value.tags,
        'metrics': value.metrics,
        'geo': MonitEntityExtraGeoToJSON(value.geo),
    };
}

