/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    PersonNm: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    BusinessName: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    TitleTxt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    AverageHoursPerWeekRt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    AverageHoursPerWeekRltdOrgRt: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    IndividualTrusteeOrDirectorInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    InstitutionalTrusteeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    OfficerInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    KeyEmployeeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    HighestCompensatedEmployeeInd: string;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    FormerOfcrDirectorTrusteeInd: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    ReportableCompFromOrgAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    ReportableCompFromRltdOrgAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA
     */
    OtherCompensationAmt: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PersonNm" in value;
    isInstance = isInstance && "BusinessName" in value;
    isInstance = isInstance && "TitleTxt" in value;
    isInstance = isInstance && "AverageHoursPerWeekRt" in value;
    isInstance = isInstance && "AverageHoursPerWeekRltdOrgRt" in value;
    isInstance = isInstance && "IndividualTrusteeOrDirectorInd" in value;
    isInstance = isInstance && "InstitutionalTrusteeInd" in value;
    isInstance = isInstance && "OfficerInd" in value;
    isInstance = isInstance && "KeyEmployeeInd" in value;
    isInstance = isInstance && "HighestCompensatedEmployeeInd" in value;
    isInstance = isInstance && "FormerOfcrDirectorTrusteeInd" in value;
    isInstance = isInstance && "ReportableCompFromOrgAmt" in value;
    isInstance = isInstance && "ReportableCompFromRltdOrgAmt" in value;
    isInstance = isInstance && "OtherCompensationAmt" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': json['PersonNm'],
        'BusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'TitleTxt': json['TitleTxt'],
        'AverageHoursPerWeekRt': json['AverageHoursPerWeekRt'],
        'AverageHoursPerWeekRltdOrgRt': json['AverageHoursPerWeekRltdOrgRt'],
        'IndividualTrusteeOrDirectorInd': json['IndividualTrusteeOrDirectorInd'],
        'InstitutionalTrusteeInd': json['InstitutionalTrusteeInd'],
        'OfficerInd': json['OfficerInd'],
        'KeyEmployeeInd': json['KeyEmployeeInd'],
        'HighestCompensatedEmployeeInd': json['HighestCompensatedEmployeeInd'],
        'FormerOfcrDirectorTrusteeInd': json['FormerOfcrDirectorTrusteeInd'],
        'ReportableCompFromOrgAmt': json['ReportableCompFromOrgAmt'],
        'ReportableCompFromRltdOrgAmt': json['ReportableCompFromRltdOrgAmt'],
        'OtherCompensationAmt': json['OtherCompensationAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionAToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990Form990PartVIISectionA | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'BusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'TitleTxt': value.TitleTxt,
        'AverageHoursPerWeekRt': value.AverageHoursPerWeekRt,
        'AverageHoursPerWeekRltdOrgRt': value.AverageHoursPerWeekRltdOrgRt,
        'IndividualTrusteeOrDirectorInd': value.IndividualTrusteeOrDirectorInd,
        'InstitutionalTrusteeInd': value.InstitutionalTrusteeInd,
        'OfficerInd': value.OfficerInd,
        'KeyEmployeeInd': value.KeyEmployeeInd,
        'HighestCompensatedEmployeeInd': value.HighestCompensatedEmployeeInd,
        'FormerOfcrDirectorTrusteeInd': value.FormerOfcrDirectorTrusteeInd,
        'ReportableCompFromOrgAmt': value.ReportableCompFromOrgAmt,
        'ReportableCompFromRltdOrgAmt': value.ReportableCompFromRltdOrgAmt,
        'OtherCompensationAmt': value.OtherCompensationAmt,
    };
}

