/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue {
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue
     */
    TotalRevenueColumnAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue
     */
    RelatedOrExemptFuncIncomeAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue
     */
    UnrelatedBusinessRevenueAmt: number;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue
     */
    ExclusionAmt: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "TotalRevenueColumnAmt" in value;
    isInstance = isInstance && "RelatedOrExemptFuncIncomeAmt" in value;
    isInstance = isInstance && "UnrelatedBusinessRevenueAmt" in value;
    isInstance = isInstance && "ExclusionAmt" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'TotalRevenueColumnAmt': json['TotalRevenueColumnAmt'],
        'RelatedOrExemptFuncIncomeAmt': json['RelatedOrExemptFuncIncomeAmt'],
        'UnrelatedBusinessRevenueAmt': json['UnrelatedBusinessRevenueAmt'],
        'ExclusionAmt': json['ExclusionAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenueToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990TotalRevenue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TotalRevenueColumnAmt': value.TotalRevenueColumnAmt,
        'RelatedOrExemptFuncIncomeAmt': value.RelatedOrExemptFuncIncomeAmt,
        'UnrelatedBusinessRevenueAmt': value.UnrelatedBusinessRevenueAmt,
        'ExclusionAmt': value.ExclusionAmt,
    };
}

