/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MonitEntityHoneypotsOrderBy = {
    EntityId: 'entity_id',
    Asset: 'asset',
    SourceAsset: 'source_asset',
    Port: 'port',
    Shared: 'shared',
    Created: 'created',
    Updated: 'updated',
    Found: 'found'
} as const;
export type MonitEntityHoneypotsOrderBy = typeof MonitEntityHoneypotsOrderBy[keyof typeof MonitEntityHoneypotsOrderBy];


export function MonitEntityHoneypotsOrderByFromJSON(json: any): MonitEntityHoneypotsOrderBy {
    return MonitEntityHoneypotsOrderByFromJSONTyped(json, false);
}

export function MonitEntityHoneypotsOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityHoneypotsOrderBy {
    return json as MonitEntityHoneypotsOrderBy;
}

export function MonitEntityHoneypotsOrderByToJSON(value?: MonitEntityHoneypotsOrderBy | null): any {
    return value as any;
}

