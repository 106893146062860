import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Typography, Grid } from '@explorer/core';
import { ScanChip, ScanStatusOpts } from './chip';

/**
 * ScanStatus
 */
export const ScanStatus = ({ data }: ScanStatusProps) => {
  const displayItems = useMemo(() => {
    return Object.entries(data);
  }, [data]);
  return (
    <Grid container alignItems="center" alignContent="center" spacing={1}>
      <Grid item>
        <Typography variant="bodyM" fontWeight="bold" colon>
          Scan Status
        </Typography>
      </Grid>
      {displayItems.map((entry) => (
        <Grid item key={uuidv4()}>
          <ScanChip label={entry[0]} status={entry[1]} />
        </Grid>
      ))}
    </Grid>
  );
};

/**
 * ScanStatus Props
 */
export type ScanRecordType = Record<string, ScanStatusOpts>;

export interface ScanStatusProps {
  data: ScanRecordType;
}
