/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress';
import type { ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress } from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';
import {
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSONTyped,
    ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON,
} from './ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress';

/**
 * 
 * @export
 * @interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
 */
export interface ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp {
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    PersonNm: string;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    BusinessName: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessName;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    USAddress: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddress;
    /**
     * 
     * @type {ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    ForeignAddress: ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddress;
    /**
     * 
     * @type {string}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    ServiceTypeTxt: string;
    /**
     * 
     * @type {number}
     * @memberof ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp
     */
    CompensationAmt: number;
}

/**
 * Check if a given object implements the ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp interface.
 */
export function instanceOfExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PersonNm" in value;
    isInstance = isInstance && "BusinessName" in value;
    isInstance = isInstance && "USAddress" in value;
    isInstance = isInstance && "ForeignAddress" in value;
    isInstance = isInstance && "ServiceTypeTxt" in value;
    isInstance = isInstance && "CompensationAmt" in value;

    return isInstance;
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSON(json: any): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp {
    return ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json, false);
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PersonNm': json['PersonNm'],
        'BusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameFromJSON(json['BusinessName']),
        'USAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressFromJSON(json['USAddress']),
        'ForeignAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressFromJSON(json['ForeignAddress']),
        'ServiceTypeTxt': json['ServiceTypeTxt'],
        'CompensationAmt': json['CompensationAmt'],
    };
}

export function ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrpToJSON(value?: ExecRiskAppApiV1EagerTasksForm990ModelsIRS990EZCompensationOfHghstPdCntrctGrp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PersonNm': value.PersonNm,
        'BusinessName': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesBusinessNameToJSON(value.BusinessName),
        'USAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesUSAddressToJSON(value.USAddress),
        'ForeignAddress': ExecRiskAppApiV1EagerTasksForm990ModelsCommonTypesForeignAddressToJSON(value.ForeignAddress),
        'ServiceTypeTxt': value.ServiceTypeTxt,
        'CompensationAmt': value.CompensationAmt,
    };
}

