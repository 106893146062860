/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsName } from './AsName';
import {
    AsNameFromJSON,
    AsNameFromJSONTyped,
    AsNameToJSON,
} from './AsName';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';

/**
 * 
 * @export
 * @interface ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet
 */
export interface ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet {
    /**
     * 
     * @type {Category}
     * @memberof ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet
     */
    category?: Category;
    /**
     * 
     * @type {AsName}
     * @memberof ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet
     */
    as_name?: AsName;
}

/**
 * Check if a given object implements the ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet interface.
 */
export function instanceOfControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGetFromJSON(json: any): ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet {
    return ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGetFromJSONTyped(json, false);
}

export function ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'as_name': !exists(json, 'as_name') ? undefined : AsNameFromJSON(json['as_name']),
    };
}

export function ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGetToJSON(value?: ControlBodyGetEntityAssetsAsmEntityEntityIdAssetsGet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': CategoryToJSON(value.category),
        'as_name': AsNameToJSON(value.as_name),
    };
}

