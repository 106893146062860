/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MonitEntityAssetSource = {
    RootDomain: 'root-domain',
    SubdomainEnumeration: 'subdomain-enumeration',
    DnsA: 'dns-a',
    DnsAaaa: 'dns-aaaa',
    DnsSpf: 'dns-spf',
    SslCertificate: 'ssl-certificate',
    Ipwhois: 'ipwhois',
    BaseAsset: 'base-asset'
} as const;
export type MonitEntityAssetSource = typeof MonitEntityAssetSource[keyof typeof MonitEntityAssetSource];


export function MonitEntityAssetSourceFromJSON(json: any): MonitEntityAssetSource {
    return MonitEntityAssetSourceFromJSONTyped(json, false);
}

export function MonitEntityAssetSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitEntityAssetSource {
    return json as MonitEntityAssetSource;
}

export function MonitEntityAssetSourceToJSON(value?: MonitEntityAssetSource | null): any {
    return value as any;
}

