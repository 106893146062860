/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MonitImpactedAssetsInterval = {
    _050: '0-50',
    _51100: '51-100',
    _101500: '101-500',
    _500: '+500'
} as const;
export type MonitImpactedAssetsInterval = typeof MonitImpactedAssetsInterval[keyof typeof MonitImpactedAssetsInterval];


export function MonitImpactedAssetsIntervalFromJSON(json: any): MonitImpactedAssetsInterval {
    return MonitImpactedAssetsIntervalFromJSONTyped(json, false);
}

export function MonitImpactedAssetsIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitImpactedAssetsInterval {
    return json as MonitImpactedAssetsInterval;
}

export function MonitImpactedAssetsIntervalToJSON(value?: MonitImpactedAssetsInterval | null): any {
    return value as any;
}

